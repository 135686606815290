import styled from 'styled-components';

export const RepliesStyled = styled.ul`
  box-sizing: border-box;
  flex: 1;

  @media (max-width: 600px) {
    margin-left: 4.4rem;
  }
`;

export const ActionBar = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

export const Separator = styled.svg`
  margin-left: 6px;
  margin-right: 6px;
`;
