import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  user-select: none;
  height: 28px;
`;

export const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  //  border: 1px solid #003D4F;

  background-color: white;
  line-height: 18px;
  border-radius: 2px;
  box-sizing: border-box;
`;

export const DropDownHeaderText = styled.div`
  width: 100%;
  padding: 5px 24px 5px 8px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const DropDownList = styled.div`
  margin-top: 4px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  box-sizing: border-box;
  border-radius: 2px;
`;

export const WrapperDropDownListItens  = styled.div`    
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 2px;
  background-color: white;
`;

export const DropDownListItens = styled.div`
  width: 100%;
  max-height: 215px;
  overflow-y: auto;
  padding-top: 1px;
  padding-bottom: 1px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid ${({ theme }) => theme.colors.gray30};
    background-color: ${({ theme }) => theme.colors.gray30};
  }

  &::-webkit-scrollbar {
    width: 6px;
    box-shadow: nset 0 0 6px ${({ theme }) => theme.colors.gray20};
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 4px solid ${({ theme }) => theme.colors.secondary};
  }
`;

export const DropDownListItem = styled.div`
  width: 100%;
  padding: 8px 10px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.secondary};

  &.no-result {
    font-weight: normal;
    cursor: default;

    &:hover {
      background-color: transparent;
      color: black;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray20};
  }
`;

export const DropDownListSearch = styled.div`
  display: flex;
  padding: 12px 1px 8px 13px;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray40};
`;

export const DropDownListSearchField = styled.input`
  border: none;
  outline: 0rem;
  margin-left: 1.4rem;
  margin-right: 0.7rem;
  width: 80%;
`;
