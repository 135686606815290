import styled from 'styled-components';

export const NotificationSettingsDetails = styled.div`
  display: grid;
  grid-template-columns: [grid-left] auto [types-column-end] 30px [blog_column-end];
  grid-template-rows: [grid-top] auto [header-channels-end] 30px [like-end] 30px [comment-end] 30px [replies-end] 30px [new-follower-end] 30px [blogs-you-follow-end];
  align-items: center;
`;

export const CellPosition = styled.div`
  grid-column-start: ${({ column }) => column};
  grid-column-end: ${({ column }) => column};
  grid-row-start: ${({ row }) => row};
  grid-row-end: ${({ row }) => row};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NotificationSettingsChannelLabel = styled.p`
  font-size: 12px;
  color: #003d4f;
  margin-top: 4px;
  padding-bottom: 24px;
`;
