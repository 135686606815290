import styled, { css } from 'styled-components';

export const Icon = styled.svg`
  width: 100%;
  height: auto;
  ${({ size }) => {
    switch (size) {
      case 'ss':
        return css`
          width: 1.2rem;
          height: 1.2rem;
        `;
      case 's':
        return css`
          width: 1.6rem;
          height: 1.6rem;
        `;
      case 'l':
        return css`
          width: 2.4rem;
          height: 2.4rem;
        `;
      default:
        return css`
          width: 2rem;
          height: 2rem;
        `;
    }
  }}

  path {
    fill: ${({ color, active }) => (active ? '#F2617A' : color)};
  }

  &:hover > path {
    ${({ disabledHover }) => (disabledHover ? '' : 'fill: #F2617A;')}
  }
`;
