import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Likes from '../../components/Likes';
import { addLike, removeLike } from '../../services/likes/service';
import {
  addLikeFeaturedPost,
  addLikeLatestPost,
  removeLikeFeaturedPost,
  removeLikeLatestPost
} from '../../store/posts/actions';

const LikesContainer = ({ post }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const [isDisabledLikeButton, setIsDisabledLikeButton] = useState(true);
  const [isUserLikedPost, setIsUserLikedPost] = useState(false);
  const [likesList, setLikesList] = useState([]);
  const [userLikedPost, setUserLikedPost] = useState({});

  const checkUserLikedPost = (likeList, loggedUserId) => {
    const loggedUserLike = likeList.find(
      (like) => like.author.id === loggedUserId
    );
    if (loggedUserLike) {
      setUserLikedPost(loggedUserLike);
    }
    return !!loggedUserLike;
  };

  useEffect(() => {
    setLikesList(post.likes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  useEffect(() => {
    if (likesList.length >= 0 && loggedUser.id) {
      const isUserLiked = checkUserLikedPost(likesList, loggedUser.id);
      setIsUserLikedPost(isUserLiked);
      setIsDisabledLikeButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likesList, loggedUser.id]);

  useEffect(() => {
    setIsDisabledLikeButton(false);
  }, [isUserLikedPost]);

  const handleRemoveLike = () => {
    const updatedLikeList = likesList.filter(
      (like) => like.id !== userLikedPost.id
    );
    setLikesList(updatedLikeList);

    removeLike(userLikedPost.id).then((response) => {
      const likeRemoved = {
        id: response.id,
        post: response.post.id
      };

      if (response?.post?.is_featured) {
        dispatch(removeLikeFeaturedPost(likeRemoved));
      } else {
        dispatch(removeLikeLatestPost(likeRemoved));
      }
      setIsUserLikedPost(false);
      setUserLikedPost(null);
    });
  };

  const handleAddLike = () => {
    const like = { post: post.id, user: loggedUser.id };
    addLike(like).then((response) => {
      const likeAdded = factoryObjectLike(response);
      const updatedLikeList = [likeAdded, ...likesList];

      setLikesList(updatedLikeList);
      setIsUserLikedPost(true);

      if (response?.post?.is_featured) {
        dispatch(addLikeFeaturedPost(likeAdded));
      } else {
        dispatch(addLikeLatestPost(likeAdded));
      }
    });
  };

  const handleLikes = () => {
    setIsDisabledLikeButton(true);
    if (isUserLikedPost) {
      handleRemoveLike();
    } else {
      handleAddLike();
    }
  };

  const factoryObjectLike = (response) => {
    return {
      created_at: response.created_at,
      email: response.user.email,
      id: response.id,
      name: response.user.name,
      post: response.post.id,
      updated_at: response.updated_at,
      user: response.user.id,
      author: {
        id: response.author?.id
      },
      username: response.user.username,
      isFeatured: response.post.is_featured
    };
  };

  const loggedUserIsTheAuthor = post.author.id === loggedUser.id;
  return (
    <Likes
      handleLikes={handleLikes}
      isDisabledLikeButton={loggedUserIsTheAuthor || isDisabledLikeButton}
      isLiked={isUserLikedPost}
      likesList={likesList}
    />
  );
};

LikesContainer.propTypes = {
  post: PropTypes.object
};

export default LikesContainer;
