import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './styles';
import LoadingCircle from '../../components/LoadingCircle';
import { cleanSearch, searching } from '../../store/search/actions';
import {
  getSearchPosts,
  getSearchTags,
  getSearchAuthors
} from '../../services/search/service';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import ListItem from '../../components/ListItem';
import LoadingDots from '../../components/LoadingDots';
import ResultTags from './resultTags';
import AuthorBiography from '../../components/AuthorBiography';

import InfiniteScroll from 'react-infinite-scroll-component';
import MessageScroll from '../../components/MessageScroll';
import PostSummary from '../../components/PostSummary';

const SearchContainer = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const history = useHistory();

  const resultPostsList = useSelector((state) => state.search.resultPosts);
  const hasMorePosts = useSelector((state) => state.search.hasMorePosts);
  const hasMoreAuthors = useSelector((state) => state.search.hasMoreAuthors);
  const resultTagList = useSelector((state) => state.search.tagList);
  const resultAuthorList = useSelector((state) => state.search.resultAuthors);

  const searchingResult = useSelector((state) => state.search.searching);

  const [searchParam, setSearchParm] = useState(query.get('title'));

  const [postPaginationStart, setPostPaginationStart] = useState(0);
  const limit = 6;

  const [authorPaginationStart, setAuthorPaginationStart] = useState(0);

  const [tagList, setTagList] = useState([]);

  const [tabActive, setTabActive] = useState({
    valid: true,
    tab: 'Posts'
  });

  function useQuery() {
    const search = useLocation().search;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const restoreInitalTabState = () => {
    setTabActive({
      valid: true,
      tab: 'Posts'
    });
  }

  const queryParamSearchValue = query.get('title');

  useEffect(() => {
    dispatch(cleanSearch());
    dispatch(searching(true));
    getSearchTags(searchParam, dispatch);
    getSearchAuthors(
      encodeURIComponent(history.location.state.search),
      limit,
      authorPaginationStart,
      dispatch
    );

    getSearchPosts(
      encodeURIComponent(history.location.state.search),
      limit,
      postPaginationStart,
      dispatch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  useEffect(() => {
    setSearchParm(queryParamSearchValue);
    restoreInitalTabState();
  }, [queryParamSearchValue])

  useEffect(() => {
    if (resultTagList.length > 0) {
      const listTagFiltered = resultTagList
        .sort((a, b) => a.posts.length - b.posts.length)
        .reverse();

      const quantityOfPosts =
        listTagFiltered.length < 8 ? listTagFiltered.length : 8;

      const limitedTagList = listTagFiltered.slice(0, quantityOfPosts);

      setTagList(limitedTagList);
    }
  }, [resultTagList]);

  const updateAuthorScroll = () => {
    let pagination = authorPaginationStart + 1;

    getSearchAuthors(
      encodeURIComponent(history.location.state.search),
      limit,
      pagination,
      dispatch
    );

    setAuthorPaginationStart((page) => page + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const updatePostsScroll = () => {
    let pagination = postPaginationStart + 6;

    getSearchPosts(
      encodeURIComponent(history.location.state.search),
      limit,
      pagination,
      dispatch
    );

    setPostPaginationStart((page) => page + 6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handlePostTab = (e) => {
    setTabActive({ tab: e.target.innerText, valid: true });
  };

  return (
    <S.Wrapper>
      {searchingResult ? (
        <LoadingCircle showLoading={true} loadingText="Searching..." />
      ) : (
        <Tabs>
          <Tab
            title="Posts"
            handleClick={handlePostTab}
            isActive={tabActive.tab === 'Posts'}
          >
            <InfiniteScroll
              dataLength={resultPostsList.length}
              next={updatePostsScroll}
              hasMore={hasMorePosts}
              loader={
                resultPostsList.length < 6 ? null : (
                  <LoadingDots showLoading={true} />
                )
              }
              endMessage={
                resultPostsList.length > 6 ? (
                  <MessageScroll message={'Yay! You have seen it all'} />
                ) : null
              }
            >
              <ListItem
                showMessage={resultPostsList.length === 0}
                message={'We couldn’t find any Posts.'}
                itemId={'posts'}
                listId={'listPosts'}
              >
                {resultPostsList.map((post, index) => {
                  return (
                    <S.ContainerPosts key={index}>
                      <PostSummary
                        post={post}
                        variant="home"
                        position={index}
                        origin="PostsSearch"
                      />
                    </S.ContainerPosts>
                  );
                })}
              </ListItem>
            </InfiniteScroll>
          </Tab>

          <Tab
            title="Tags"
            handleClick={handlePostTab}
            isActive={tabActive.tab === 'Tags'}
          >
            <ResultTags tagList={tagList} />
          </Tab>

          <Tab
            title="Authors"
            handleClick={handlePostTab}
            isActive={tabActive.tab === 'Authors'}
          >
            <InfiniteScroll
              dataLength={resultAuthorList.length}
              next={updateAuthorScroll}
              hasMore={hasMoreAuthors}
              loader={
                resultAuthorList.length < 6 ? null : (
                  <LoadingDots showLoading={true} />
                )
              }
              endMessage={
                resultAuthorList.length > 6 ? (
                  <MessageScroll message={'Yay! You have seen it all'} />
                ) : null
              }
            >
              <ListItem
                showMessage={resultAuthorList.length === 0}
                message={'We couldn’t find any Author.'}
                itemId={'author'}
                listId={'listAuthors'}
              >
                {resultAuthorList.map((x, index) => {
                  return (
                    <S.ContainerAuthor key={index}>
                      <AuthorBiography
                        id={index}
                        author={x}
                        isBiography={true}
                      />
                    </S.ContainerAuthor>
                  );
                })}
              </ListItem>
            </InfiniteScroll>
          </Tab>
        </Tabs>
      )}
    </S.Wrapper>
  );
};

export default SearchContainer;
