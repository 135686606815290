import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReplyForm from '../Form';
import Item from './Item';
import ReplyButton from './ReplyButton';
import ReplyCounter from './ReplyCounter';
import * as S from './styles';

const RepliesList = ({
  comment,
  handleAddReply,
  handleEditReply,
  loggedUser,
  openModal
}) => {
  const [showReplyForm, setShowReplyForm] = useState(false);

  const openReplyForm = () => {
    setShowReplyForm(true);
  };

  const closeReplyForm = () => {
    setShowReplyForm(false);
  };

  return (
    <>
      <S.ActionBar>
        <ReplyButton onClick={openReplyForm} />
        {comment.replies.length > 0 && (
          <S.Separator
            width="3"
            height="4"
            viewBox="0 0 3 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1.5" cy="2" r="1.5" fill="#BDBEC0" />
          </S.Separator>
        )}
        <ReplyCounter quantity={comment.replies.length} />
      </S.ActionBar>
      <S.RepliesStyled>
        {comment.replies.map((reply, index) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <li tabIndex={0} key={index} aria-label='reply-item'>
            <>
              <Item
                reply={reply}
                handleEditReply={handleEditReply}
                loggedUserId={loggedUser.id}
                openModal={openModal}
              />
            </>
          </li>
        ))}
      </S.RepliesStyled>
      {showReplyForm && (
        <ReplyForm
          handleAddReply={handleAddReply}
          handleClose={closeReplyForm}
          comment={comment}
          loggedUser={loggedUser}
        />
      )}
    </>
  );
};

RepliesList.propTypes = {
  comment: PropTypes.object,
  handleAddReply: PropTypes.func,
  handleEditReply: PropTypes.func,
  openModal: PropTypes.func,
  loggedUser: PropTypes.object
};

export default RepliesList;
