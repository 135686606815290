import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const LoadingDots = ({ showLoading }) => {
  return showLoading ? (
    <S.Wrapper>
      <S.LoadingDots aria-label="loading-dots" />
    </S.Wrapper>
  ) : null;
};

LoadingDots.propTypes = {
  showLoading: PropTypes.bool
};

export default LoadingDots;
