import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'build-custom-ckeditor5';
import PropTypes from 'prop-types';
import React from 'react';
import './custom.css';
import UploadAdapterPlugin from './uploadAdapter';

const toolBarButtons = [
  'heading',
  '|',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  '|',
  'outdent',
  'indent',
  'alignment',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'blockQuote',
  'horizontalLine',
  'codeBlock',
  'code',
  '|',
  'link',
  'imageUpload',
  '|',
  'undo',
  'redo'
];

const CKEditorCustom = ({
  handleChangeContent,
  data,
  isReadOnly,
  featureId
}) => {
  return (
    <CKEditor
      editor={Editor}
      config={{
        licenseKey: '',
        extraPlugins: [UploadAdapterPlugin],
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: 'http://'
        },
        placeholder: 'Start typing your blog post here',
        toolbar: {
          items: isReadOnly ? [] : toolBarButtons,
          shouldNotGroupWhenFull: true
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            '|',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'resizeImage:25',
            'resizeImage:50',
            'resizeImage:original'
          ]
        }
      }}
      data={data}
      onChange={(event, editor) => {
        if (!isReadOnly) handleChangeContent(editor.getData());
      }}
      onReady={(editor) => {
        const toolBarElement = editor.ui.view.toolbar.element;
        toolBarElement.style.display = isReadOnly ? 'none' : 'flex';
        if (isReadOnly) {
          editor.enableReadOnlyMode(featureId);
        } else {
          const toolbarReference = document.querySelector('#toolbar');
          toolbarReference.appendChild(toolBarElement)
          editor.disableReadOnlyMode(featureId);
        }
      }}
    />
  );
};

CKEditorCustom.propTypes = {
  handleChangeContent: PropTypes.func,
  handleUploadImage: PropTypes.func,
  data: PropTypes.string,
  isReadOnly: PropTypes.bool,
  featureId: PropTypes.string
};

CKEditorCustom.defaultProps = {
  data: '',
  isReadOnly: true
};

export default CKEditorCustom;
