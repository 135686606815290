import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding-bottom: 24px;
`;

export const Count = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #003d4f;
`;

export const Description = styled.span`
  padding-left: 8px;
  padding-right: 48px;
  font-size: 16px;
  line-height: 24px;
  color: #003d4f;
`;

export const Follow = styled.div``;
