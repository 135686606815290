import React, { useState } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import * as S from './styles';
import PropTypes from 'prop-types';
// import SliderTrack from './sliderTrack';
// import SliderHandle from './sliderHandler';
// import SliderRail from './sliderRail';
import zoomInIcon from '../../../images/zoomIn-icon.svg';
import zoomOutIcon from '../../../images/zoomOut-icon.svg';

const sliderStyle = {
  position: 'relative',
  width: '100%',
  touchAction: 'none'
};

const SliderControl = ({ maxZoom, setZoom }) => {
  const minValue = 100;
  const maxValue = maxZoom > 1 ? parseInt(maxZoom * 100) : 101;
  const domain = [minValue, maxValue];
  const isDisabled = maxZoom <= 1;

  const [values, setValues] = useState([minValue]);

  const handleUpdate = (update) => {
    setZoom(update);
  };

  const handleOnChange = (value) => {
    setValues(value);
  };

  return (
    <>
      <S.SliderControlWraper>
        <S.ZoomOut src={zoomOutIcon} alt="zoom out" />
        <Slider
          mode={1}
          step={1}
          domain={domain}
          rootStyle={sliderStyle}
          onUpdate={handleUpdate}
          onChange={handleOnChange}
          values={values}
          disabled={isDisabled}
        >
          <Rail>
            {({ getRailProps }) => (
              <>
                <S.RailOuter {...getRailProps()} />
                <S.RailInner />
              </>
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle, index) => (
                  <S.WrapperHandle key={index}>
                    <S.Handle
                      // key={index}
                      handle={handle}
                      domain={domain}
                      percent={handle.percent}
                      {...getHandleProps(handle.id)}
                      disabled={isDisabled}
                    />
                    <S.HandleButton
                      role="slider"
                      aria-valuemin={domain.min}
                      aria-valuemax={domain.max}
                      aria-valuenow={handle.value}
                      disabled={isDisabled}
                      percent={handle.percent}
                    />
                  </S.WrapperHandle>
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false} disabled={isDisabled}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <S.Track
                    key={id}
                    source={source}
                    target={target}
                    {...getTrackProps()}
                    disabled={isDisabled}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
        <S.ZoomIn src={zoomInIcon} alt="zoom in" />
      </S.SliderControlWraper>
    </>
  );
};

SliderControl.propTypes = {
  maxZoom: PropTypes.number,
  setZoom: PropTypes.func
};

export default SliderControl;
