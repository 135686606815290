import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0 0 38px;
`

export const CommentLabel = styled.label`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;

export const CommentTextArea = styled.textarea`
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #c0c4d7;
    height: 143px;
    width: -webkit-fill-available;
    color: #212223;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    padding: 19px 24px 0px 24px;
    resize: none;
`;

export const WrapperButtons = styled.div` 
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;