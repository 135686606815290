import styled from 'styled-components';

export const Page = styled.main`
  max-width: 110.8rem;
  margin: 32px auto 60px;

  > ul {
    margin-top: 0;
  }

  @media screen and (max-width: 1108px) {
    margin: 0 16px 60px;
  }
`;

export const Wrapper = styled.div`
  max-width: 826px;
`;

export const TagName = styled.p`
  margin-top: 3.2rem;
  margin-bottom: 0.8rem;
  font-size: 2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.gray70};

  @media (max-width: 600px) {
    margin-top: 2rem;
    margin-bottom: 0.6rem;
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
`;

export const PageTitle = styled.h1`
  margin-bottom: 3.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 600px) {
    margin-bottom: 2.1rem;
    font-size: 2.8rem;
    line-height: 4.2rem;
  }
`;

export const ListTitle = styled.div`
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  width: max-content;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 0.8rem;

    @media (max-width: 600px) {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
`;

export const TagList = styled.ul`
  max-width: 826px;

  & > li {
    margin: 0;
    padding: 0;
    border-top: solid 1px #e2e4ec;
    width: 100%;
    display: flex;

    & > div {
      flex: 1;
    }
  }
`;
