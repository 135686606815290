import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles.js';

const TypeDefinition = (props) => {
  const { type, line } = props;
  
  const rowShift = 1;
  const rowPosition = line + rowShift;
  const columnPosition = 1;

  return (
    <S.NotificationSettingsDetailsItemType
      row={rowPosition}
      column={columnPosition}
    >
      <S.NotificationSettingsTypeLabel>{type}</S.NotificationSettingsTypeLabel>
    </S.NotificationSettingsDetailsItemType>
  );
};

TypeDefinition.propTypes = {
  type: PropTypes.string.isRequired,
  line: PropTypes.number.isRequired
};

export default TypeDefinition;
