import styled from 'styled-components';

export const NotificationItem = styled.div`
  padding: 12px 8px 0 16px;
  color: ${({ theme }) => theme.colors.text};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #eeeeef;
  }
`;

export const ClickabledArea = styled.a`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: inherit;
  cursor: pointer;
`;

export const MainContent = styled.div`
  letter-spacing: 0;
  text-decoration: none;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const AvatarNotificationIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const NotificationText = styled.p`
  flex: 1;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  text-align: left;

  > span {
    font-weight: 400;
  }
`;

export const AmountOthers = styled.span`
  font-weight: 400;

  > b {
    font-weight: 700;
  }
`;

export const Time = styled.span`
  font-size: 10px;
  color: #616364;
  margin: 6px 50px;
  float: left;
`;

export const SeparatorLine = styled.div`
  color: #616364;
  border-bottom: 1px solid #e1e2e3;
  margin-left: 50px;
`;

export const MenuKebab = styled.div``;

export const UnreadBadge = styled.div`
  z-index: 2;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  float: inline-end;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  margin: 0 0.4rem;
`;
export const StatusArea = styled.a`
  flex: 1;
  display: flex;
  flex-direction: row;
  color: inherit;
  justify-content: space-between;
  align-items: center;
`;

export const KebabContextMenu = styled.ul`
  width: 123px;
  margin: 1.6rem 0;
  padding-left: 2.4rem;

  & > li {
    margin: 0;

    & + li {
      margin-top: 20px;
    }
    & > button {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 1.4rem;
      line-height: 2.1rem;

      & > svg {
        margin-right: 1.1rem;
      }
    }
  }
`;
