import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Author from '../../Author';
import useEventTracker from '../../../hooks/useEventTracker';

const FeaturedItem = ({
  featuredImageUrl,
  featuredImageAlt,
  postTitle,
  authorName,
  authorEmail,
  username,
  publishedAt,
  postUrl,
  position
}) => {
  const isMobile = window.innerWidth <= 600;
  const sendEvent = useEventTracker();

  const getShortTitle = (title) => {
    if (!title) {
      return '';
    }
    const charLimit = 52;
    return title.length <= charLimit
      ? title
      : `${title.substring(0, charLimit)}...`;
  };

  return (
    <S.FeaturedItem>
      <S.Wrapper>
        <S.Title
          onClick={() =>
            sendEvent(
              'FeaturedList',
              `Click on item ${position}`,
              'FeaturedItem'
            )
          }
          to={{
            pathname: postUrl,
            state: { prevPath: '/' }
          }}
        >
          <h5>{getShortTitle(postTitle)}</h5>
        </S.Title>

        <Author
          authorName={authorName}
          authorEmail={authorEmail}
          username={username}
          publishedAt={publishedAt}
          showAvatar={!isMobile}
          variant={'post'}
        />
      </S.Wrapper>
      <S.PostFeaturedImage src={featuredImageUrl}>
        <span role="img" aria-label={featuredImageAlt} />
      </S.PostFeaturedImage>
    </S.FeaturedItem>
  );
};

FeaturedItem.propTypes = {
  featuredImageUrl: PropTypes.string,
  featuredImageAlt: PropTypes.string,
  postTitle: PropTypes.string,
  authorName: PropTypes.string,
  authorEmail: PropTypes.string,
  username: PropTypes.string,
  publishedAt: PropTypes.string,
  postUrl: PropTypes.string,
  position: PropTypes.number
};

export default FeaturedItem;
