import styled from 'styled-components';

export const IconButtonStyled = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 13.5px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: ${({theme}) => theme.colors.primaryDark};
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 1.5;
    width: 40px;
    padding-left: 8px;
  }
`;
