import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageUpload from './ImageUpload/imageUpload';
import Button from '../../Button';
import ImageCrop from './ImageCrop/imageCrop';
import getCroppedImageB64 from '../../../utils/images/getCroppedImageB64';
import * as S from './styles';
import { ModalAction } from '../../Modals';

const FeaturedImageDialog = (props) => {
  const { showDialog, closeDialog, featuredImage, setFeaturedImage } = props;

  const [featuredImageSrc, setFeaturedImageSrc] = useState('');
  const [altText, setAltText] = useState('');
  const [cropArea, setCropArea] = useState({});
  const [isSaveActionDisabled, setIsSaveActionDisabled] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const maxAltTextLength = 255;

  useEffect(() => {
    setFeaturedImageSrc(featuredImage.src);
    setAltText(featuredImage.alt);
    const wordSize = featuredImage.alt ? featuredImage.alt.length : 0;
    setWordCount(wordSize);
  }, [featuredImage]);

  useEffect(() => {
    if (featuredImageSrc && altText) {
      setIsSaveActionDisabled(false);
    } else {
      setIsSaveActionDisabled(true);
    }
  }, [altText, cropArea, featuredImageSrc]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openModalRemoveImage = () => {
    setShowDeleteModal(true);
  };

  const closeModalRemoveImage = () => {
    setShowDeleteModal(false);
  };

  const modalConfig = {
    showModal: false,
    message:
      'Are you sure you want to remove the image? Your post cover will be blank',
    cancelButton: 'Not now',
    submitButton: 'Yes, remove'
  };

  const handleRemoveImage = () => {
    if (featuredImageSrc) {
      setFeaturedImage({
        src: '',
        alt: '',
        croppedImage: ''
      });
      closeModalRemoveImage();
      closeDialog();
    }
  };

  const handleCancel = () => {
    setFeaturedImageSrc(featuredImage.src);
    setAltText(featuredImage.alt);
    setCropArea({});
    closeDialog();
  };

  const handleChangeAlt = (e) => {
    setAltText(e.target.value);
    setWordCount(e.target.value.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let cropedImageB64 = featuredImageSrc;
    const featuredImageSrcIsNotAnUrl = !featuredImageSrc.startsWith('https');

    if (featuredImageSrcIsNotAnUrl) {
      cropedImageB64 = await getCroppedImageB64(featuredImageSrc, cropArea);
    }

    setFeaturedImage({
      src: featuredImageSrc,
      alt: altText,
      croppedImage: cropedImageB64
    });
    closeDialog();
  };

  return (
    <>
      {showDialog && (
        <S.DialogOverlay
          role="alertdialog"
          aria-labelledby="confirmation_modal"
          aria-modal="true"
        >
          <S.Dialog data-testid="image-upload-dialog">
            <header>
              <S.UploadSelectorButton
                isActive={true}
                type="button"
                value={'upload'}
              >
                Upload
              </S.UploadSelectorButton>
              <Button
                size="m"
                variant="link"
                type="submit"
                onClick={openModalRemoveImage}
                disabled={!featuredImageSrc}
                label="Remove image"
              />
            </header>

            <form onSubmit={handleSubmit}>
              <ImageUpload
                featuredImageSrc={featuredImageSrc}
                setFeaturedImageSrc={setFeaturedImageSrc}
              />
              <ImageCrop
                featuredImageSrc={featuredImageSrc}
                setCropArea={setCropArea}
              />
              <S.AltTex>
                <p id="title">
                  Alternative text
                  <span>Mandatory</span>
                </p>
                <textarea
                  style={{ resize: 'none' }}
                  type="text"
                  value={altText}
                  onChange={handleChangeAlt}
                  maxLength={maxAltTextLength}
                  placeholder="A description of the image for more accessibility"
                />
                <S.WordCounter>
                  {wordCount}/{maxAltTextLength}
                </S.WordCounter>
              </S.AltTex>
              <S.Wrapper>
                <Button
                  size="m"
                  variant="link"
                  onClick={handleCancel}
                  disabled={false}
                  label="Cancel"
                />
                <Button
                  size="m"
                  variant="primary"
                  type="submit"
                  disabled={isSaveActionDisabled}
                  label="Save"
                />
              </S.Wrapper>
            </form>
          </S.Dialog>
          <ModalAction
            data-testid={'New'}
            showModal={showDeleteModal}
            handleConfirmation={handleRemoveImage}
            handleCancel={closeModalRemoveImage}
            message={modalConfig.message}
            cancelButton={modalConfig.cancelButton}
            submitButton={modalConfig.submitButton}
          />
        </S.DialogOverlay>
      )}
    </>
  );
};

FeaturedImageDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  featuredImage: PropTypes.object,
  setFeaturedImage: PropTypes.func
};

export default FeaturedImageDialog;
