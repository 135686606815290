import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import SearchContainer from '../../containers/SearchContainer';
import BackTop from '../../components/BackTop';
import withPreventScrollFreezing from '../withPreventScrollFreezing';

const Search = () => {

  return (
    <S.Page>
      <SearchContainer />
      <BackTop isFloating={true} positionX={60} scrollPageFactor={0.5} />
    </S.Page>
  );
};

Search.propTypes = {
  props: PropTypes.object
};

export default withPreventScrollFreezing(Search);
