import React from 'react';
import { toast } from 'react-toastify';
import ToastifySuccess from './ToastifySuccess';
import ToastifyError from './ToastifyError';
import ToastifyInfo from './ToastifyInfo';

export const showToastifySuccess = ({ title, message }) => {
  return toast.success(<ToastifySuccess title={title} message={message} />, {
    icon: false
  });
};

export const showToastifyError = ({ title, message }) => {
  return toast.error(<ToastifyError title={title} message={message} />, {
    icon: false
  });
};

export const showToastifyInfo = ({ title, message }) => {
  return toast.info(<ToastifyInfo title={title} message={message} />, {
    icon: false
  });
};

export const showToastify = (
  promise,
  { pending, successTitle, successMessage, errorTitle, errorMessage }
) => {
  toast.promise(promise, {
    pending,
    success: {
      render() {
        return (
          <ToastifySuccess title={successTitle} message={successMessage} />
        );
      },
      icon: false
    },
    error: {
      render(error) {
        return <ToastifyError title={errorTitle} message={(error.data.message !== '') ? error.data.message : errorMessage} />;
      },
      icon: false
    }
  });
};
