import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import styled from 'styled-components';
import { ButtonPink, ButtonWhiteLink } from '../buttons/Buttons';

const EditForm = ({ handleSubmit, editedText, handleCancelEdit }) => {
  return (
    <Form
      onSubmit={handleSubmit}
      className={styles.editForm}
      data-testid="editForm"
    >
      <div>
        <textarea
          id="editedText"
          type="text"
          className={styles.editTextarea}
          rows="1"
          defaultValue={editedText}
          data-testid="editText"
        />
      </div>
      <div className={styles.buttons}>
        <ButtonWhiteLink onClick={handleCancelEdit}>Cancel</ButtonWhiteLink>
        <ButtonPink type="submit" data-testid="updateButton">
          Update
        </ButtonPink>
      </div>
    </Form>
  );
};

EditForm.propTypes = {
  handleSubmit: PropTypes.func,
  editedText: PropTypes.string,
  handleCancelEdit: PropTypes.func
};

export default EditForm;

const Form = styled.form`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
`;
