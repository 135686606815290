import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import React from 'react';

export function FeatureToggle({children, featureName}) {
  const isTestEnvironment = process.env.NODE_ENV === 'test';

  if (isTestEnvironment) {
    return <div>{children}</div>;
  }

  return <IfFeatureEnabled feature={featureName}>{children}</IfFeatureEnabled>;
}
