import styled from 'styled-components';

export const Wrapper = styled.li`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  background: ${({ theme }) => theme.colors.gray20};
  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  margin: 0 8px 8px 0;
  text-transform: capitalize;

  &:last-child {
    margin-right: 0;
  }
`;

export const RemoveTag = styled.button`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;
