import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationSettings from '../../../2.0/components/NotificationSettings';
import {
  editNotificationSettings,
  getNotificationSettings
} from '../../../2.0/services/notification-settings/service';
import { getTimeZones } from '../../../2.0/services/timezones/service';
import NotFound from '../../../components/notFound';
import Button from '../../components/Button';
import DropDown from '../../components/DropDown';
import LoadingCircle from '../../components/LoadingCircle';
import NewsletterSettings from '../../components/NewsletterSettings';
import RadioButton from '../../components/RadioButton';
import { showToastify } from '../../components/ToastifyTemplate';
import { getLondonTimeZone, sortTimeZoneByOffset } from './functions';
import * as S from './styles';

const NotificationSettingsContainer = () => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const [requestState, setRequestState] = useState('loading');
  const [notificationSettings, setNotificationSettings] = useState({});
  const [immediatelyRadioBtn, setImmediatelyRadioBtn] = useState(true);
  const [dailySummaryRadioBtn, setDailySummaryRadioBtn] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [isActiveEmailSendingFrequency, setIsActiveEmailSendingFrequency] =
    useState(true);
  const [timeZones, setTimeZones] = useState([]);
  const [londonTimeZone, setLondonTimeZone] = useState(0);

  useEffect(() => {
    const canGetNotificationSettings =
      loggedUser.email !== undefined &&
      loggedUser.email !== '' &&
      timeZones.length !== 0;

    if (canGetNotificationSettings) {
      getNotificationSettings(loggedUser.email).then(
        (response) => {
          setNotificationSettings(response);
          setImmediatelyRadioBtn(response.email_frequency === 'immediately');
          setDailySummaryRadioBtn(response.email_frequency === 'daily_summary');
          handleEmailFrequencySession(response);
          setRequestState('resolved');
        },
        (error) => {
          console.error(error);
          setRequestState('error');
        }
      );
    }
  }, [loggedUser.email, timeZones]);

  useEffect(() => {
    getTimeZones().then(
      (response) => {
        setTimeZones(sortTimeZoneByOffset(response));
        setLondonTimeZone(getLondonTimeZone(response));
      },
      (error) => {
        console.error(error);
        setRequestState('error');
      }
    );
  }, []);

  const enableSaveButton = () => {
    setSaveButtonDisabled(false);
  };

  const disableSaveButton = () => {
    setSaveButtonDisabled(true);
  };

  const editNotificationSettingsAndDisableSaveButton = async (
    notificationSettings
  ) => {
    await editNotificationSettings(
      notificationSettings.id,
      notificationSettings
    );
    disableSaveButton();
  };

  const saveNotificationSettings = async () => {
    showToastify(
      editNotificationSettingsAndDisableSaveButton(notificationSettings),
      {
        pending: 'Updating...',
        successMessage: 'Your settings were saved.'
      }
    );
  };

  const handleOnSave = (event) => {
    event.preventDefault();

    saveNotificationSettings();
  };

  const handleEmailFrequencySession = (notificationSettings) => {
    if (
      !notificationSettings.email_replies &&
      !notificationSettings.email_like_post &&
      !notificationSettings.email_comment_post
    ) {
      setIsActiveEmailSendingFrequency(false);
    } else {
      setIsActiveEmailSendingFrequency(true);
    }
  };

  const handlerChangeDropDownTimeZone = (timeZone) => {
    enableSaveButton();
    handleChangeSettingsValues('time_zone', { id: timeZone.id });
  };

  const handleChangeSettingsValues = (key, newValue) => {
    const notificationSettingsUpdated = { ...notificationSettings };
    notificationSettingsUpdated[key] = newValue;

    handleEmailFrequencySession(notificationSettingsUpdated);
    setNotificationSettings(notificationSettingsUpdated);
    enableSaveButton();
  };

  const handleChangeRadioButton = (event) => {
    setImmediatelyRadioBtn(event.target.value === 'immediately');
    setDailySummaryRadioBtn(event.target.value === 'daily_summary');
    handleChangeSettingsValues('email_frequency', event.target.value);
  };

  switch (requestState) {
    case 'loading':
      return <LoadingCircle showLoading={true} loadingText="Loading ..." />;
    case 'error':
      return <NotFound />;
    default:
      return (
        <>
          <S.NotificationSettingsForm onSubmit={handleOnSave}>
            <S.Title tabIndex="0">Notification Settings</S.Title>
            <S.SessionSubtitle tabIndex="0">
              Email sending frequency
            </S.SessionSubtitle>
            <S.GroupRadioButton
              isActive={isActiveEmailSendingFrequency}
              data-testid="group-radiobtn"
            >
              <li>
                <RadioButton
                  label="Immediately"
                  checked={immediatelyRadioBtn}
                  handleChange={handleChangeRadioButton}
                  value="immediately"
                  activateHover={true}
                />
                <S.DescriptionRadioButton>
                  Receive an email for each new notification.
                </S.DescriptionRadioButton>
              </li>
              <li>
                <RadioButton
                  label="Daily summary"
                  checked={dailySummaryRadioBtn}
                  handleChange={handleChangeRadioButton}
                  value="daily_summary"
                  activateHover={true}
                />
                <S.DescriptionRadioButton>
                  Receive a summary of the latest 24 hours notifications every
                  day.
                </S.DescriptionRadioButton>
              </li>
              <S.WrapperDropDown isActive={dailySummaryRadioBtn}>
                <S.DropDownTitle id="TimeZoneLabel">
                  Select your timezone
                </S.DropDownTitle>
                <DropDown
                  ariaLabel="TimeZoneLabel"
                  onChange={handlerChangeDropDownTimeZone}
                  itens={timeZones.map((timeZone) => ({
                    id: timeZone.id,
                    text: timeZone.description
                  }))}
                  selectedItem={
                    notificationSettings.time_zone
                      ? notificationSettings.time_zone.id
                      : londonTimeZone
                  }
                />
              </S.WrapperDropDown>
            </S.GroupRadioButton>
            <S.SessionSubtitle>Notification types</S.SessionSubtitle>
            <NotificationSettings
              settingsValues={notificationSettings}
              onChangeSettingsValues={handleChangeSettingsValues}
            />
          </S.NotificationSettingsForm>

          <NewsletterSettings
            handleChange={handleChangeSettingsValues}
            isActiveWeeklyDigest={notificationSettings?.weekly_highlights}
          />

          <S.ButtonToRight>
            <Button
              label="Save"
              size="m"
              variant="primary"
              disabled={saveButtonDisabled}
              onClick={handleOnSave}
            />
          </S.ButtonToRight>
        </>
      );
  }
};

export default NotificationSettingsContainer;
