import React from 'react';
import FeaturedList from '../../../components/FeaturedList';
import { useSelector } from 'react-redux';
import FeaturedListSkeleton from '../../../components/FeaturedList/skeleton';

const FeaturedListContainer = () => {
  const featuredPosts = useSelector((state) => state.post.featuredPosts);
  const [, ...featuredPostsList] = featuredPosts;

  return featuredPostsList.length > 0 ? (
    featuredPostsList && <FeaturedList postList={featuredPostsList} />
  ) : (
    <FeaturedListSkeleton />
  );
};

export default FeaturedListContainer;
