import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  padding: 24px;
  margin-bottom: 32px;

  @media (max-width: 600px) {
    border-bottom: none;
    padding-top: 24px;
    margin-bottom: 0;
  }
`;

export const SectionName = styled.h6`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 24px;
`;

export const SectionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 52px;
  grid-template-areas:
    'title .'
    'description .';
  align-items: start;
`;

export const Title = styled.p`
  grid-area: title;
  font-size: 1.4rem;
`;

export const Description = styled.span`
  grid-area: description;
  font-size: 1.2rem;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray70};
`;
