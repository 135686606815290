import React, { useState } from 'react';
import Button from '../Button';
import * as S from './styles';
import { IconClose } from '../Icons';
import instructionsSVG from './instructions.svg';

const ImageRequirements = () => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const handleCloseModal = () => {
    setIsOpenedModal(!isOpenedModal);
  };

  const secondaryColor = ({ theme }) => theme.colors.secondary;

  return (
    <S.Wrapper>
      {isOpenedModal ? (
        <S.Modal role="dialog">
          <S.Header>
            <S.Title tabIndex={0}>Image Requirements</S.Title>
            <Button
              size="m"
              variant="link"
              onClick={handleCloseModal}
              icon={<IconClose />}
              iconColor={secondaryColor}
              aria-label="close image requiriments modal"
            />
          </S.Header>

          <S.Content tabIndex={0}>
            <h6>Alternative text</h6>
            <p>
              Blog Central is a place for everyone, so alternative text is
              <b> mandatory</b>, the alt text describes what is in your image,
              this will help the blog to be more inclusive. <br />
              <br />
              To add alt text, you can select the image you added and choose the
              eye icon:
            </p>

            <S.Picture>
              <img
                src={instructionsSVG}
                alt="click in the first option to insert or change the alternative text"
              />
            </S.Picture>

            <h6>Size and formats</h6>
            <p>
              Make sure your image is in one of these <b>.jpg</b>, <b>.png </b>
              and <b>.gif</b> formats and is <b>5 Megabytes</b> or less.
            </p>
            <br />
          </S.Content>
        </S.Modal>
      ) : (
        <Button
          size="m"
          variant="link"
          label="Image requirements"
          onClick={handleCloseModal}
        />
      )}
    </S.Wrapper>
  );
};

export default ImageRequirements;
