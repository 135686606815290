import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 4rem;

  & > hr {
    width: 100%;
    border: none;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.gray30};
  }
  & > h2 {
    font-size: 2.0rem;
    line-height: 3.0rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const MostUsedTagsList = styled.ul`
  & > li {
    width: 100%;
    margin-top: 0.7rem;
    border-top: solid 1px #e2e4ec;
    + li {
      margin-top: 1.7rem;
    }
    & > div {
    margin-top: 1.7rem;
    }
  }
`;

export const Content = styled.div`
    display: flex;
`
export const Title = styled(Link)`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.0rem;
  width: 100%;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media screen and (max-width: 600px) {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
`;

export const Icon = styled.span`
    margin-top: 0.25rem;
    margin-left: 0.9rem;

  @media screen and (max-width: 600px) {
    margin-top: 0.5rem;
    margin-left: 0.9rem;
  }
`

export const AllPosts = styled(Link)`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  width: 100%;
  text-transform: capitalize;
  text-align: end;
  color: ${({ theme }) => theme.colors.primaryHover};
  text-decoration: underline;

  &> span{
    all: none;
    text-decoration: none !important;
  }

  &:hover {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media screen and (max-width: 600px) {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
`;
