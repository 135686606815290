import React from 'react';
import PropTypes from 'prop-types';
import CheckboxTickIcon from '../../../images/checkbox-tick-icon.svg';
import * as S from './styles';

export const Checkbox = ({
  label,
  checked,
  setChecked,
  sharpBorder,
  activateHover,
  id,
  ariaLabel
}) => {
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 32) {
      const e = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      event.target.dispatchEvent(e);
    }
  };

  const getCheckBoxState = () => {
    return `checkbox ${checked ? 'checked' : 'not checked'}`;
  };

  return (
    <S.Checkbox
      tabIndex="0"
      checked={checked}
      onClick={handleCheckboxChange}
      onKeyDown={handleKeyDown}
      aria-labelledby="checkbox-label"
      aria-label="checkbox-label"
    >
      <S.HiddenCheckbox
        onChange={handleCheckboxChange}
        checked={checked}
        aria-labelledby="checkbox-label"
      />
      <S.StyledCheckbox
        checked={checked}
        sharpborder={sharpBorder}
        activatehover={activateHover}
        id={id}
      >
        <img
          alt={`${ariaLabel} ${getCheckBoxState()}`}
          src={CheckboxTickIcon}
        />
      </S.StyledCheckbox>
      {label && (
        <S.Text id="checkbox-label" name="checkbox-label" checked={checked}>
          {label}
        </S.Text>
      )}
    </S.Checkbox>
  );
};

Checkbox.propTypes = {
  label: PropTypes.any,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  sharpBorder: PropTypes.bool,
  activateHover: PropTypes.bool,
  id: PropTypes.string,
  ariaLabel: PropTypes.string
};

Checkbox.defaultProps = {
  ariaLabel: ''
};
