import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Button from '../Button';

const HeaderClean = (props) => {
  const {
    handleCancelAction,
    handleSubmitAction,
    submitButtonLabel,
    submitButtonDisabled
  } = props;

  return (
    <S.Header>
      <S.Container>
        <Button
          size="m"
          variant="link"
          onClick={handleCancelAction}
          disabled={false}
          label="Cancel"
        />

        <Button
          size="m"
          variant="primary"
          onClick={handleSubmitAction}
          disabled={submitButtonDisabled}
          label={submitButtonLabel}
        />
      </S.Container>
    </S.Header>
  );
};

HeaderClean.propTypes = {
  handleCancelAction: PropTypes.func,
  mode: PropTypes.string,
  handleMode: PropTypes.func,
  handleSubmitAction: PropTypes.func,
  submitButtonLabel: PropTypes.string,
  submitButtonDisabled: PropTypes.bool
};

export default HeaderClean;
