import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NotFound from '../../../components/notFound';
import Button from '../../components/Button';
import LoadingCircle from '../../components/LoadingCircle';
import LoadingDots from '../../components/LoadingDots';
import PostSummary from '../../components/PostSummary';
import ProfileHeader from '../../components/ProfileHeader';
import { getFollowers, getFollowings } from '../../services/followers/service';
import { getUserPosts } from '../../services/posts/service';
import { getUserByUsername } from '../../services/users/service';
import { closeModal, showModal } from '../../store/modals/actions';
import {
  addPostsOnProfilePostsList,
  cleanAllProfileInfo,
  cleanUserPostsList,
  forceProfileUpdate,
  setProfileHasMorePosts,
  setProfileIsLoggedUser,
  setProfileUser
} from '../../store/profile/actions';
import FollowersContainer from '../FollowersContainer';
import * as S from './styles';

const ProfileContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const LIMIT_POSTS_PER_REQUEST = 6;
  const dispatch = useDispatch();

  const [userPostsFeedbackMessage, setUserPostsFeedbackMessage] =
    useState(null);
  const [notFoundUser, setNotFoundUser] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const { username: usernameFromPath } = useParams();

  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const user = useSelector((state) => state.profile.user);
  const isLoggedUser = useSelector((state) => state.profile.isLoggedUser);
  const postsList = useSelector((state) => state.profile.postsList);
  const hasMorePosts = useSelector((state) => state.profile.hasMorePosts);
  const profileUpdate = useSelector(
    (state) => state.profile.forceProfileUpdate
  );

  useEffect(() => {
    if (user.username && user.username !== usernameFromPath) {
      dispatch(cleanAllProfileInfo());
    }

    if (loggedUser.username) {
      loggedUser.username === usernameFromPath
        ? manageLoggedUserInfo()
        : manageNotLoggedUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser.username, usernameFromPath, user.username]);

  useEffect(() => {
    if (user.username) {
      if (profileUpdate) {
        dispatch(cleanUserPostsList());
        dispatch(forceProfileUpdate(false));
        dispatch(setProfileHasMorePosts(true));
      }

      getAmountFollow();
      fetchUserPostsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.username, profileUpdate]);

  const getAmountFollow = async () => {
    const followers = await getFollowers(loggedUser.email);
    const followings = await getFollowings(loggedUser.email);

    const orderlyFollowers = sortFollowers(followers, followings);
    const orderlyFollowings = sortFollowings(followers, followings);

    setFollowers(orderlyFollowers);
    setFollowings(orderlyFollowings);

    if (location?.from === 'follower-notification') {
      openFollowModalList([...orderlyFollowers], 'Follower');
    }
  };

  const sortFollowers = (followers, followings) => {
    const usersWhoFollowMeAndIFollowThem = followers?.filter((follower) =>
      followings.some((following) => follower.username === following.username)
    );

    const orderlyFollowers = new Set([
      ...usersWhoFollowMeAndIFollowThem,
      ...followers
    ]);

    return [...orderlyFollowers];
  };

  const sortFollowings = (followers, followings) => {
    const usersWhoFollowMeAndIFollowThem = followings?.filter((follower) =>
      followers.some((following) => follower.username === following.username)
    );

    const orderlyFollowings = new Set([
      ...usersWhoFollowMeAndIFollowThem,
      ...followings
    ]);

    return [...orderlyFollowings];
  };

  const openFollowModalList = (resourceList, relationType) => {
    dispatch(
      showModal({
        showModal: true,
        message: relationType,
        type: 'follow',
        list: resourceList,
        handleCancel: () => {
          dispatch(closeModal());
        }
      })
    );
  };

  const fetchUserPostsList = async () => {
    const paginationStart = postsList.length;

    if (hasMorePosts) {
      const response = await getUserPosts(
        LIMIT_POSTS_PER_REQUEST,
        paginationStart,
        usernameFromPath
      );

      if (response.length < LIMIT_POSTS_PER_REQUEST) {
        dispatch(setProfileHasMorePosts(false));
      }

      if (response.length === 0 && postsList.length === 0) {
        isLoggedUser
          ? setUserPostsFeedbackMessage("You don't have any post yet")
          : setUserPostsFeedbackMessage(
              `${user.name} hasn't published any post yet`
            );
      }

      dispatch(addPostsOnProfilePostsList(response));

      return [...postsList, ...response];
    }
  };

  const manageNotLoggedUserInfo = async () => {
    setNotFoundUser(false);
    try {
      const userInfo = await getUserByUsername(usernameFromPath);
      dispatch(setProfileUser(userInfo));
      dispatch(setProfileIsLoggedUser(false));
    } catch {
      setNotFoundUser(true);
    }
  };

  const manageLoggedUserInfo = async () => {
    dispatch(setProfileUser(loggedUser));
    dispatch(setProfileIsLoggedUser(true));
  };

  const createPost = () => {
    history.push(`/post/create`, { from: `/${loggedUser.username}` });
  };

  if (notFoundUser) return <NotFound />;

  return (
    <>
      <ProfileHeader user={user} allowEdit={isLoggedUser} />
      <FollowersContainer />
      {isLoggedUser ? (
        <S.Wrapper>
          <S.WrapperFollow>
            <S.Followers
              tabIndex={0}
              role="button"
              followers={followers?.length}
              aria-label={`${followers?.length} followers`}
              onClick={() => openFollowModalList(followers, 'Follower')}
            >
              <S.Amount>{followers?.length}</S.Amount>
              <S.Text>followers</S.Text>
            </S.Followers>

            <S.Following
              tabIndex={0}
              role="button"
              followings={followings?.length}
              aria-label={`${followings?.length} following`}
              onClick={() => openFollowModalList(followings, 'Following')}
            >
              <S.Amount>{followings?.length}</S.Amount>
              <S.Text>following</S.Text>
            </S.Following>
          </S.WrapperFollow>

          <S.WrapperAction>
            <Button
              label="Create a Post"
              size="m"
              variant="outlined"
              onClick={createPost}
            />
          </S.WrapperAction>
        </S.Wrapper>
      ) : null}
      <S.PostListHeader tabIndex={0}>
        <h4>{isLoggedUser ? 'My Posts' : 'Posts'} </h4>
      </S.PostListHeader>
      {userPostsFeedbackMessage ? (
        <S.FeedbackMessage>{userPostsFeedbackMessage}</S.FeedbackMessage>
      ) : (
        <InfiniteScroll
          dataLength={postsList.length}
          next={fetchUserPostsList}
          hasMore={hasMorePosts}
          loader={
            postsList.length === 0 ? (
              <LoadingCircle showLoading={true} loadingText="Loading..." />
            ) : (
              <LoadingDots showLoading={true} />
            )
          }
          endMessage={
            <S.MessageScroll>Yay! You have seen it all</S.MessageScroll>
          }
        >
          <S.PostsList>
            {postsList.map((post, index) => (
              <li key={index}>
                <PostSummary
                  isAuthor={isLoggedUser}
                  post={post}
                  variant={isLoggedUser ? 'profile' : 'home'}
                  position={index}
                  origin="LatestPosts"
                />
              </li>
            ))}
          </S.PostsList>
        </InfiniteScroll>
      )}
    </>
  );
};

export default ProfileContainer;
