import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const MessageScroll = (props) => {
  const { message } = props;

  return <S.Message>{message}</S.Message>;
};

MessageScroll.propTypes = {
  message: PropTypes.string
};

MessageScroll.defaultProps = {
  message: 'message'
};

export default MessageScroll;
