import React from 'react';
import * as S from './styles';
import PropTypes from 'prop-types';

const RadioButton = (props) => {
  const { label, checked, handleChange, value, activateHover } = props;

  const handleKeyDown = (event) => {
    if (event.keyCode === 32) {
      const e = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      event.target.dispatchEvent(e);
    }
  };

  return (
    <S.RadioWrapper>
      <S.Label>
        <S.HiddenInput
          name="radioBtn"
          type="radio"
          checked={checked}
          onChange={handleChange}
          value={value}
        />
        <S.StyledRadioButton
          tabIndex="0"
          onKeyDown={handleKeyDown}
          aria-label={label}
          activateHover={activateHover}
          checked={checked}
        />
        <div>{label}</div>
      </S.Label>
    </S.RadioWrapper>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  activateHover: PropTypes.bool,
  handleChange: PropTypes.func
};

export default RadioButton;
