import React, { Fragment } from 'react';
import * as S from './styles.js';

export const ActionForConfirmation = () => {
  window.open('https://t.maze.co/75147846?guerilla=true', '_blank');
};

export const FeedBackCKEditor = (
  <S.Container data-testid="modalTitle" aria-label="confirmation_modal">
    <Fragment>
      <S.Paragraph tabIndex={0}>
        Hey there, congrats for your awesome new post!
      </S.Paragraph>
      <S.Paragraph tabIndex={0}>
        You&apos;ve just used our fresh <strong>new text editor</strong>, could
        you please share some <strong>feedback</strong> about your experience
        and help us improve even more?
      </S.Paragraph>
      <S.Note tabIndex={0}>
        The survey has only 4 questions (1 rating, 2 yes/no and 1 open)
      </S.Note>
    </Fragment>
  </S.Container>
);
