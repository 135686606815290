import PropTypes from 'prop-types';
import React from 'react';
import Author from '../../Author';
import Dialog from '../../Dialog';
import { IconDelete, IconEdit, IconKebab } from '../../Icons';
import * as S from './styles';

const ItemHeader = ({ item, editItem, removeItem, allowEdit }) => {

  const handleEdit = () => {
    editItem(item)
  }

  const handleDelete = () => {
    removeItem(item)
  };

  const MoreActionsMobileButton = () => (
    <S.MoreActionsButton aria-label="more actions">
      <IconKebab size="l" color="#808285" disabledHover={true} />
    </S.MoreActionsButton>
  );

  return (
    <S.Wrapper>
      {item.author && (
        <Author
          authorName={item.author.name}
          authorEmail={item.author.email}
          username={item.author.username}
          publishedAt={item.updated_at}
          isEdited={new Date(item.created_at).setSeconds(0, 0) !== new Date(item.updated_at).setSeconds(0, 0)}
          sizeAvatar={'m'}
          variant={'comment'}
        />
      )}

      {allowEdit && (
        <div>
          <S.ButtonsDesktop>
            <S.ButtonsStyled aria-label="Edit" onClick={handleEdit}>
              <IconEdit size="s" color="#808285" disabledHover={true} />
            </S.ButtonsStyled>
            <S.ButtonsStyled aria-label="Delete" onClick={handleDelete}>
              <IconDelete size="s" color="#808285" disabledHover={true} />
            </S.ButtonsStyled>
          </S.ButtonsDesktop>
          <Dialog
            trigger={<MoreActionsMobileButton />}
            boxPosition="bottom"
            boxDistanceX={5}
            boxDistanceY={45}
            connectorPosition="right"
            connectorDistance={10}
          >
            <S.MobileMenu>
              <li>
                <button aria-label="edit" onClick={handleEdit}>
                  <IconEdit size="m" color="#4D7784" disabledHover={true} />
                  Edit
                </button>
              </li>
              <li>
                <button aria-label="edit" onClick={handleDelete}>
                  <IconDelete size="m" color="#4D7784" disabledHover={true} />
                  Delete
                </button>
              </li>
            </S.MobileMenu>
          </Dialog>
        </div>
      )}
    </S.Wrapper>
  );
};

ItemHeader.propTypes = {
  item: PropTypes.object,
  editItem: PropTypes.func,
  removeItem: PropTypes.func,
  allowEdit: PropTypes.bool
};

export default ItemHeader;
