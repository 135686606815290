import styled from 'styled-components';

export const Page = styled.main`
  max-width: 82.3rem;
  margin: 0 auto 60px;

  @media screen and (max-width: 823px) {
    margin: 0 16px 60px;
  }
`;
