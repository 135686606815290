import styled from 'styled-components';

export const SearchHeader = styled.li`
  padding-top: 4rem;

  button {
    font-size: 1.6rem;
    font-weight: 600;
    width: ${(props) => (props.owner ? 7.2 : 4.6)}rem;
    padding-bottom: 1.1rem;
    color: ${({ theme }) => theme.colors.secondary};
    width: 100%;

    ${({ isActive, theme }) =>
      isActive === true
        ? `
      border-bottom: 3px solid ${theme.colors.primary};
    `
        : null}
  }
`;
