import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styles';

const ReplyButton = ({ onClick }) => {
  return (
    <S.IconButtonStyled onClick={onClick} aria-label="replycomment">
      {/* <img src={ReplyIcon} alt="reply comment" aria-hidden="true" /> */}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33325 15L4.30325 12.6667H13.9999C14.3681 12.6667 14.6666 12.3682 14.6666 12V2.66667C14.6666 2.29848 14.3681 2 13.9999 2H1.99992C1.63173 2 1.33325 2.29848 1.33325 2.66667V15ZM13.3333 11.3333H3.84192L2.66659 12.2567V3.33333H13.3333V11.3333Z"
          fill="#BD4257"
        />
      </svg>
      <p>Reply</p>
    </S.IconButtonStyled>
  );
};

ReplyButton.propTypes = {
  onClick: PropTypes.func
};

export default ReplyButton;
