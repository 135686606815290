import { SHOW_LOADING } from '../actionTypes';

const initialState = {
  showLoading: false
};

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: action.showLoading
      };
    default:
      return state;
  }
};
