import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import CKEditorCustom from '../../../CKEditorCustom';
import FeaturedImage from '../../../FeaturedImage';
import ImageRequirements from '../../../ImageRequirements';
import TagFormAutocomplete from '../../../Tag/FormAutocomplete';
import * as S from './styles';

const EditorBody = ({
  handleChangeContent,
  handleChangeTitle,
  handleChangeFeaturedImage,
  post,
  showCK,
  duplicatedTitleError,
  featuredImage,
  setTags,
  handleUploadImage,
  isCoverEnabled,
  setIsCoverEnabled,
  handleTagsAutocomplete
}) => {
  const handleChange = (event) => {
    const key = event.keyCode || event.charCode;
    if (key !== 13) handleChangeTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    const key = event.keyCode || event.charCode;
    if (key === 13) event.preventDefault();
  };

  const handleInput = (event) => {
    event.target.style.height = '5px';
    event.target.style.height = event.target.scrollHeight + 'px';
  };

  useEffect(() => {
    handleChangeFeaturedImage({ ...featuredImage, isCoverEnabled });

    // eslint-disable-next-line
  }, [isCoverEnabled]);

  return (
    <S.Page>
      <S.GridLayout>
        <S.Toolbar id="toolbar" />
        <S.PostArticle>
          <S.InputTitle
            name="postTitle"
            maxLength={75}
            onKeyPress={handleKeyPress} // TODO: #33 Keypress event is deprecated
            onChange={handleChange}
            placeholder="Insert the post title here"
            defaultValue={post.title}
            onInput={handleInput}
            aria-label="Insert the post title here"
          />
          {duplicatedTitleError && (
            <S.DuplicatedTitleError aria-label="title duplicated alert">
              It seems that you have another post with this title. Please, write
              a different one.
            </S.DuplicatedTitleError>
          )}
          {isCoverEnabled === true && featuredImage.croppedImage && (
            <S.PostCover
              src={featuredImage.croppedImage}
              alt={featuredImage.alt}
            />
          )}

          {showCK && (
            <CKEditorCustom
              handleChangeContent={handleChangeContent}
              data={post.content}
              handleUploadImage={handleUploadImage}
              isReadOnly={false}
              featureId="ck-post-editor"
            />
          )}
        </S.PostArticle>
        <S.AsideContent>
          <S.ImageRequirements>
            <ImageRequirements />
          </S.ImageRequirements>
          <TagFormAutocomplete
            tags={post.tags}
            setTags={setTags}
            handleTagsAutocomplete={handleTagsAutocomplete}
          />
          <FeaturedImage
            checked={isCoverEnabled}
            setChecked={setIsCoverEnabled}
            featuredImage={featuredImage}
            setFeaturedImage={handleChangeFeaturedImage}
          />
        </S.AsideContent>
      </S.GridLayout>
    </S.Page>
  );
};

EditorBody.propTypes = {
  handleChangeContent: PropTypes.func,
  handleChangeTitle: PropTypes.func,
  handleChangeFeaturedImage: PropTypes.func,
  post: PropTypes.object,
  showCK: PropTypes.bool,
  duplicatedTitleError: PropTypes.bool,
  featuredImage: PropTypes.object,
  setTags: PropTypes.func,
  handleUploadImage: PropTypes.func,
  isCoverEnabled: PropTypes.bool,
  setIsCoverEnabled: PropTypes.func,
  handleTagsAutocomplete: PropTypes.func
};

EditorBody.defaultProps = {
  showCK: true,
  post: { title: '', content: '' },
  duplicatedTitleError: false,
  featuredImage: null
};

export default EditorBody;
