import { LIST_NOTIFICATIONS } from '../actionTypes';

export const notificationDefault = {
  notificationList: []
};

export const notificationReducer = (state = notificationDefault, action) => {
  switch (action.type) {
    case LIST_NOTIFICATIONS:
      return {
        ...state.notification,
        notificationList: action.payload.notifications
      };
    default:
      return state;
  }
};
