import { blogApiNew } from '../../servers/blogApiNew';

export const getTimeZones = async () => {
  const data = await blogApiNew.get(
    '/time-zones?pagination[start]=0&pagination[limit]=100'
  );

  if (!data || (data && data.length === 0)) {
    throw new Error('Could not get timezones.');
  }

  return data;
};
