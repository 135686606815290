import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

const BackLinkMobile = (props) => {
  const { text } = props;
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <S.Container>
      <S.BackLinkStyled onClick={handleClick}>
        <S.BackArrow
          width="16"
          height="16"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5235 6.16689H13.6668V7.83355H3.5235L7.9935 12.3036L6.81516 13.4819L0.333496 7.00022L6.81516 0.518555L7.9935 1.69689L3.5235 6.16689Z"
            fill="#212223"
          />
        </S.BackArrow>
        <S.Text>{text}</S.Text>
      </S.BackLinkStyled>
    </S.Container>
  );
};

export default BackLinkMobile;

BackLinkMobile.propTypes = {
  text: PropTypes.string.isRequired
};
