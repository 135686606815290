import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Desktop = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const PostSummary = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 24px;
  position: relative;
`;

export const PostSummaryContainer = styled.div`
  display: flex;

  & > div:first-child {
    flex: 1;
    & > div {
      display: flex;
    }
  }
`;

export const PostSummaryTitle = styled(Link)`
  text-decoration: none;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  overflow-wrap: break-word;
  word-wrap: break-word;

  :hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

export const PostSummaryContent = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 14px;
  word-break: break-word;

  @media screen and (max-width: 600px) {
    margin-top: 12px;
  }
`;

export const PostInfo = styled.div`
  display: flex;
  align-items: center;

  & div {
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const PostInfoAuthor = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #616364;
  margin: 2px 0px;
`;

export const PostInfoDate = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #808285;
`;

export const PostFeaturedImage = styled.div`
  span {
    box-sizing: border-box;
    width: 192px;
    height: 149px;
    margin-left: 24px;
    border-radius: 4px;
    display: flex;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
      width: 108px;
      height: 84px;
      margin-left: 12px;
    }
  }
`;

export const PostDate = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #616364;
  margin-bottom: 8px;
`;


export const WrapperSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PostSummaryContainerSkeleton = styled.div`
  display: flex;

  & > div:first-child {
    flex: 1;
    & > div {
      display: flex;
    }
  }
`;


export const PostSummarySkeleton = styled.div`
  display: flex;
  width:100%;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 24px;
  position: relative;
`;

export const PostSummaryTitleSkeleton = styled.a`
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  display: block;
  text-decoration: none;
  width: 100%;


  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }




  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
  }
`;
