export const removeInvalidCharacters = (text) => {
  return text.replace(/,/g, '').replace(/^#/g, '').trim();
};

export const hasAnInvalidSize = (tag) => {
  return tag.length > 25;
};

export const hasPercentChar = (tag) => {
  return /%/i.test(tag);
};

export const isRepeated = (tag, tagList = []) => {
  const tagsLowerCase = tagList.map((i) => i.name.toLowerCase());
  return tagsLowerCase.includes(tag.toLowerCase());
};
