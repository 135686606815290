import React from 'react';
import * as S from './styles';
import ProfileContainer from '../../containers/ProfileContainer';
import BackTop from '../../components/BackTop';
import withPreventScrollFreezing from '../withPreventScrollFreezing';

const Profile = () => {
  return (
    <S.Page>
      <div>
        <ProfileContainer />
      </div>
      <BackTop isFloating={true} positionX={180} scrollPageFactor={0.5} />
    </S.Page>
      ) ;
};

export default withPreventScrollFreezing(Profile);
