import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifications from '../../components/Notifications';
import { showToastify } from '../../components/ToastifyTemplate';
import {
  deleteNotification,
  listNotificationsByReceiverId,
  markAllAsRead,
  updateNotificationStatus
} from '../../services/notifications/service';
import { listNotificationsAction } from '../../store/notification/actions';

const NotificationContainer = () => {
  const [newNotificationsAmount, setNewNotificationsAmount] = useState(0);
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const notifications = useSelector(
    (state) => state.notification.notificationList
  );

  useEffect(() => {
    if (loggedUser.id) {
      getUserNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser.id]);

  const getUserNotifications = () => {
    listNotificationsByReceiverId(loggedUser.id).then((result) => {
      dispatch(listNotificationsAction({ notifications: result }));
    });
  };

  const deleteNotificationAndUpdateStore = async (id) => {
    const response = await deleteNotification(id);

    const updatedNotifications = notifications.filter(
      (notification) => notification.id != response.id
    );

    dispatch(
      listNotificationsAction({
        notifications: updatedNotifications
      })
    );

    setNewNotificationsAmount(0);
  };

  const onDeleteNotificationItem = async (id) => {
    showToastify(deleteNotificationAndUpdateStore(id), {
      pending: 'Deleting...',
      successMessage: 'Notification was deleted.'
    });
  };

  const handleShowNotifications = async () => {
    await markNewNotificationAsUnread();
    getUserNotifications();
  };

  const markNewNotificationAsUnread = async () => {
    let newNotifications = notifications;

    if (notifications.length > 0) {
      newNotifications = notifications.filter(
        (notification) => notification.status === 'new'
      );
    }

    if (newNotifications && newNotifications.length > 0) {
      try {
        for (let notification of newNotifications) {
          await updateNotificationStatus(notification.id, 'unread');
        }
        setNewNotificationsAmount(0);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateNotificationOnStore = (notificationUpdated) => {
    const newNotifications = [...notifications];
    const notificationIndex = newNotifications.findIndex(
      (notification) => notification.id === notificationUpdated.id
    );
    if (notificationIndex > -1) {
      newNotifications[notificationIndex] = notificationUpdated;
      dispatch(listNotificationsAction({ notifications: newNotifications }));
    }
  };

  const updateNotificationToRead = (notificationId) => {
    updateNotificationStatus(notificationId, 'read').then(
      (notificationUpdated) => {
        updateNotificationOnStore(notificationUpdated);
      }
    );
  };

  const markAllAsReadAndUpdateStore = async (unreadNotifications) => {
    await markAllAsRead(unreadNotifications);
    unreadNotifications.forEach((notification) =>
      updateNotificationOnStore({ ...notification, status: 'read' })
    );
  };

  const handleMarkAllAsRead = async () => {
    const unreadNotifications = notifications.filter(
      (notification) => notification.status === 'unread'
    );
    showToastify(markAllAsReadAndUpdateStore(unreadNotifications), {
      pending: 'Updating...',
      successMessage: 'All notifications were marked as read.'
    });
  };

  return (
    <Notifications
      handleShowNotifications={handleShowNotifications}
      notifications={notifications}
      newNotificationsAmount={newNotificationsAmount}
      setNewNotificationsAmount={setNewNotificationsAmount}
      onDeleteNotificationItem={onDeleteNotificationItem}
      updateNotificationItem={updateNotificationToRead}
      handleMarkAllAsRead={handleMarkAllAsRead}
    />
  );
};

export default NotificationContainer;
