import {
  LIST_FEATURED_POSTS,
  GET_LATEST_POSTS,
  GET_USER_POSTS,
  CLEAN_USER_POSTS,
  ADD_LIKE_LATEST_POSTS,
  ADD_LIKE_FEATURED_POSTS,
  REMOVE_LIKE_LATEST_POSTS,
  REMOVE_LIKE_FEATURED_POSTS,
  SET_READ_POST_ID
} from '../actionTypes';

const initialState = {
  featuredPosts: [],
  latestPosts: [],
  userPosts: [],
  readPostId: ''
};

export const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_FEATURED_POSTS:
      return {
        ...state,
        featuredPosts: action.featuredPosts
      };
    case GET_LATEST_POSTS:
      return {
        ...state,
        latestPosts: [...state.latestPosts, ...action.latestPosts]
      };
    case GET_USER_POSTS:
      return {
        ...state,
        userPosts: [...state.userPosts, ...action.payload]
      };
    case CLEAN_USER_POSTS:
      return {
        ...state,
        userPosts: [] 
      };
    case ADD_LIKE_LATEST_POSTS: {
      const latestPostListUpdated = [...state.latestPosts].map((post) => {
        if (post.id === action.payload.post) {
          post.likes = [action.payload,...post.likes];
        }
        return post;
      });
      return {
        ...state,
        latestPosts: latestPostListUpdated
      };
    }
    case ADD_LIKE_FEATURED_POSTS: {
      const featuredPostListUpdated = [...state.featuredPosts].map((post) => {
        if (post.id === action.payload.post) {
          post.likes = [...post.likes, action.payload];
        }
        return post;
      });
      return {
        ...state,
        featuredPosts: featuredPostListUpdated
      };
    }
    case REMOVE_LIKE_LATEST_POSTS: {
      const latestPostListUpdated = [...state.latestPosts].map((post) => {
        if (post.id === action.payload.post) {
          post.likes = [...post.likes.filter(like => like.id !== action.payload.id)];
        }
        return post;
      });
      return {
        ...state,
        latestPosts: latestPostListUpdated
      };
    }
    case REMOVE_LIKE_FEATURED_POSTS: {
      const featuredPostListUpdated = [...state.featuredPosts].map((post) => {
        if (post.id === action.payload.post) {
          post.likes = [...post.likes.filter(like => like.id !== action.payload.id)];
        }
        return post;
      });
      return {
        ...state,
        featuredPosts: featuredPostListUpdated
      };
    }
    case SET_READ_POST_ID: {
      return {
        ...state,
        readPostId: action.payload
      }
    }
    default:
      return state;
  }
};
