import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFeaturedPosts } from '../../services/posts/service';

const FeaturedPostsContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getFeaturedPosts(dispatch);
  }, [dispatch]);

  return null;
};

export default FeaturedPostsContainer;
