import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AnchorBell from '../AnchorBell';
import Button from '../Button';
import Dialog from '../Dialog';
import { IconSettings } from '../Icons';
import NotificationItem from './NotificationItem';
import * as S from './styles';

const Notifications = ({
  handleShowNotifications,
  notifications,
  newNotificationsAmount,
  setNewNotificationsAmount,
  onDeleteNotificationItem,
  updateNotificationItem,
  handleMarkAllAsRead
}) => {
  const [closeDialog, setCloseDialog] = useState(false);
  const [disableButtonReadAll, setDisableButtonReadAll] = useState(false);

  useEffect(() => {
    if (notifications?.length >= 0) {
      const checkUnreadNotification = notifications.some(
        (notification) => notification.status === 'unread'
      );
      setDisableButtonReadAll(!checkUnreadNotification);
    }
  }, [notifications]);

  useEffect(() => {
    let newNotifications = notifications;

    if (notifications.length > 0) {
      newNotifications = notifications.filter(
        (notification) => notification.status === 'new'
      );
    }

    if (newNotifications.length > 0) {
      setNewNotificationsAmount(newNotifications.length);
    }
  }, [notifications, setNewNotificationsAmount]);

  const handleOnMouseLeave = () => {
    document.querySelector('body').style.overflow = 'auto';
  };

  const handleOnMouseEnter = () => {
    document.querySelector('body').style.overflow = 'hidden';
  };

  const buildNotificationTriggerAriaLabel = () => {
    const baseAriaLabel = 'Blog notifications';

    if (newNotificationsAmount > 0) {
      return `${baseAriaLabel}, you have ${newNotificationsAmount} new notifications`;
    }

    return baseAriaLabel;
  };

  const TriggerButton = (
    <S.NotificationButton
      role="button"
      tabIndex={0}
      aria-label={buildNotificationTriggerAriaLabel()}
    >
      <AnchorBell showBadge={newNotificationsAmount > 0} />
    </S.NotificationButton>
  );

  const history = useHistory();
  const handleNotificationsSettingsOnClick = () => {
    history.push({
      pathname: '/notification-settings',
      state: {
        prevPath: history.location.pathname
      }
    });
    history.go();
  };

  const handleDeleteNotification = (id) => {
    onDeleteNotificationItem(id);
  };

  const handleUpdateNotification = async (notification) => {
    if (notification.status !== 'read') {
      await updateNotificationItem(notification.id);
    }
    setCloseDialog(!closeDialog);
  };

  return (
    <S.Wrapper>
      <Dialog
        trigger={TriggerButton}
        boxPosition="bottom"
        boxDistanceX={8}
        boxDistanceY={45}
        connectorPosition="right"
        connectorDistance={12}
        name="notificationCentral"
        closeDialog={closeDialog}
        ariaLabel="Notifications"
        handleEntryDialog={handleShowNotifications}
      >
        <S.DialogContent
          id="notifications"
          data-testid="dialogContent"
          onMouseLeave={handleOnMouseLeave}
          onMouseEnter={handleOnMouseEnter}
        >
          <S.Header>
            <S.Title>Notifications</S.Title>
            <S.MarkAllAsReadContainer>
              <Button
                size="s"
                variant="link"
                onClick={handleMarkAllAsRead}
                disabled={disableButtonReadAll}
                label="Mark all as read"
              />
              <S.NotificationSettingsArea>
                <S.NotificationSettingsButton
                  tabIndex={0}
                  aria-label="settings of notifications"
                  onClick={handleNotificationsSettingsOnClick}
                >
                  <IconSettings color="#003D4F" size="m" disabledHover={true} />
                </S.NotificationSettingsButton>
              </S.NotificationSettingsArea>
            </S.MarkAllAsReadContainer>
          </S.Header>
          {notifications.length > 0 ? (
            <S.NotificationsList tabIndex={0} aria-label="Notifications List">
              {notifications.map((notification, index) => (
                <li key={index}>
                  <NotificationItem
                    notificationData={notification}
                    onDeleteNotification={handleDeleteNotification}
                    updateNotification={handleUpdateNotification}
                  />
                </li>
              ))}
            </S.NotificationsList>
          ) : (
            <S.EmptyNotification tabIndex={0}>
              You don&apos;t have any notifications yet
            </S.EmptyNotification>
          )}
        </S.DialogContent>
      </Dialog>
    </S.Wrapper>
  );
};

Notifications.propTypes = {
  handleShowNotifications: PropTypes.func,
  notifications: PropTypes.array,
  showNotifications: PropTypes.bool,
  setShowNotifications: PropTypes.func,
  newNotificationsAmount: PropTypes.number,
  setNewNotificationsAmount: PropTypes.func,
  onDeleteNotificationItem: PropTypes.func.isRequired,
  updateNotificationItem: PropTypes.func.isRequired,
  handleMarkAllAsRead: PropTypes.func
};

export default Notifications;
