import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 3.5rem;
  
    div {
        margin-right: 1.2rem;
    }

`;

export const Separator = styled.hr`
    border: 0;
    border-top: 1px solid rgb(192, 196, 215);
    margin-top: 3.5rem;
    margin-bottom: 3.0rem;
`;