import styled from 'styled-components';

export const LatestPostsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LatestPostsList = styled.ul`
  display: block;
  flex: 1;
  & > li {
    margin: 0;
    padding: 0;
    border-top: solid 1px #e2e4ec;
    width: 100%;
    display: flex;

    & > div {
      flex: 1;
    }
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 0.8rem;

  @media (max-width: 600px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
  }
`;


export const SummarySkeleton = styled.p`
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.6rem;
`;

export const WrapperSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const LatestPostsListSkeleton = styled.div`
    display: flex;
    justify-content: relative;
    position: relative ;
`;

export const Image = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const TitleSkeleton = styled.div`
  position: absolute ;
  width: 100%;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
`;

export const ListSkeleton = styled.ul`
  display: block;
  flex: 1;
  & > li {
    margin: 0;
    padding: 0;
    border-top: solid 1px #e2e4ec;
    width: 100%;
    display: flex;

    & > div {
      flex: 1;
    }
  }
`;
