import styled from 'styled-components';

export const Float = styled.div`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  display: flex;
  transition: opacity 0.3s linear;
  justify-content: ${({ isFloating }) => (isFloating ? 'flex-end' : 'center')};
  position: ${({ isFloating }) => (isFloating ? 'fixed' : 'relative')};
  width: 100%;
  max-width: inherit;
  margin: 0 auto;
  z-index: 3;
  bottom: 60px;
  margin-left: ${({ positionX }) => `${positionX}px`};

  @media screen and (max-width: 600px) {
    margin: 0 auto;
    max-width: 90%;
  }
`;

export const TopButton = styled.button`
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  margin: 0.8rem;

  @media screen and (max-width: 600px) {
    margin: 0;
    width: 4rem;
    height: 4rem;
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  line-height: 1.9rem;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;