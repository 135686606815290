import styled from 'styled-components';

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const Switch = styled.div`
  position: relative;
  width: 27px;
  height: 16px;
  border-radius: 8px;
  transition: 200ms all;
  cursor: pointer;

  &:before {
    content: '';
    background: white;
    transition: 200ms all;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 35px;
    top: 50%;
    transform: translate(5%, -50%);
  }
`;

export const Text = styled.p`
  cursor: pointer;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:not(.checked) {
    + ${Switch} {
      background: ${({ theme }) => theme.colors.gray70};

      + ${Text} {
        color: ${({ theme }) => theme.colors.gray70};
      }
    }

    &:not(:disabled) {
      &:hover {
        + ${Switch} {
          background: ${({ theme }) => theme.colors.secondary};

          + ${Text} {
            color: ${({ theme }) => theme.colors.secondary};
          }
        }
      }
    }
  }

  &:checked {
    + ${Switch} {
      background: ${({ theme }) => theme.colors.primary};

      + ${Text} {
        color: ${({ theme }) => theme.colors.primary};
      }

      &:before {
        transform: translate(88%, -50%);
      }
    }

    &:not(:disabled) {
      &:hover {
        + ${Switch} {
          background: ${({ theme }) => theme.colors.primaryDark};

          + ${Text} {
            color: ${({ theme }) => theme.colors.primaryDark};
          }
        }
      }
    }
  }

  &:disabled {
    + ${Switch} {
      opacity: 0.2;
      cursor: default;

      + ${Text} {
        opacity: 0.2;
        cursor: default;
      }
    }
  }
`;
