import {
  SET_PROFILE_USER,
  SET_PROFILE_IS_LOOGED_USER,
  ADD_POSTS_ON_PROFILE_POSTS_LIST,
  CLEAN_ALL_PROFILE_INFO,
  SET_PROFILE_HAS_MORE_POSTS,
  FORCE_PROFILE_UPDATE,
  CLEAN_USER_POSTS_LIST,
  REMOVE_POST_IN_USER_POSTS_LIST
} from '../actionTypes';

const initialState = {
  user: {},
  isLoggedUser: false,
  postsList: [],
  hasMorePosts: true,
  forceProfileUpdate: false
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE_USER:
      return {
        ...state,
        user: action.payload
      };
    case SET_PROFILE_IS_LOOGED_USER:
      return {
        ...state,
        isLoggedUser: action.payload
      };
    case ADD_POSTS_ON_PROFILE_POSTS_LIST:
      return {
        ...state,
        postsList: [...state.postsList, ...action.payload]
      };
    case REMOVE_POST_IN_USER_POSTS_LIST: {
      const updatedPostList = [...state.postsList].filter((post) => {
        return post.id != action.payload;
      });
      return {
        ...state,
        postsList: updatedPostList
      };
    }
    case CLEAN_ALL_PROFILE_INFO:
      return initialState;
    case SET_PROFILE_HAS_MORE_POSTS:
      return {
        ...state,
        hasMorePosts: action.payload
      };
    case FORCE_PROFILE_UPDATE:
      return {
        ...state,
        forceProfileUpdate: action.payload
      };
    case CLEAN_USER_POSTS_LIST:
      return {
        ...state,
        postsList: []
      }
    default:
      return state;
  }
};
