import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconComment } from '../Icons/index';

const CommentsCount = ({ commentsAmount }) => {
  return (
    <S.CommentsCountWrapper tabIndex={0}>
      <IconComment size="m" color="#616364" disabledHover={true} />
      <span
        aria-label={`${commentsAmount} ${
          commentsAmount === 1 ? 'comment' : 'comments'
        }`}
      >
        {commentsAmount}
      </span>
    </S.CommentsCountWrapper>
  );
};

CommentsCount.propTypes = {
  commentsAmount: PropTypes.number
};

CommentsCount.defaultProps = {
  commentsAmount: 0
};

export default memo(CommentsCount);
