import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getPostsByTagName } from '../../../services/posts/service';
import { buildPostList } from '../../../../services/posts/service';
import BackLink from '../../BackLink';
import LoadingDots from '../../LoadingDots';
import BackTop from '../../BackTop';
import MessageScroll from '../../MessageScroll';
import withPreventScrollFreezing from '../../../pages/withPreventScrollFreezing';
import * as S from './styles';
import PostSummary from '../../../components/PostSummary';

const NewShowTag = () => {
  const [tagPosts, setTagPosts] = useState([]);
  const limit = 6;

  const [hasMorePosts, setHasMorePosts] = useState(true);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tagName = queryParams.get('name');

  const backToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    backToTop();
    updateTagScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTagScroll = () => {
    const start = tagPosts.length;
    getPostsByTagName(tagName, start, limit).then((postList) => {
      const posts = buildPostList(postList);
      postList.length < limit ? setHasMorePosts(false) : setHasMorePosts(true);

      setTagPosts([...tagPosts, ...posts]);
    });
  };

  return (
    <S.Page>
      <BackLink />
      <S.TagName>Tag</S.TagName>
      <S.PageTitle>{tagName}</S.PageTitle>
      <S.ListTitle>
        <h5>Posts</h5>
      </S.ListTitle>
      <S.Wrapper>
        <InfiniteScroll
          dataLength={tagPosts.length}
          next={updateTagScroll}
          hasMore={hasMorePosts}
          loader={
            tagPosts.length < limit ? null : <LoadingDots showLoading={true} />
          }
          endMessage={<MessageScroll message={'Yay! You have seen it all'} />}
        >
          <S.TagList>
            {tagPosts &&
              tagPosts.map((post, index) => (
                <li key={post.id}>
                  <PostSummary
                    post={post}
                    variant="home"
                    position={index}
                    origin="Tags"
                  />
                </li>
              ))}
          </S.TagList>
        </InfiniteScroll>
      </S.Wrapper>
      <BackTop isFloating={true} positionX={0} scrollPageFactor={0.5} />
    </S.Page>
  );
};

export default withPreventScrollFreezing(NewShowTag);
