import React from 'react';
import * as S from './styles';

// import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AuthorSkeleton from '../Author/skeleton';

const topContributorsList = [0, 1, 2, 3, 4];

const TopContributorsSkeleton = () => {
  return (
    <S.TopContributorsList>
      {topContributorsList.map((author, index) => (
        <li data-testid="author" key={index}>
          <S.SkeletonElement>
            <AuthorSkeleton />
          </S.SkeletonElement>
        </li>
      ))}
    </S.TopContributorsList>
  );
};

export default TopContributorsSkeleton;
