import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuButton = styled.button`
  border: 1px solid red;
  background-color: transparent;
  border:none;
  display:flex;
  align-items:center;

  svg{
    margin-left: 4px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const LoggedUserNameForMobile = styled.h5`
  color: ${({theme}) => theme.colors.white};
  font-weight: 700;
  line-height: 3rem;
  margin-left: 1.2rem;

  @media (min-width: 768px){
    display: none;
  }
`;

export const Menu = styled.ul`
  width: 153px;
  margin: 12px 0;
`;

export const MenuLink = styled(Link)`
  font-size: 14px;
  padding: 5px 24px;
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  &:hover {
    background-color: ${({ theme }) => theme.colors.wave90};
  }
`;
