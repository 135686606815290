import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconNotification } from '../Icons';

const AnchorBell = ({ showBadge }) => {
  return (
    <S.AnchorBell tabIndex={-1} aria-label="notifications bell">
      {showBadge && <S.Badge aria-label="has new notifications" />}
      <IconNotification size={'l'} viewBox={'0 0 22 22'} />
    </S.AnchorBell>
  );
};

AnchorBell.propTypes = {
  showBadge: PropTypes.bool
};

export default AnchorBell;
