import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import PostSummary from '../../components/PostSummary';

const LatestPosts = ({ postList }) => {
  return (
    <S.LatestPostsWrapper>
      <S.Title>Latest Posts</S.Title>
      <S.LatestPostsList id="postList">
        {postList &&
          postList.map((post,index) => (
            <li key={post.id}>
              <PostSummary
                post={post}
                variant="home"
                position={index}
                origin="LatestPosts"
              />
            </li>
          ))}
      </S.LatestPostsList>
    </S.LatestPostsWrapper>
  );
};

LatestPosts.propTypes = {
  postList: PropTypes.array
};

export default LatestPosts;
