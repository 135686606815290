import React from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MostUsedTagsSkeleton = () => {
  const tagsList = [0, 1, 2, 3, 4];

  return (
    <S.MostUsedTagsList>
      {tagsList.map((tag, index) => (
        <li data-testid="tag" key={index}>
          <S.Content>
              <Skeleton width={100} />
              <Skeleton width={100} />
          </S.Content>
        </li>
      ))}
    </S.MostUsedTagsList>
  );
};

export default MostUsedTagsSkeleton;
