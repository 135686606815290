import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import HeaderClean from '../../../components/HeaderClean';
import PostPreview from '../Preview';
import EditorBody from './Body';

const Editor = ({ headerProps, bodyProps }) => {
  const [mode] = useState('editMode');

  useEffect(() => {
    const editorVersion = bodyProps.post.post_editor_version;
    if (mode === 'editMode' && editorVersion === 'v1') {
      bodyProps.handleAlertOldEditor();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderClean
        handleCancelAction={headerProps.handleCancelAction}
        handleSubmitAction={headerProps.handleSubmitAction}
        submitButtonLabel={headerProps.submitButtonLabel}
        submitButtonDisabled={headerProps.submitDisabled}
      />

      {mode === 'editMode' ? (
        <EditorBody
          isCoverEnabled={bodyProps.isCoverEnabled}
          setIsCoverEnabled={bodyProps.setIsCoverEnabled}
          featuredImage={bodyProps.featuredImage}
          post={bodyProps.post}
          showCK={bodyProps.showCK}
          duplicatedTitleError={bodyProps.duplicatedTitleError}
          setTags={bodyProps.setTags}
          getTagsByName={bodyProps.getTagsByName}
          handleChangeFeaturedImage={bodyProps.handleChangeFeaturedImage}
          handleChangeTitle={bodyProps.handleChangeTitle}
          handleChangeContent={bodyProps.handleChangeContent}
          handleUploadImage={bodyProps.handleUploadImage}
          handleTagsAutocomplete={bodyProps.handleTagsAutocomplete}
        />
      ) : (
        <PostPreview post={bodyProps.post} />
      )}
    </>
  );
};

Editor.propTypes = {
  headerProps: PropTypes.shape({
    submitButtonLabel: PropTypes.string,
    submitDisabled: PropTypes.bool,
    handleSubmitAction: PropTypes.func,
    handleCancelAction: PropTypes.func
  }),
  bodyProps: PropTypes.shape({
    handleChangeTitle: PropTypes.func,
    handleChangeContent: PropTypes.func,
    handleChangeFeaturedImage: PropTypes.func,
    handleAlertOldEditor: PropTypes.func,
    post: PropTypes.object,
    showCK: PropTypes.bool,
    duplicatedTitleError: PropTypes.bool,
    isCoverEnabled: PropTypes.bool,
    setIsCoverEnabled: PropTypes.func,
    featuredImage: PropTypes.object,
    setTags: PropTypes.func,
    handleUploadImage: PropTypes.func,
    handleTagsAutocomplete: PropTypes.func
  })
};

export default Editor;
