import styled, { css } from 'styled-components';

export const RadioWrapper = styled.div`
  display: inline-block;
`;

export const StyledRadioButton = styled.span`
  display: inline-block;
  position: relative;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.primary};
  ${({ activateHover, checked }) =>
    activateHover && !checked
      ? css`
          border-color: ${({ theme }) => theme.colors.black};
          &:hover {
            border-color: ${({ theme }) => theme.colors.primaryHover};
          }
        `
      : ``};
  width: 14px;
  height: 14px;
  left: 0;
  border-radius: 50%;
  vertical-align: middle;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

export const HiddenInput = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${StyledRadioButton} {
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      left: 12%;
      top: 12%;
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  ${(props) =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `}

  > div {
    font-size: 14px;
    margin-left: 8px;
  }
`;
