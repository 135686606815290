import React, { useEffect, useState } from 'react';
import { editBiography } from './service';
import styled from 'styled-components';
import styles from './style.module.css';
import ButtonsWrap from './../buttonsWrapper';
import NotFound from '../notFound';
import { useHistory } from 'react-router-dom';
import { ModalAction } from '../../2.0/components/Modals';
import { useSelector } from 'react-redux';

const EditProfile = () => {
  const [wordCount, setWordCount] = useState(0);
  const [biography, setBiography] = useState('');
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const history = useHistory();

  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [isChange, setIsChanged] = useState(false);

  useEffect(() => {
    setBiography(loggedUser.biography);

    const biographyLength = loggedUser.biography
      ? loggedUser.biography.length
      : 0;
    setWordCount(biographyLength);
  }, [loggedUser]);

  const backToProfileWithCheck = () => {
    setIsChanged(false);
    if (isChange) {
      openModalEditProfile(true);
      setIsChanged(false);
    } else {
      setIsChanged(false);
      backToProfile();
    }
  };

  const backToProfile = () => {
    setIsChanged(false);
    const path = `/${loggedUser.username}`;
    history.push(path);
  };

  const handleChange = (event) => {
    setIsChanged(true);
    setBiography(event.target.value);
    setWordCount(event.target.value.length);
  };

  const openModalEditProfile = () => {
    setShowEditProfileModal(true);
  };

  const closeEditProfileModal = () => {
    backToProfileWithCheck();
  };

  const modalConfig = {
    message: 'Are you sure you want to discard all changes?',
    cancelButton: 'Yes, discard',
    submitButton: 'Not now'
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      biography: biography
    };

    editBiography(loggedUser.id, body).then((response) => {
      setIsChanged(false);

      loggedUser.biography = body.biography;
      sessionStorage.setItem('loggedUser', JSON.stringify(response));

      backToProfile();
    });
  };

  const isUserInvalid = () => {
    const url = history.location.pathname.split('/');
    const userId = parseInt(url[2]);
    return loggedUser.id && loggedUser.id !== userId;
  };

  return isUserInvalid() ? (
    <NotFound />
  ) : (
    <Page>
      <main className={styles.editProfileContainer}>
        <form onSubmit={handleSubmit}>
          <h1 className={styles.editProfileTitle}>{loggedUser.name}</h1>
          <textarea
            value={biography}
            maxLength="240"
            className={styles.editProfileBio}
            placeholder="Start typing your bio here"
            onChange={handleChange}
          />
          <div className={styles.wordCounter}>{wordCount}/240</div>
          <ButtonsWrap
            style={styles.editProfileButtons}
            cancelName="Cancel"
            submitName="Save"
            handleCancelAction={backToProfileWithCheck}
          />
        </form>
      </main>
      <ModalAction
        data-testid={'New'}
        showModal={showEditProfileModal}
        handleConfirmation={closeEditProfileModal}
        handleCancel={handleSubmit}
        message={modalConfig.message}
        cancelButton={modalConfig.submitButton}
        submitButton={modalConfig.cancelButton}
      />
    </Page>
  );
};

export default EditProfile;

const Page = styled.main`
  max-width: 63.3rem;
  margin: 32px auto 60px;

  @media screen and (max-width: 633px) {
    margin: 0 16px 60px;
  }
`;
