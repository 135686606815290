import styled from 'styled-components';

const headerListHeightDesktop = '44px';
const headerListHeightMobile = '64px';
const marginListLikes = '1.5%';

export const Modal = styled.div`
  z-index: 4;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: 600px) {
    background-color: transparent;
    scroll-behavior: smooth;
  }
`;

export const ModalContent = styled.div`
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 356px;
  max-height: 657px;

  background-color: #fff;
  border-radius: 4px;

  @media (max-width: 600px) {
    min-width: 100vw;
    max-height: 100%;
    height: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  height: ${headerListHeightDesktop};
  justify-content: space-between;
  align-content: center;
  border-bottom: solid 1px #e1e2e3;

  @media (max-width: 600px) {
    height: ${headerListHeightMobile};
    display: flex;
    justify-content: flex-start;
  }
`;

export const Close = styled.button`
  margin: auto 1.6rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Back = styled.button`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    margin: auto 1.6rem;
    margin-right: 0;
  }
`;

export const Title = styled.h6`
  margin: auto 1.6rem;
  display: block;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: 600px) {
    margin-right: 1rem;
  }
`;

export const List = styled.ul`
  overflow-y: auto;

  max-height: 613px;
  margin-bottom: ${marginListLikes};

  &::-webkit-scrollbar-track {
    margin-top: 1.2rem;
  }

  > li {
    padding-left: 1.6rem;
    padding-top: 1.2rem;
  }

  > li:first-child {
    padding-top: 2rem;
  }

  > li:last-child > hr {
    border: none;
  }

  &::-webkit-scrollbar {
    width: 12.5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e1e2e3;
    border: 4px solid #fff;
    margin: 1.2rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #003d4f;
    border-radius: 5px;
    border-left: 4px solid #fff;
    border-right: 4px solid #fff;
  }

  @media (max-width: 600px) {
    max-height: calc(100% - ${headerListHeightMobile} - ${marginListLikes});
  }
`;

export const Line = styled.hr`
  margin-left: 4.5rem;
  margin-right: 0.3rem;
  margin-top: 1rem;
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.colors.gray30};
`;
