import styled from 'styled-components';

export const FeaturedListWrapper = styled.ul`
  width: 100%;
  height:100%;
  display: flex;
  flex-direction:column;
  justify-content: space-between;

  /* Mobile */
  @media (max-width: 900px) {
    & > li {
      + li {
        margin-top: 2.4rem;
      }
    }
  }
`;
