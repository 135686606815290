import styled from 'styled-components';

export const BackLinkStyled = styled.button`
  display: flex;
  align-items: center;

  &:hover {
    font-weight: 700;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${({theme}) => theme.colors.primaryHover};
  margin-left: 7.36px;
`;

export const BackArrow = styled.svg`
  & > path {
    fill: ${({theme}) => theme.colors.primaryHover};
  }
`;
