import { useRef, useEffect } from 'react';

const useClickOutside = (handler) => {
  const domNodeRef = useRef();

  useEffect(() => {
    const clickOutsideHandler = (event) => {

      if (domNodeRef.current !== undefined) {
        if (!domNodeRef.current.contains(event.target)) {
          handler();
        }
      } 
    };

    document.addEventListener('mousedown', clickOutsideHandler);

    return () => document.removeEventListener('mousedown', clickOutsideHandler);
  });

  return domNodeRef;
};

export default useClickOutside;
