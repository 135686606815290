import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({backGroundColor})=> backGroundColor};
  height: 100%;
  width: 100%;
  display: grid;
  justify-items: start;
  grid-template-areas:
    "icon header"
    "icon main";
  grid-template-columns: 0.15fr 1fr;
`;

export const Icon = styled.span`
  grid-area: icon;
`;

export const Title = styled.header`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  align-elements: left;
  padding-bottom: 8px;
  grid-area: header;
`;

export const Message = styled.main`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  grid-area: main;
`;
