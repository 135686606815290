import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FeaturedPostSection = styled.section`
  width: 100%;
  min-height: 50.8rem;
  position: relative;

  @media (max-width: 900px) {
    min-height: auto;
  }
`;

export const FeaturedPostImage = styled.div`
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    margin-bottom: 0.8rem;
  }

  span {
    width: 100%;
    height: 30.2rem;
    border-radius: 0.8rem;
    display: flex;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-end;

    /* Mobile */
    @media (max-width: 600px) {
      width: 100%;
      height: 13.7rem;
    }
  }
`;

export const SkeletonTitle = styled.a`
  margin-bottom: 1.2rem;
  line-height: 3rem;
  display: block;

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
`;

export const Title = styled(Link)`
  margin-bottom: 1.2rem;
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  word-wrap: break-word;

  :hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 700;
    }
  }
`;

export const Summary = styled.p`
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.6rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
