import styled from 'styled-components';

export const Container = styled.main`
  border-bottom: 1px solid #e1e2e3;
  padding: 0 0 15px 20px;
  display: none;
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
  }
`;

export const BackLinkStyled = styled.button`
  display: flex;
  align-items: center;
  &:hover {
    font-weight: 700;
  }
`;

export const Text = styled.h5`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: 1.4rem;
`;

export const BackArrow = styled.svg`
  & > path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;
