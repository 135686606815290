import styled from 'styled-components';

export const SliderControlWraper = styled.div`
  box-sizing: border-box;
  margin-top: 26px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 65px;
`;

export const ZoomOut = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 14px;
`;

export const ZoomIn = styled.img`
  width: 17px;
  height: 17px;
  margin-left: 14px;
`;

export const RailOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 17px;
  transform: translate(0%, -50%);
  cursor: pointer;
`;

export const RailInner = styled.div`
  position: absolute;
  width: 100%;
  height: 3px;
  transform: translate(0%, -50%);
  border-radius: 4px;
  pointer-events: none;
  background-color: #bdbec0;
`;

export const Handle = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  left: ${(props) => props.percent}%;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: 'none';
`;

export const HandleButton = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  left: ${(props) => props.percent}%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  background: ${(props) => (props.disabled ? '#BDBEC0' : '#fff')};
  border: 1px solid #bdbec0;
`;

export const Track = styled.div`
    position: absolute;
    transform: translate(0%, -50%);
    height: 8;
    z-index: 1;
    border-radius: 4;
    cursor: pointer;
    left: ${(props) => props.source.percent}%;
    width: ${(props) => props.target.percent - props.source.percent}%;
`;

export const WrapperHandle = styled.div`
`;
