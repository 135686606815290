import React, { useEffect, useState } from 'react';
import { getMostUsedTags } from '../../services/tags/service';
import MostUsedTags from '../../components/MostUsedTags';
import * as S from './styles';

import MostUsedTagsSkeleton from '../../components/MostUsedTags/skeleton';

const MostUsedTagsContainer = () => {
  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    getMostUsedTags(5).then((response) => {
      setTagsList(response);
    });
  }, []);

  // return <MostUsedTags tagsList={tagsList} />;

  return (
    <S.Wrapper>
      <h2>Tags more used</h2>

      {tagsList.length >= 1 ? (
        <MostUsedTags tagsList={tagsList} />
      ) : (
        <MostUsedTagsSkeleton />
      )}
    </S.Wrapper>
  );
};

export default MostUsedTagsContainer;
