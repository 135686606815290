import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 3.6rem;
  width: 100%;
  height:auto;
  background: #f4f5f8;
  padding: 3.2rem;

  @media screen and (max-width: 900px) {
    margin-top:0;
  }

  & > hr {
    width: 100%;
    margin-top: 0.8rem;
    border: none;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.gray30};
  }
  & > h3 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: 600px) {
    & > h3 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;