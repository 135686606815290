import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Logout = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const logout = async () => {
    // Redirect to '/' after logout
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + '/'
    });
  };

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return <button onClick={logout}>Logout</button>;
};

export default Logout;
