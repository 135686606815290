import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  margin-top: 3.6rem;
  width: 100%;
  height: auto;
  background: #f4f5f8;
  padding: 3.2rem;

  @media screen and (max-width: 900px) {
    margin-top: 0;
  }

  & > hr {
    width: 100%;
    margin-top: 0.8rem;
    border: none;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.gray30};
  }

  & > h3 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: 600px) {
    & > h3 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

export const MostPopularList = styled.ul`
  margin-top: 2.4rem;

  & > li {
    display: flex;
    + li {
      margin-top: 3.2rem;
    }

    & > span {
      font-weight: 600;
      font-size: 2.8rem;
      line-height: 3.2rem;
      color: ${({ theme }) => theme.colors.secondaryLight};
      margin-right: 2.4rem;

      @media screen and (max-width: 600px) {
        font-size: 2.4rem;
        line-height: 3.6rem;
      }
    }
    & > div {
      & > p {
        font-style: italic;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.45rem;

        & > span {
          color: ${({ theme }) => theme.colors.gray70};
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.45rem;
          font-style: normal;
        }

        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
  }
`;

export const Author = styled(Link)`
  color: ${({ theme }) => theme.colors.gray70};
  font-weight: 700;
  font-size: 1.2rem;
  font-style: normal;
  &:hover {
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export const Title = styled(Link)`
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media screen and (max-width: 600px) {
    h6 {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  }
`;

export const SkeletonAuthor = styled.a`
  font-weight: 700;
  font-size: 1.2rem;
  font-style: normal;
`;

export const SkeletonTitle = styled.a`
  @media screen and (max-width: 600px) {
    h6 {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  }
`;


export const MostPopularListSkeleton = styled.ul`

  margin-top: 2.4rem;

  & > li {
    width: auto;
    + li {
      margin-top: 3.2rem;
      width: auto;
    }

    & > span {
      display: inline-block;
      font-weight: 600;
      font-size: 2.8rem;
      line-height: 3.2rem;
      color: ${({ theme }) => theme.colors.secondaryLight};
      margin-right: 2.4rem;

      @media screen and (max-width: 600px) {
        font-size: 2.4rem;
        line-height: 3.6rem;
        margin-right: 2.4rem;
      }
    }
  }
`;


export const Container = styled.span`

  width:75% ;

  @media screen and (max-width: 600px) {
    width:65% ;
  }

  & > p {
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.45rem;

    & > span {
      color: ${({ theme }) => theme.colors.gray70};
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.45rem;
      font-style: normal;
    }

    @media screen and (max-width: 600px) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
`;
