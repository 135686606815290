import React from 'react';
import BackLinkMobile from '../../components/BackLinkMobile';
import BackLink from '../../components/BackLink';

import NotificationSettingsContainer from '../../containers/NotificationSettingsContainer';
import * as S from './styles';

const NotificationSettings = () => {
  return (
    <S.BackGround>
      <S.Container>
        <S.BackLinkWrapper>
          <BackLink />
        </S.BackLinkWrapper>
        <BackLinkMobile text="Notification Settings"/>
        <NotificationSettingsContainer />
      </S.Container>
    </S.BackGround>
  );
};

export default NotificationSettings;
