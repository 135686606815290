import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconPath } from '../Icons';
import theme from '../../styles/theme';

const MostUsedTags = ({ tagsList }) => {
  const charLimit = 30;

  return (
    <S.MostUsedTagsList>
      {tagsList.map((tag, index) => (
        <li data-testid="tag" key={index}>
          <S.Content>
            <S.Title to={`/tag?name=${tag.tag_name}`}>
              {tag.tag_name.length <= 30
                ? tag.tag_name
                : `${tag.tag_name.substring(0, charLimit)}...`}
            </S.Title>
            <S.AllPosts to={`/tag?name=${tag.tag_name}`}>All Posts</S.AllPosts>
            <S.Icon>
              <IconPath size="s" color={theme.colors.primaryHover} />
            </S.Icon>
          </S.Content>
        </li>
      ))}
    </S.MostUsedTagsList>
  );
};

MostUsedTags.propTypes = {
  tagsList: PropTypes.array
};

export default MostUsedTags;
