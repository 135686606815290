import styled from 'styled-components';

export const Page = styled.main`
  max-width: 110.8rem;
  margin: 0 auto 60px;

  @media screen and (max-width: 1108px) {
    margin: 0 16px 60px;
  }
`;

export const Instructions = styled.div`
  font-size: 1.8rem;

  & > h2 {
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 1.8rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 120px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 5.6rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  line-height: 6.7rem;
  word-wrap: break-word;
`;

export const Subtitle = styled.p`
  font-size: 1.6rem;
  margin-bottom: 4rem;
  max-width: 53.9rem;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primaryDark};
  text-decoration: none;
  text-decoration: underline;

  :hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  @media screen and (max-width: 600px) {
    :hover {
      color: ${({ theme }) => theme.colors.primaryDark};
      opacity: 1;
      box-shadow: none;
    }
  }
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  position: relative;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;
