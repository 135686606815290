import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import LoadingDots from '../../../LoadingDots';

const SuggestionList = ({
  suggestions,
  addTag,
  showSuggestions,
  loadingSuggestions
}) => {
  const handleOnClick = (tagName) => {
    addTag(tagName);
  };

  return (
    <S.StyledTooltip visible={showSuggestions}>
      <S.Pointer />
      <S.Content>
        {loadingSuggestions ? (
          <LoadingDots showLoading={loadingSuggestions} />
        ) : (
          suggestions.length > 0 && (
            <S.SuggestionList>
              <ul>
                {suggestions.map((s, index) => (
                  <S.SuggestionName key={index}>
                    <button tabIndex={0} onClick={() => handleOnClick(s.name)}>
                      {s.name}
                    </button>
                  </S.SuggestionName>
                ))}
              </ul>
            </S.SuggestionList>
          )
        )}
        <S.Message tabIndex={0}>
          Tap comma or enter at the end of the word to add a new tag
        </S.Message>
      </S.Content>
    </S.StyledTooltip>
  );
};

SuggestionList.propTypes = {
  suggestions: PropTypes.array,
  addTag: PropTypes.func,
  loadingSuggestions: PropTypes.bool,
  showSuggestions: PropTypes.bool
};

SuggestionList.defaultProps = {
  suggestions: []
};

export default SuggestionList;
