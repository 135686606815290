import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Avatar from '../../components/Avatar';
import useEventTracker from '../../hooks/useEventTracker';

const AuthorBiography = ({ author, isBiography, showTotalPosts }) => {
  author = { ...author, ...author.author };

  const sendEvent = useEventTracker();

  return (
    <>
      <S.AuthorInfo>
        {<Avatar fullName={author.name} size={'m'} email={author.email} />}
        <span>
          {
            <S.AuthorName
              onClick={() =>
                sendEvent(
                  'Profile',
                  `Click on ${author.username} - From ${window.location.pathname}`,
                  'AuthorInfor'
                )
              }
              href={`/${author.username}`}
            >
              {author.name}
            </S.AuthorName>
          }
          {showTotalPosts ? (
            <S.TotalArticles>{author.total} articles</S.TotalArticles>
          ) : null}
        </span>
      </S.AuthorInfo>
      {isBiography ? (
        <>
          <S.Bio> {author.biography}</S.Bio>
        </>
      ) : null}
    </>
  );
};

AuthorBiography.propTypes = {
  isBiography: PropTypes.bool,
  showTotalPosts: PropTypes.bool,
  author: PropTypes.shape({
    total: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    biography: PropTypes.string
  })
};

AuthorBiography.defaultProps = {
  showTotalPosts: true
};

export default AuthorBiography;
