import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import * as S from './imageCrop.styles';
import SliderControl from '../../../SliderControl';

const ImageCrop = ({ featuredImageSrc, cropArea, setCropArea }) => {
  const MIN_IMAGE_WIDTH = 728;
  const MIN_IMAGE_HEIGHT = 368;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);

  // eslint-disable-next-line no-unused-vars
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(cropArea);

  const maxZoom = getMaxZoomFromB64Image(
    featuredImageSrc,
    MIN_IMAGE_WIDTH,
    MIN_IMAGE_HEIGHT
  );

  function getMaxZoomFromB64Image(b64Image, maxWidth, maxHeight) {
    if (b64Image) {
      const img = new Image();
      img.src = b64Image;
      return Number.parseInt(
        Math.min(Math.round(img.width / maxWidth), Math.round(img.height / maxHeight))
      );
    } else {
      return 1;
    }
  }

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      if (
        !croppedAreaPixels ||
        croppedAreaPixels.width < MIN_IMAGE_WIDTH ||
        croppedAreaPixels.height < MIN_IMAGE_HEIGHT
      ) {
        return;
      } else {
        setCroppedAreaPixels(croppedAreaPixels);
        setCropArea(croppedAreaPixels);
      }
    },
    [setCropArea]
  );

  return (
    <S.ImageCropWrapper>
      <S.CropContainer>
        <Cropper
          image={featuredImageSrc}
          crop={crop}
          zoom={zoom/100}
          minZoom={1}
          maxZoom={maxZoom}
          zoomWithScroll={false}
          aspect={728/368}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </S.CropContainer>
      <div className="controls">
        <SliderControl zoom={zoom} setZoom={setZoom} maxZoom={maxZoom} />
      </div>
    </S.ImageCropWrapper>
  );
};

ImageCrop.propTypes = {
  featuredImageSrc: PropTypes.string,
  cropArea: PropTypes.object,
  setCropArea: PropTypes.func
};

export default ImageCrop;
