import styled from 'styled-components';

export const Page = styled.main`
  margin-top: 120px;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
`;

export const GridLayout = styled.div`
  max-width: 110.8rem;
  display: grid;
  grid-template-areas:
    'toolbar asideContent'
    'postArticle asideContent';
  grid-template-rows: auto auto;
  grid-template-columns: 8.25fr 3.75fr;
  grid-row-gap: 0;
  grid-column-gap: 0;

  @media screen and (max-width: 900px) {
    grid-template-areas:
      'asideContent'
      'toolbar'
      'postArticle';
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    grid-row-gap: 3rem;
    overflow-y: scroll;
    padding: 1rem;
  }
`;

export const Toolbar = styled.section`
  grid-area: toolbar;
`;

export const PostArticle = styled.article`
  grid-area: postArticle;
  height: calc(83vh);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const AsideContent = styled.aside`
  grid-area: asideContent;
  padding-left: 3rem;
  border-left: 1px solid #e1e2e3;
  z-index: 2;

  @media screen and (max-width: 900px) {
    padding-left: 0;
    border: none;
  }
`;

export const ImageRequirements = styled.div`
  margin-bottom: 4.2rem;
`;

export const InputTitle = styled.textarea`
  font-family: Bitter, serif;
  font-size: 3.2rem;
  outline: none;
  border: none;
  resize: none;
  overflow: hidden;
  word-break: break-word;
  height: 4.2rem;
  width: 100%;
  line-height: 4.8rem;
  font-weight: 700;
  margin-top: 3.2rem;
`;

export const DuplicatedTitleError = styled.span`
  height: 2.1rem;
  width: fit-content;
  font-size: 1.4rem;
  background-color: ${({ theme }) => theme.colors.primarySoftLight};
  color: ${({ theme }) => theme.colors.primaryDark};
  padding: 0px 0.8rem;
  margin-bottom: 1.2rem;
  display: block;
`;

export const PostCover = styled.img`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  width: 728px;
  height: 368px;
  margin-top: 41px;

  /* Tablet */
  @media (min-width: 601px) and (max-width: 1279px) {
    min-width: 440px;
    width: 55%;
    margin-left: 10%;
  }

  /* Mobile */
  @media (max-width: 600px) {
    width: 288px;
    margin: 0 calc((100vw - 288px) / 2);
  }
`;
