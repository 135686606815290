const MINIMUM_CONTENT_LENGTH = 3;
const MINIMUM_TITLE_LENGTH = 3;

export const isPostValid = (postTitle, postContent, showCK) => {
  const postTitleFormated = postTitle.trim();
  if (!showCK) {
    return postTitleFormated.length >= MINIMUM_TITLE_LENGTH;
  }

  const postContentWithoutHtmlTags =
    removeAnyHtmlTagFromPostContent(postContent).trim();

  return (
    postContentWithoutHtmlTags.length >= MINIMUM_CONTENT_LENGTH &&
    postTitleFormated.length >= MINIMUM_TITLE_LENGTH
  );
};

const removeAnyHtmlTagFromPostContent = (postContent) => {
  const postContentWithoutHtmlTags = postContent
    .replaceAll(/<\/?[^>]+(>|$)/g, '')
    .replaceAll('&nbsp;', '');
  return postContentWithoutHtmlTags;
};
