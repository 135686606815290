import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './imageUpload.styles';
import getCroppedImageB64 from '../../../../utils/images/getCroppedImageB64';

const ImageUpload = (props) => {
  const { featuredImageSrc, setFeaturedImageSrc } = props;
  const [showError, setShowError] = useState(false);
  const MIN_WIDTH = 860;
  const MIN_HEIGHT = 435;

  useEffect(() => {
    if (featuredImageSrc && featuredImageSrc.startsWith('https')) {
      getCroppedImageB64(featuredImageSrc, function (dataUrl) {
        setFeaturedImageSrc(dataUrl);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImage = () => {
    const reader = new FileReader();
    const fileUpload = document.querySelector('#featuredImageUpload[type=file]');

    reader.readAsDataURL(fileUpload.files[0]);
    
    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        if (this.height < MIN_HEIGHT || this.width < MIN_WIDTH) {
          setShowError(true);
          return false;
        }
        setShowError(false);
        setFeaturedImageSrc(image.src);
        return true;
      };
    };
  };

  return (
    <div>
      <S.ImageUpload
        data-testid="image-upload-input"
        label={featuredImageSrc ? 'Change image' : 'Choose an image'}
      >
        <input type="file" onChange={loadImage} id="featuredImageUpload"/>
        <p>Minimum size 860x435 pixels</p>
      </S.ImageUpload>
      <S.ErrorMessage>
        {showError
          ? `You should upload an image with minimum size of ${MIN_WIDTH}x${MIN_HEIGHT} pixels.`
          : ''}
      </S.ErrorMessage>
    </div>
  );
};

ImageUpload.propTypes = {
  featuredImageSrc: PropTypes.string,
  setFeaturedImageSrc: PropTypes.func
};

export default ImageUpload;
