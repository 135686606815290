import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import moment from 'moment';

const Timestamp = ({ date, preText }) => {
  return (
    <div>
      {date && date !== '' &&
        <p data-testid="timestamp" className={styles.commentCreationDate}>
          {`${preText} ${moment.utc(date).fromNow()}`.trim()}
        </p>
      }</div>
  );
};

Timestamp.propTypes = {
  date: PropTypes.string,
  preText: PropTypes.string
};

Timestamp.defaultProps = {
  preText: ''
};

export default Timestamp;
