import {
  COMMENTS_LIST,
  REMOVE_COMMENT,
  ADD_COMMENT,
  UPDATE_COMMENT,
  ADD_REPLY_COMMENT,
  REMOVE_REPLY_COMMENT,
  UPDATE_REPLY_COMMENT

} from '../actionTypes';

const initialState = {
  commentsList: []
};

export const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_LIST:
      return {
        ...state,
        commentsList: action.payload
      };
    case REMOVE_COMMENT: {
      const commentsListUpdated = [...state.commentsList].filter((comment) => {
        return comment.id != action.payload;
      });
      return {
        ...state,
        commentsList: commentsListUpdated
      };
    }
    case ADD_COMMENT:
      return {
        ...state,
        commentsList: [action.payload, ...state.commentsList]
      };
    case UPDATE_COMMENT: {
      const newCommentsList = [...state.commentsList];

      const commentIndex = newCommentsList.findIndex(
        (comment) => comment.id == action.payload.id
      );

      if (commentIndex !== -1) {
        const commentReplies = newCommentsList[commentIndex].replies;
        newCommentsList[commentIndex] = action.payload;
        newCommentsList[commentIndex].replies = commentReplies;
      }

      return {
        ...state,
        commentsList: newCommentsList
      };
    }
    case ADD_REPLY_COMMENT: {
      const newCommentsList = [...state.commentsList];

      const index = newCommentsList.findIndex(
        (comment) => comment.id == action.payload.comment.id
      );

      if (index !== -1) {
        newCommentsList[index].replies.push(action.payload);
      }

      return {
        ...state,
        commentsList: newCommentsList
      };
    }
    case REMOVE_REPLY_COMMENT: {
      const newCommentsList = [...state.commentsList];

      const index = newCommentsList.findIndex(
        (comment) => comment.id == action.payload.comment.id
      );

      if (index !== -1) {
        const newRepliesCommentList = newCommentsList[index].replies.filter((reply) => reply.id !== action.payload.id);
        newCommentsList[index].replies = newRepliesCommentList
      }

      return {
        ...state,
        commentsList: newCommentsList
      };
    }
    case UPDATE_REPLY_COMMENT: {
      const newCommentsList = [...state.commentsList];

      const commentIndex = newCommentsList.findIndex(
        (comment) => comment.id == action.payload.comment.id
      );

      if (commentIndex !== -1) {
        const replyIndex = newCommentsList[commentIndex].replies.findIndex(
          (reply) => reply.id == action.payload.id
        );

        newCommentsList[commentIndex].replies[replyIndex] = action.payload
      }

      return {
        ...state,
        commentsList: newCommentsList
      };
    }
    default:
      return state;
  }
};
