import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;
`;

export const ButtonsDesktop = styled.div`
  display: flex;
  align-items: center;

  & > button + button {
    margin-left: 16px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const ButtonsStyled = styled.button`
  border-radius: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    position: relative;
    background-color: ${({ theme }) => theme.colors.gray20};
  }

  &[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 8px;
    position: absolute;
    left: 0;
    top: 150%;
    white-space: nowrap;
    z-index: 2;
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    height: 14px;
    letter-spacing: 0px;
    width: 33px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const MoreActionsButton = styled.button`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const MobileMenu = styled.ul`
  width: 123px;
  margin: 1.6rem 0;
  padding-left: 2.4rem;

  & > li {
    margin: 0;

    & + li {
      margin-top: 20px;
    }
    & > button {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 1.4rem;
      line-height: 2.1rem;

      & > svg {
        margin-right: 1.1rem;
      }
    }
  }
`;
