import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import * as S from './styles';
import { IconEdit } from '../Icons';
import moreIcon from '../../../images/more-icon.svg';
import ProfilePhotoHelp from './ProfilePhotoHelp';
import Dialog from '../Dialog';
import Button from '../Button';

const ProfileHeader = (props) => {
  const history = useHistory();
  const { user, allowEdit } = props;

  const editProfile = () => {
    history.push(`/profile/${user.id}/edit`);
  };

  const trigger = (
    <S.ShowMoreButton>
      <img src={moreIcon} alt="more actions" />
    </S.ShowMoreButton>
  );

  return (
    <>
      <S.UserInfo>
        <S.WrapperAvatar>
          <Avatar fullName={user.name} size="xl" email={user.email} />
          <ProfilePhotoHelp showIcon={allowEdit} />
        </S.WrapperAvatar>
        <S.Username tabIndex={0}>{user.name}</S.Username>
        {allowEdit ? (
          <>
            <S.Wrapper>
              <Button
                label="Edit Profile"
                size="s"
                variant="outlined"
                onClick={editProfile}
              />
            </S.Wrapper>
            <Dialog
              trigger={trigger}
              boxPosition="right"
              boxDistanceX={41.4}
              boxDistanceY={125}
              connectorPosition="top"
              connectorDistance={12}
            >
              <S.MobileMenu>
                <li>
                  <S.MobileMenuItem onClick={() => editProfile()}>
                    <IconEdit size="m" color="#003d4f" disabledHover={true} />
                    <span>Edit</span>
                  </S.MobileMenuItem>
                </li>
              </S.MobileMenu>
            </Dialog>
          </>
        ) : null}
      </S.UserInfo>
      <S.Biography value={user.biography} />
    </>
  );
};

ProfileHeader.propTypes = {
  user: PropTypes.object,
  allowEdit: PropTypes.bool
};

export default ProfileHeader;
