import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '../../images/close-icon.svg';

const Tag = (props) => {
  const { text, handleClick, handleClose } = props;
  return (
    <TagStyled data-tagname={text} onClick={handleClick}>
      <TagText> {text} </TagText>
      {handleClose instanceof Function ? (
        <CloseButton type="button" data-tagname={text} onClick={handleClose}>
          <img src={CloseIcon} alt="close" />
        </CloseButton>
      ) : null}
    </TagStyled>
  );
};

Tag.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func
};

export default Tag;

const TagStyled = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 2px 4px 2px 8px;
  width: max-content;
  background: #eeeeef;
  display: flex;
  align-items: center;
  margin: 0px 8px 8px 0px;
  border-radius: 2px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;

const TagText = styled.p`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  margin-right: 4px;
  color: ${({theme}) => theme.colors.secondary};
  pointer-events: none;
`;

const CloseButton = styled.button`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    pointer-events: none;
  }
`;
