import React from 'react';
import * as S from './styles';
import Button from '../../components/Button';
import PropTypes from 'prop-types';

const Followers = ({
  isFollowedByLoggedUser,
  isLoggedUser,
  disableActionButton,
  actionOnClick
}) => {
  return (
    !isLoggedUser && (
      <S.Wrapper>
        <S.Follow>
          <Button
            label={isFollowedByLoggedUser ? 'Following' : 'Follow'}
            size="m"
            variant={isFollowedByLoggedUser && 'outlined'}
            onClick={actionOnClick}
            disabled={disableActionButton}
          />
        </S.Follow>
      </S.Wrapper>
    )
  );
};

Followers.propTypes = {
  isFollowedByLoggedUser: PropTypes.bool.isRequired,
  isLoggedUser: PropTypes.bool.isRequired,
  actionOnClick: PropTypes.func.isRequired,
  disableActionButton: PropTypes.bool.isRequired
};

export default Followers;
