import React, { useEffect, useState } from 'react';
import * as S from './styles';
import Button from '../Button';
import { useHistory, useLocation } from 'react-router-dom';
import { IconClose, IconSearch } from '../Icons';

const SearchField = () => {
  const history = useHistory();
  const query = useQuery();

  const [searchTitleValue, setSearchTitle] = useState('');
  const [enableSearch, setEnableSearch] = useState(false);

  const [url] = useState(query.get('title'));

  useEffect(() => {
    if (
      history.location.state !== undefined &&
      !history.location.state.prevPath
    ) {
      setSearchTitle(history.location.state.search);
    }
  }, [history, url]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    setEnableSearch(searchTitleValue.trim().length > 1);
  }, [searchTitleValue]);

  const search = () => {
    history.push({
      pathname: '/search',
      search: `?title=${searchTitleValue}`,
      state: {
        search: searchTitleValue.trim()
      }
    });
  };

  const cleanSearch = () => {
    setSearchTitle('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  return (
    <S.Wrapper>
      <S.InputWrapper>
        <IconSearch size="l" color="#003D4F" disabledHover={true} />
        <S.SearchField
          onKeyDown={enableSearch ? handleKeyDown : undefined}
          value={searchTitleValue}
          onChange={(e) => setSearchTitle(e.target.value)}
          placeholder="Search in the Blog"
          title= "Search in the Blog"
          type={'text'}
        />

        <S.CleanButton
          data-testid="clean-button"
          aria-label="clean text typed in search input"
          onClick={cleanSearch}
          name="clean-button"
          style={{
            visibility: enableSearch === true ? 'visible' : 'hidden'
          }}
        >
          <IconClose size="s" color="#003D4F" disabledHover={true} />
        </S.CleanButton>
      </S.InputWrapper>

      <S.Container>
        <Button
          disabled={!enableSearch}
          label="Search"
          size="m"
          variant="secondary"
          onClick={search}
        />
      </S.Container>
    </S.Wrapper>
  );
};

export default SearchField;
