import React from 'react';
import FeaturedPost from '../../../components/FeaturedPost';
import FeaturedPostSkeleton from '../../../components/FeaturedPost/skeleton';

import { useSelector } from 'react-redux';

import 'react-loading-skeleton/dist/skeleton.css';

const FeaturedPostContainer = () => {
  const featuredPosts = useSelector((state) => state.post.featuredPosts);
  const [firstPost] = featuredPosts;

  if (featuredPosts.length === 0) {
    return <FeaturedPostSkeleton />;
  }

  return (
    <FeaturedPost
      featuredImageUrl={firstPost.featured_image_url}
      featuredImageAlt={firstPost.featured_image_alt}
      postTitle={firstPost.title}
      content={firstPost.content}
      authorName={firstPost.author.name}
      authorEmail={firstPost.author.email}
      username={firstPost.author.username}
      publishedAt={firstPost.published_date}
      likes={firstPost.likes}
      postUrl={`/${firstPost.blog.slug}/${firstPost.slug}`}
    />
  );
};

export default FeaturedPostContainer;
