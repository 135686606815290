import { blogApiNew } from '../../servers/blogApiNew';

const throwIfError = (response) => {
  if (response?.statusCode && response?.statusCode !== 200) {
    throw new Error('Sorry! Please reload the page and try again.');
  }
};

export const getNotificationSettings = async (userEmail) => {
  const data = await blogApiNew.get(
    `/notification-settings?filters[user][email]=${userEmail}&populate=*`
  );

  throwIfError(data);

  return data && data.length > 0 ? data[0] : {};
};

export const editNotificationSettings = async (
  notificationSettingsId,
  newNotificationSettings
) => {
  const url = `/notification-settings`;
  const data = await blogApiNew.edit(
    url,
    notificationSettingsId,
    newNotificationSettings
  );
  throwIfError(data);

  return data;
};
