import { buildHeader } from '../buildHeader';
import {
  handleHttpResponse,
  handleHttpError
} from '../_httpRequestsErrorHandling';

const baseUrl = process.env.REACT_APP_BLOG_BACKEND_URL;

export const upload = async (file) => {
  const headers = await buildHeader(false, '');

  let formdata = new FormData();
  formdata.append('files', file, file.name);
  return fetch(`${baseUrl}/upload`, {
    method: 'POST',
    headers: headers,
    body: formdata,
    redirect: 'follow'
  })
    .then(handleHttpResponse)
    .then((data) => data)
    .catch(handleHttpError);
};
