import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Editor from '../../../components/Post/Editor';
import { publishPost } from '../../../services/posts/service';
import { closeModal, showModal } from '../../../store/modals/actions';
import { forceProfileUpdate } from '../../../store/profile/actions';
import { isPostValid } from '../utils/postValidator';
import {
  showToastify,
  showToastifyInfo,
  showToastifyError
} from '../../../components/ToastifyTemplate';
import { uploadImageB64 } from '../../../services/images/service';
import {
  addTag,
  getTagIdByName,
  getTagsSuggestions
} from '../../../services/tags/service';

import {
  FeedBackCKEditor,
  ActionForConfirmation
} from '../../../components/Survey/FeedBackCKEditor';
import { createBlog, getBlog } from '../../../services/blogs/service';
import { setLoggedUser } from '../../../store/loggedUser/actions';

const CreateContainer = ({ showCK }) => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const [postContent, setPostContent] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [duplicatedTitleError, setDuplicatedTitleError] = useState(false);
  const [tags, setTags] = useState([]);
  const [isCoverEnabled, setIsCoverEnabled] = useState(false);

  const [featuredImage, setFeaturedImage] = useState({
    src: '',
    alt: '',
    croppedImage: '',
    isCoverEnabled: isCoverEnabled
  });

  const postToCreate = {
    published_date: new Date(),
    author: {
      name: loggedUser.name,
      email: loggedUser.email,
      username: loggedUser.username
    },
    title: postTitle,
    content: postContent,
    tags,
    featured_image_url: featuredImage.src,
    featured_image_alt: featuredImage.alt,
    croppedImage: featuredImage.croppedImage,
    cover_enabled: featuredImage.isCoverEnabled,
    editor_version: 'v2'
  };

  const getInitialPostState = () => {
    try {
      const cachedPost = JSON.parse(localStorage.getItem('tw-blog-post-draft'));

      if (cachedPost) {
        const cachePostObject = cachedPost;

        if (!cachePostObject?.post) {
          throw new Error('Invalid cache!');
        }

        setPostContent(cachePostObject.post.content);
        setPostTitle(cachePostObject.post.title);
        setTags(cachePostObject.tags);
      } else {
        return postToCreate;
      }
    } catch (error) {
      localStorage.removeItem('tw-blog-post-draft');
    }
  };

  useEffect(() => {
    getInitialPostState();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isPostValid(postTitle, postContent, showCK)) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [postContent, postTitle]);

  const handleChangeFeaturedImage = (data) => {
    setFeaturedImage(data);
  };

  const handleChangeContent = (data) => {
    setPostContent(data);
  };

  const handleChangeTitle = (data) => {
    validateDuplicatedTitleCase();

    setPostTitle(data);
  };

  const validateDuplicatedTitleCase = () => {
    if (duplicatedTitleError) {
      setDuplicatedTitleError(false);
    }
  };

  const isValidAltImgInPostContent = () => {
    const parser = new DOMParser();
    const document = parser.parseFromString(postContent, 'text/html');
    const imgsInPostContent = document.querySelectorAll('img');
    const listImages = Array.from(imgsInPostContent);

    const result = listImages.filter((element) => !element['alt']);

    return result.length > 0;
  };

  const surveyModal = () => {
    dispatch(
      showModal({
        showModal: true,
        handleConfirmation: () => {
          ActionForConfirmation();
          dispatch(closeModal());
        },
        handleCancel: () => {
          dispatch(closeModal());
        },
        message: FeedBackCKEditor,
        submitButton: 'Go to survey',
        cancelButton: 'Skip',
        type: 'campaign'
      })
    );
  };

  const handlePublishPost = async (blogId) => {
    const post = {
      title: postTitle.trim(),
      content: DOMPurify.sanitize(postContent, { ADD_ATTR: ['rel', 'target'] }),
      published_date: Date.now(),
      blog: blogId,
      author: loggedUser.id,
      editor_version: 'v2',
      featured_image_alt: featuredImage.alt,
      tags
    };

    if (featuredImage?.croppedImage) {
      const response = await uploadImageB64(featuredImage.croppedImage);
      const imageUrl = response.data.link;

      post.featured_image_url = imageUrl;
      post.cover_enabled = featuredImage.isCoverEnabled && !!imageUrl;
    }

    if (tags.length > 0) {
      try {
        const tagList = await generateTagList();
        post.tags = tagList;
      } catch (e) {
        throw new Error(e.errorMessage);
      }
    }

    try {
      const newPost = await publishPost(post);
      localStorage.removeItem('tw-blog-post-draft');
      dispatch(forceProfileUpdate(true));
      backToPost(newPost);

      surveyModal();
    } catch (e) {
      if (e.message === 'duplicatedTitle') {
        setDuplicatedTitleError(true);

        throw new Error('Duplicated title.');
      }

      throw new Error();
    }
  };

  const generateTagList = async () => {
    return Promise.all(
      tags.map(async (tag) => {
        return await getTagIdByName(encodeURIComponent(tag.name)).then(
          async (response) => {
            if (response.length === 0) {
              return await addTag(tag.name).then((tag) => {
                return tag.id;
              });
            } else {
              return response[0].id;
            }
          }
        );
      })
    );
  };

  const createPost = async (blogId) => {
    isValidAltImgInPostContent()
      ? showToastifyInfo({
        title: 'Add an alt text!!',
        message:
          'You need to add an alternative text to your images before publishing.'
      })
      : showToastify(handlePublishPost(blogId), {
        pending: 'Publishing...',
        successTitle: 'Congrats!',
        successMessage: 'Your post was published.',
        errorTitle: 'Something went wrong!',
        errorMessage:
          "Don't worry, your progress was saved in cache. Please try again later."
      });
  };

  const createNewBlog = async () => {
    const newBlog = {
      name: loggedUser.name,
      owner: loggedUser.name,
      owner_id: loggedUser.id.toString()
    };
    const blog = await createBlog(newBlog);

    const updatedLoggedUser = { ...loggedUser, blog };
    dispatch(setLoggedUser(updatedLoggedUser));

    return blog;
  };

  const getUserBlogId = async () => {
    if(!loggedUser.id) {
      throw new Error('There is no loggedUser on this session');
    }
    const blog = await getBlog(loggedUser.id);
    if (!blog) {
      const newBlog = await createNewBlog();
      return newBlog.id;
    }

    return blog.id;
  };

  const createNewPost = async (e) => {
    const response = e.target.value;

    if (response === 'true') {
      const blogId = await getUserBlogId();
      if (blogId) createPost(blogId);
    }

    dispatch(closeModal());
  };

  const backToProfilePage = () => {
    history.push({
      pathname: `/${loggedUser.username}`,
      state: {
        prevPath: history.location,
        loggedUser,
        ...history?.location?.state
      }
    });
  };

  const backToPost = (post) => {
    history.push({
      pathname: `/${post.blog.slug}/${post.slug}`,
      state: { prevPath: history.location, ...history?.location?.state }
    });
  };

  const cancelPost = (e) => {
    const response = e.target.value;

    if (response === 'true') {
      localStorage.removeItem('tw-blog-post-draft');
      backToProfilePage();
    }

    dispatch(closeModal());
  };

  const handleTagsAutocomplete = async (name) => {
    return await getTagsSuggestions(name, tags);
  };

  const handleCancelAction = () => {
    dispatch(
      showModal({
        showModal: true,
        handleConfirmation: cancelPost,
        message: 'Are you sure you want to discard all changes?',
        cancelButton: 'Not now',
        submitButton: 'Yes, discard',
        type: 'confirmation'
      })
    );
  };

  const handleSubmitAction = () => {
    dispatch(
      showModal({
        showModal: true,
        handleConfirmation: createNewPost,
        message: 'Are you ready to publish?',
        cancelButton: 'Not now',
        submitButton: 'Publish',
        type: 'confirmation'
      })
    );
  };

  const handleUploadImage = async (image) => {
    const { data } = await uploadImageB64(image);
    return data.link;
  };

  const headerProps = {
    submitButtonLabel: 'Publish post',
    submitDisabled,
    handleSubmitAction,
    handleCancelAction
  };

  const handleLocalStorageSetItem = (post) => {
    try {
      localStorage.setItem(
        'tw-blog-post-draft',
        JSON.stringify({
          post: post,
          tags
        })
      );
    } catch (error) {
      setFeaturedImage({
        src: '',
        alt: '',
        croppedImage: '',
        isCoverEnabled: false
      });
      showToastifyError({
        title: 'Image was not uploaded!',
        message:
          '  Sorry! It seems that something wrong is happening with our system, we are working to fix this issue.'
      });
    }
  };

  const bodyProps = {
    handleChangeTitle,
    handleChangeContent,
    handleChangeFeaturedImage,
    post: postToCreate,
    showCK,
    duplicatedTitleError,
    featuredImage,
    setTags,
    handleUploadImage,
    isCoverEnabled,
    setIsCoverEnabled,
    handleTagsAutocomplete
  };

  useEffect(() => {
    handleLocalStorageSetItem(bodyProps.post);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTitle, postContent, tags, featuredImage]);

  return <Editor headerProps={headerProps} bodyProps={bodyProps} />;
};

CreateContainer.propTypes = {
  showCK: PropTypes.bool
};

CreateContainer.defaultProps = {
  showCK: true
};

export default CreateContainer;
