import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RemoveMarkdown from 'remove-markdown';
import * as S from './styles';

import { format } from '../../utils/date/formatDate';
import { deletePost } from '../../services/posts/service';
import { useDispatch } from 'react-redux';

import useEventTracker from '../../hooks/useEventTracker';
import { removePostInUserPostsList } from '../../store/profile/actions';

import { ModalAction } from '../Modals';
import { useLocation } from 'react-router-dom';
import PostSummaryWrapper from './Wrapper';

const PostSummary = ({ post, variant, position, origin, isAuthor }) => {
  const sendEvent = useEventTracker();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idDelete, setDeleteId] = useState();
  const location = useLocation();
  const dispatch = useDispatch();

  const MOBILE_CHAR_LIMIT = 85;
  const DESKTOP_CHAR_LIMIT = 165;

  const getCharLimit = () => {
    return window.innerWidth <= 600 ? MOBILE_CHAR_LIMIT : DESKTOP_CHAR_LIMIT;
  };

  const getSummary = (content) => {
    if (!content) {
      return '';
    }

    content = removeAnyHtmlTag(content);
    let summary = content.substring(0, getCharLimit()) + '...';
    summary = getContentWithoutMarkdownImageSintax(summary);
    summary = RemoveMarkdown(summary);
    summary = summary.replace(/>/g, '');
    summary = summary.replace(/#/g, '');

    return summary;
  };

  const removeAnyHtmlTag = (content) => {
    return content.replace(/(<([^>]+)>)/gi, '');
  };

  const getContentWithoutMarkdownImageSintax = (summary) => {
    return summary.replace(/!\[[^\]]+\]\([^)]+\)/, ' ');
  };

  const openDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const closeDeleteModal = () => {
    setDeleteId();
    setShowDeleteModal(false);
  };

  const handleDelete = () => {
    deletePost(idDelete).then((response) => {
      if (response.id) {
        dispatch(removePostInUserPostsList(response.id));
        closeDeleteModal();
      }
    });
  };

  return (
    <S.PostSummary data-testid="post-summary">
      <S.PostSummaryContainer>
        <div>
          {variant === 'profile' ? (
            <S.PostDate>{post && format(post.published_date)}</S.PostDate>
          ) : null}
          <div>
            <S.PostSummaryTitle
              onClick={() =>
                sendEvent(
                  origin,
                  `Click on ${position} - /${post.blog.slug}/${post.slug}`,
                  'PostSummary'
                )
              }
              to={{
                pathname: post.url
                  ? post.url
                  : `/${post.blog.slug}/${post.slug}`,
                state: { prevPath: `${location.pathname}` }
              }}
            >
              <h2>{post.title}</h2>
            </S.PostSummaryTitle>
            <S.Mobile>
              {post.featured_image_url ? (
                <S.PostFeaturedImage src={post.featured_image_url}>
                  <span
                    data-testid="featured-image-mobile"
                    role="img"
                    aria-label={post.featured_image_alt}
                  />
                </S.PostFeaturedImage>
              ) : null}
            </S.Mobile>
          </div>
          <S.PostSummaryContent
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            dangerouslySetInnerHTML={{
              __html: getSummary(post && post.content)
            }}
          />
        </div>
        <S.Desktop>
          {post.featured_image_url ? (
            <S.PostFeaturedImage src={post.featured_image_url}>
              <span
                data-testid="featured-image-desktop"
                role="img"
                aria-label={post.featured_image_alt}
              />
            </S.PostFeaturedImage>
          ) : null}
        </S.Desktop>
      </S.PostSummaryContainer>
      <PostSummaryWrapper
        post={post}
        variant={variant}
        openDeleteModal={openDeleteModal}
        isAuthor={isAuthor}
      />
      <ModalAction
        data-testid={'New'}
        showModal={showDeleteModal}
        handleConfirmation={handleDelete}
        handleCancel={closeDeleteModal}
        message={'Delete this post permanently?'}
        cancelButton={'Not now'}
        submitButton={'Delete'}
      />
    </S.PostSummary>
  );
};

PostSummary.propTypes = {
  post: PropTypes.object,
  variant: PropTypes.string,
  position: PropTypes.number,
  origin: PropTypes.string,
  isAuthor: PropTypes.bool
};

PostSummary.defaultProps = {
  isAuthor: false
};

export default PostSummary;
