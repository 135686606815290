import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ItemHeader from '../../../Comments/ItemHeader';
import ItemEditForm from './ItemEditForm';
import * as S from './styles';

const Item = ({ reply, handleEditReply, loggedUserId, openModal }) => {
  const [showEditTextForm, setShowEditTextForm] = useState(false);

  const openEditForm = () => {
    setShowEditTextForm(true);
  };

  const closeEditForm = () => {
    setShowEditTextForm(false);
  };

  const handleRemoveItem = (reply) => {
    openModal(reply.id)
  }

  return (
    <>
      <ItemHeader
        item={reply}
        editItem={openEditForm}
        removeItem={handleRemoveItem}
        allowEdit={loggedUserId === reply.author.id}
      />
      {showEditTextForm ? (
        <ItemEditForm
          reply={reply}
          closeEditForm={closeEditForm}
          handleEditReply={handleEditReply}
        />
      ) : (
        <S.ReplyText
          data-testid="reply-text"
          value={reply.text}
        />
      )}
    </>
  );
};

Item.propTypes = {
  reply: PropTypes.object,
  handleEditReply: PropTypes.func,
  loggedUserId: PropTypes.number,
  openModal: PropTypes.func
};

export default Item;
