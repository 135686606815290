import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setupMatomo } from '../../../setupMatomo';
import React from 'react';

export const MatomoContainer = () => {
  const location = useLocation();

  useEffect(() => {
    setupMatomo();
  }, [location]);

  return <></>;
};
