import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 66.3rem;
  height: auto;
  padding-top: 3.2rem;

  & > h2 {
    font-size: 2.0rem;
    line-height: 3.0rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & div {
    margin-right: 12px;
  }
`;

export const AuthorName = styled.a`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.gray70};
  margin: 2px 0px;
  width: max-content;

  :hover {
    color: ${({theme}) => theme.colors.primaryHover};
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export const TotalArticles = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: ${({theme}) => theme.colors.gray60};
  margin: 0;
  width: max-content;
`;

export const Bio = styled.div`

  max-width: 66.3rem;

  margin-top: 2.0rem;
  margin-bottom: 2.0rem;
  margin-right: 2.0rem;

  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Inter;

`;

export const Line = styled.hr`
    max-width: 66.3rem;
    border: 4;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.gray30};
    
`