import authApi from '../servers/authApi';

export const buildHeader = async (
  rss = false,
  contentType = 'application/json'
) => {
  const authorizationToken = process.env.REACT_APP_STRAPI_AUTHORIZATION_TOKEN;

  const oktaToken = authApi.getOktaToken();

  let defaultHeaders = {
    Authorization: 'Bearer ' + authorizationToken,
    'Okta-Token': oktaToken,
    'Response-Format': 'flatten',
    rss: rss
  };

  if (contentType !== '') {
    defaultHeaders['Content-Type'] = contentType;
  }

  return defaultHeaders;
};
