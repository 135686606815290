import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconUp } from '../Icons';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const BackTop = (props) => {
  const { isFloating, positionX, scrollPageFactor, scrollPoint } = props;

  // relative position of back to top button screenY
  const mobileProportion = 0.5;
  const desktopProportion = scrollPageFactor;
  const scrollPointOffSet = 120;

  const [showButton, setShowButton] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [windowDimensions] = useState(getWindowDimensions());

  const backToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {

    window.addEventListener('scroll', () => {
      
      const aux =
        windowDimensions.width <= 600
          ? windowDimensions.height * mobileProportion
          : scrollPoint !== null
          ? scrollPoint.current.offsetTop - windowDimensions.height + scrollPointOffSet
          : windowDimensions.height * desktopProportion;

      if (window.scrollY > aux) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Float
        isFloating={isFloating}
        hidden={!showButton}
        positionX={positionX}
      >
        <S.TopButton
          role="button"
          aria-label="back to top"
          onClick={backToTop}
          name="up-to-button-button"
        >
          <S.Circle>
            <IconUp size="l" color="#FFFFFF" disabledHover={true} />
          </S.Circle>
          <S.Text>Back to top</S.Text>
        </S.TopButton>
      </S.Float>
    </>
  );
};

BackTop.propTypes = {
  isFloating: PropTypes.bool,
  positionX: PropTypes.number,
  scrollPageFactor: PropTypes.number,
  scrollPoint: PropTypes.object
};

BackTop.defaultProps = {
  isFloating: false,
  positionX: 0,
  scrollPageFactor: 0,
  scrollPoint: null
};

export default BackTop;
