export const LIST_FEATURED_POSTS = 'LIST_FEATURED_POSTS';
export const GET_LATEST_POSTS = 'GET_LATEST_POSTS';
export const GET_USER_POSTS = 'GET_USERS_POSTS';
export const CLEAN_USER_POSTS = 'CLEAN_USER_POSTS';
export const SET_READ_POST_ID = 'SET_READ_POST_ID';

export const ADD_LIKE_LATEST_POSTS = 'ADD_LIKE_LATEST_POSTS';
export const ADD_LIKE_FEATURED_POSTS = 'ADD_LIKE_FEATURED_POSTS';
export const REMOVE_LIKE_LATEST_POSTS = 'REMOVE_LIKE_LATEST_POSTS';
export const REMOVE_LIKE_FEATURED_POSTS = 'REMOVE_LIKE_FEATURED_POSTS';

export const SEARCH_POSTS = 'SEARCH_POSTS';
export const SEARCH_AUTHORS = 'SEARCH_AUTHORS';
export const SEARCH_TAGS = 'SEARCH_TAGS';
export const SEARCHING = 'SEARCHING';
export const CLEAN_SEARCH_POSTS = 'CLEAN_SEARCH_POSTS';
export const HAS_MORE_POSTS = 'HAS_MORE_POSTS';
export const HAS_MORE_AUTHORS = 'HAS_MORE_AUTHORS';

export const SHOW_LOADING = 'SHOW_LOADING';

export const SET_LOGGED_USER = 'SET_LOGGED_USER';

export const LIST_NOTIFICATIONS = 'LIST_NOTIFICATIONS';

export const SET_PROFILE_USER = 'SET_PROFILE_USER';
export const SET_PROFILE_IS_LOOGED_USER = 'SET_PROFILE_IS_LOOGED_USER';
export const ADD_POSTS_ON_PROFILE_POSTS_LIST = 'ADD_POSTS_ON_PROFILE_POSTS_LIST';
export const CLEAN_ALL_PROFILE_INFO = 'CLEAN_ALL_PROFILE_INFO';
export const SET_PROFILE_HAS_MORE_POSTS = 'SET_PROFILE_HAS_MORE_POSTS';
export const FORCE_PROFILE_UPDATE = 'FORCE_PROFILE_UPDATE';
export const CLEAN_USER_POSTS_LIST = 'CLEAN_USER_POSTS_LIST';
export const REMOVE_POST_IN_USER_POSTS_LIST = 'REMOVE_POST_IN_USER_POSTS_LIST';

export const COMMENTS_LIST = 'COMMENTS_LIST'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'
export const ADD_COMMENT = 'ADD_COMMENT'
export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const ADD_REPLY_COMMENT = 'ADD_REPLY_COMMENT'
export const REMOVE_REPLY_COMMENT = 'REMOVE_REPLY_COMMENT'
export const UPDATE_REPLY_COMMENT = 'UPDATE_REPLY_COMMENT'

export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
