import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setLoggedUser } from '../../store/loggedUser/actions';
import { getUserByEmail } from '../../services/users/service';
import { getProfilePhoto } from '../../services/photos/service';

const UserSessionContainer = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();

  const loggedUser = useSelector((state) => state.userSession.loggedUser);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (!loggedUser.id) {
        oktaAuth.getUser().then((oktaUserInfo) => {
          getUserByEmail(oktaUserInfo.email).then((userProfile) => {
            if (userProfile.id) {
              getProfilePhoto(userProfile.email).then((response) => {
                const userInfoWithPhoto = { ...userProfile, photo: response };
                dispatch(setLoggedUser(userInfoWithPhoto));
              });
            }
          });
        });
      }
    }
    // eslint-disable-next-line
  }, [oktaAuth, authState]);

  return null;
};

export default UserSessionContainer;
