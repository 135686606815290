import PropTypes from 'prop-types';
import React from 'react';
import moreIcon from '../../../../images/more-icon.svg';
import Author from '../../Author';
import BackLink from '../../BackLink';
import CKEditorCustom from '../../CKEditorCustom';
import Dialog from '../../Dialog';
import { IconDelete, IconEdit } from '../../Icons';
import PostContent from '../../PostContent';
import PostCoverImage from '../../PostCoverImage';
import PostTags from '../../PostTags';
import * as S from './styles';

const Reader = ({ post, isAllowed, handleDelete }) => {
  const triggerButton = (
    <S.MenuAuthorButton aria-label="more actions">
      <img src={moreIcon} alt="more actions" />
    </S.MenuAuthorButton>
  );

  const handleBackAction = (history) => {
    const { state } = history?.location ?? undefined;

    if (state?.prevPath === '/') {
      history.goBack();
      return;
    }

    if (!state?.from) {
      history.push('/');
      return;
    }

    history.push(state.from);
  };

  return (
    <>
      <BackLink handleBackAction={handleBackAction} />
      <S.Wrapper>
        <Author
          authorName={post.author.name}
          authorEmail={post.author.email}
          username={post.author.username}
          publishedAt={post.published_date}
          sizeAvatar={'l'}
          variant={'post'}
        />
        {isAllowed && (
          <S.WrapperMenuAuthor>
            <Dialog
              trigger={triggerButton}
              boxPosition="bottom"
              boxDistanceX={-12}
              boxDistanceY={36}
              connectorPosition="left"
              connectorDistance={8}
            >
              <S.MenuAuthor>
                <S.MenuAuthorOptionsLink
                  aria-label="edit this post"
                  to={{
                    pathname: `/post/edit/${post.blog.slug}/${post.slug}`,
                    state: { post }
                  }}
                  tabIndex={0}
                >
                  <IconEdit size={'m'} color="#003d4f" disabledHover={true} />
                  Edit
                </S.MenuAuthorOptionsLink>

                <S.MenuAuthorOptions
                  onClick={handleDelete}
                  aria-label="delete this post"
                >
                  <IconDelete size={'m'} color="#003d4f" disabledHover={true} />
                  Delete
                </S.MenuAuthorOptions>
              </S.MenuAuthor>
            </Dialog>
          </S.WrapperMenuAuthor>
        )}
      </S.Wrapper>
      <PostCoverImage
        imageUrl={post.featured_image_url}
        altText={post.featured_image_alt}
        coverEnabled={post.cover_enabled}
      />
      <S.PostTitle tabIndex="0">{post.title}</S.PostTitle>
      {post.editor_version === 'v1' ? (
        <PostContent content={post.content} />
      ) : (
        <S.CKEditorReadOnlyWrapper>
          <CKEditorCustom
            data={post.content}
            featureId='ck-post-reader'
          />
        </S.CKEditorReadOnlyWrapper>
      )}

      <PostTags tags={post.tags} />
    </>
  );
};

Reader.propTypes = {
  post: PropTypes.object,
  isAllowed: PropTypes.bool,
  handleDelete: PropTypes.func
};

Reader.defaultProps = {
  isAllowed: false
};

export default Reader;
