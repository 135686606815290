import styled from 'styled-components';

export const LikesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LikeButton = styled.button`
  line-height: 1;
  margin-right: 0.8rem;
`;
