import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Button from '../../Button';

const CommentForm = ({ handleSubmit, clearComment }) => {
  const [commentText, setCommentText] = useState('');
  const [validateSubmit, setValidateSubmit] = useState(false);

  useEffect(() => {
    if(clearComment) handleClearTextArea();
    setValidateSubmit(false);
  }, [clearComment]);

  const handleClearTextArea = () => {
    setCommentText('');
  };

  const handleChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleSave = () => {
    setValidateSubmit(true);
    handleSubmit(commentText);
  };

  return (
    <S.Wrapper>
      <S.CommentLabel htmlFor="comment">Add comment</S.CommentLabel>
      <S.CommentTextArea
        id="comment"
        type="text"
        name="comment"
        placeholder="Write your comment here"
        rows="4"
        cols="61"
        value={commentText}
        onChange={handleChange}
      />
      <S.WrapperButtons>
        <Button
          size="m"
          variant="link"
          onClick={handleClearTextArea}
          disabled={!commentText}
          label="Clear"
        />
        <Button
          size="m"
          variant="primary"
          disabled={!commentText || validateSubmit}
          onClick={handleSave}
          label="Comment"
        />
      </S.WrapperButtons>
    </S.Wrapper>
  );
};

CommentForm.propTypes = {
  handleSubmit: PropTypes.func,
  clearComment: PropTypes.bool
};

export default CommentForm;
