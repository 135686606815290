import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import StackdriverErrorReporter from 'stackdriver-errors-js';

//Context
export const LoggerContext = createContext({});

//Provider
export const LoggerProvider = ({ children }) => {
  const errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: process.env.REACT_APP_API_KEY,
    projectId: process.env.REACT_APP_PROJECT_ID,
    service: 'blog',
    disabled: process.env.NODE_ENV === 'development'
  });

  const log = async (message) => {
    errorHandler.report(message);
  };

  return (
    <LoggerContext.Provider value={{ log }}>{children}</LoggerContext.Provider>
  );
};

LoggerProvider.propTypes = {
  children: PropTypes.object
};

//Hook
export const useLogger = () => {
  const context = useContext(LoggerContext);
  return context;
};
