import styled from 'styled-components';

export const List = styled.div`
  flex: 1;

  & > li {
    border-top: solid 1px #e2e4ec;
    width: 100%;
    display: flex;

    & > div {
      width: 100%;
    }

    :first-child{
      border-top: 0px;
    }
  }
`;
export const Title = styled.h2`
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: ${({theme}) => theme.colors.secondary};
  font-weight: 400;
  margin-top: 4.5rem;
  margin-bottom: 0.8rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
