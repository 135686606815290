const colorsHex = {
  flamingo: '#F2617A',
  flamingo_light: '#FAC0CA',
  flamingo_soft_light: '#FEEFF2',
  flamingo_s30: '#BD4257',
  wave: '#003D4F',
  wave_t30: '#4D7784',
  wave_t90: '#E6ECED',
  black_text: '#212223',
  black: '#000000',
  gray70: '#616364',
  gray60: '#808285',
  gray40: '#BDBEC0',
  gray30: '#E1E2E3',
  gray20: '#EEEEEF',
  gray5: '#FAFAFA',
  white: '#FFFFFF',
  red: '#d92d29',
  middle_green: '#4D805A'
};

export default {
  colors: {
    primary: colorsHex.flamingo,
    primaryLight: colorsHex.flamingo_light,
    primarySoftLight: colorsHex.flamingo_soft_light,
    primaryDark: colorsHex.flamingo_s30,
    primaryHover: colorsHex.flamingo_s30,
    secondary: colorsHex.wave,
    secondaryHover: colorsHex.wave_t30,
    secondaryLight: colorsHex.wave_t30,
    success: colorsHex.middle_green,
    text: colorsHex.black_text,
    separator: colorsHex.flamingo,
    black: colorsHex.black,
    gray70: colorsHex.gray70,
    gray60: colorsHex.gray60,
    gray40: colorsHex.gray40,
    gray30: colorsHex.gray30,
    gray20: colorsHex.gray20,
    gray5: colorsHex.gray5,
    white: colorsHex.white,
    wave90: colorsHex.wave_t90,
    error: colorsHex.red
  }
};
