import {
  SEARCH_POSTS,
  SEARCHING,
  CLEAN_SEARCH_POSTS,
  SEARCH_TAGS,
  SEARCH_AUTHORS,
  HAS_MORE_POSTS,
  HAS_MORE_AUTHORS
} from '../actionTypes';

const initialState = {
  resultPosts: [],
  resultAuthors: [],
  tagList: [],
  searching: false,
  hasMorePosts: true,
  hasMoreAuthors: true
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_POSTS:
      return {
        ...state,
        resultPosts: [...state.resultPosts, ...action.payload]
      };
    case SEARCH_AUTHORS:
      return {
        ...state,
        resultAuthors: action.payload
      };
    case CLEAN_SEARCH_POSTS:
      return {
        ...state,
        resultPosts: []
      };
    case HAS_MORE_POSTS:
      return {
        ...state,
        hasMorePosts: action.payload
      };
    case HAS_MORE_AUTHORS:
      return {
        ...state,
        hasMoreAuthors: action.payload
      };
    case SEARCHING:
      return {
        ...state,
        searching: action.payload
      };
    case SEARCH_TAGS:
      return {
        ...state,
        tagList: action.payload
      };
    default:
      return state;
  }
};
