import React from 'react';
import Avatar from '../../2.0/components/Avatar';
import * as S from './styles';
import { useSelector } from 'react-redux';
import Dialog from '../../2.0/components/Dialog';

const Logged = () => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);

  const MenuButton = () => (
    <S.MenuButton tabIndex={-1}>
      <Avatar
        fullName={loggedUser.name}
        email={loggedUser.email}
        photo={loggedUser.photo}
        size="l"
      />
      {loggedUser.name && (
        <S.LoggedUserNameForMobile>{loggedUser.name}</S.LoggedUserNameForMobile>
      )}
      {loggedUser.name && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
            fill="#003D4F"
          />
        </svg>
      )}
    </S.MenuButton>
  );

  return (
    <Dialog
      trigger={<MenuButton />}
      boxPosition="bottom"
      boxDistanceX={-28}
      boxDistanceY={64}
      connectorPosition="right"
      connectorDistance={12}
    >
      {loggedUser.name && (
        <S.Menu>
          <li>
            {/* eslint-disable-next-line */}
            <S.MenuLink
              to="/post/create"
              tabIndex={0}
              aria-label="create a post"
            >
              Create a post
            </S.MenuLink>
          </li>
          <li>
            {/* eslint-disable-next-line */}
            <S.MenuLink
              to={{ pathname: `/${loggedUser.username}`, from: 'menu' }}
              tabIndex={0}
              aria-label="my profile"
            >
              My profile
            </S.MenuLink>
          </li>
        </S.Menu>
      )}
    </Dialog>
  );
};

export default Logged;
