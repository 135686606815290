import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import * as S from './styles';
import { Checkbox } from '../Checkbox';
import EditIcon from '../../../images/edit-icon.svg';
import ImageDialog from './ImageDialog';

const FeaturedImage = ({
  checked,
  setChecked,
  featuredImage,
  setFeaturedImage
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <S.FeaturedImage id="featuredImage">
        {
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <h6 tabIndex={0}>Featured image</h6>
        }
        <S.Subtitle tabIndex={0}>
          This image makes your post more attractive.
        </S.Subtitle>
        {featuredImage.croppedImage ? (
          <>
            <S.FeaturedImagePreview
              featuredImageSrc={featuredImage.croppedImage}
            >
              <span
                className="featuredImagePreview"
                role="img"
                aria-label={featuredImage.alt}
              >
                <S.EditButton
                  aria-label="edit featured image"
                  onClick={openDialog}
                >
                  <img src={EditIcon} alt="edit" />
                </S.EditButton>
              </span>
            </S.FeaturedImagePreview>
            <Checkbox
              checked={checked}
              setChecked={setChecked}
              label="I want this image as a cover on my post"
              ariaLabel="I want this image as a cover on my post"
            />
          </>
        ) : (
          <Button
            size="s"
            variant="secondary"
            onClick={openDialog}
            disabled={false}
            label="Choose image"
          />
        )}
      </S.FeaturedImage>
      <ImageDialog
        showDialog={showDialog}
        closeDialog={closeDialog}
        featuredImage={featuredImage}
        setFeaturedImage={setFeaturedImage}
      />
    </>
  );
};

FeaturedImage.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  featuredImage: PropTypes.object,
  setFeaturedImage: PropTypes.func
};

export default FeaturedImage;
