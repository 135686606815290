import { blogApiNew } from '../../servers/blogApiNew';

export const getMostUsedTags = async (limit) => {
  return await blogApiNew.get(`/tags/most-used?pagination[limit]=${limit}`);
};

export const getTagsSuggestions = async (name, tags = []) => {
  let url = `/tags?filters[name][$contains]=${name}`;

  tags.forEach((tag) => {
    url += `&filters[name][$notIn]=${tag.name}`;
  });
  url += '&sort=name:asc';

  return await blogApiNew.get(url);
};

export const getTagIdByName = async (tagName) => {
  return await blogApiNew.get(`/tags?filters[name]=${tagName}`);
};

export const addTag = async (tagName) => {
  return await blogApiNew.post(`/tags`, { name: tagName });
};
