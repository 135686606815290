import React, { useEffect, useState } from 'react';
import { getTopContributors } from '../../services/posts/service';
import TopContributors from '../../components/TopContributors';
import * as S from './styles';
import TopContributorsSkeleton from '../../components/TopContributors/skeleton';

const TopsContributorsContainer = () => {
  const [topContributors, setTopContributorsList] = useState([]);

  useEffect(() => {
    getTopContributors(5).then((response) => {
      setTopContributorsList(response);
    });
  }, []);

  return (
    <S.Wrapper>
      <h2>Top 5 Contributors</h2>
      <S.Line />
      <S.Message>
        People who contribute with the community creating and sharing their knowledge.
      </S.Message>
      {topContributors.length >= 1 ? (
        <TopContributors topContributors={topContributors} />
      ) : (
        <TopContributorsSkeleton />
      )}
    </S.Wrapper>
  )
};

export default TopsContributorsContainer;
