import styled from 'styled-components';

/* Access the follow link
https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
to get CKEditor styles.
*/

export const PostContentWrapper = styled.div`
  p,
  ul,
  ol,
  blockquote,
  code,
  pre {
    white-space: 'pre-wrap';
    font-size: 2rem;
    line-height: 2.7rem;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 2.4rem;

    @media screen and (max-width: 600px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  ul,
  ol {
    padding-inline-start: 2rem;
    margin-left: 1rem;
  }

  ul li ul {
    padding-left: 4rem;
  }

  ul li {
    list-style-type: disc;
  }

  ul li ul li {
    list-style-type: circle;
  }

  ul li ul li ul li {
    list-style-type: square;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
  }

  pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
  }

  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }

  pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
  }

  p img {
    max-width: 100%;
  }

  p a {
    color: ${({ theme }) => theme.colors.primaryDark};
    text-decoration: underline;
    cursor: pointer;
  }

  margin-bottom: 3.4rem;
`;
