import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styles';

const ToggleSwitch = (props) => {
  const { showText, isDisabled, isChecked, handleChange, ariaLabel } = props;

  return (
    <S.Wrapper>
      <S.Input
        disabled={isDisabled}
        checked={isChecked}
        type="checkbox"
        onChange={handleChange}
        aria-label={ariaLabel}
      />
      <S.Switch />
      {showText ? <S.Text>{isChecked ? 'On' : 'Off'}</S.Text> : null}
    </S.Wrapper>
  );
};

ToggleSwitch.propTypes = {
  showText: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  ariaLabel: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

ToggleSwitch.defaultProps = {
  showText: true,
  isDisabled: false,
  isChecked: false,
  ariaLabel: '',
  handleChange: () => {}
};

export default ToggleSwitch;
