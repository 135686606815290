import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
`;

export const Container = styled.div`
  max-width: 110.8rem;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 1108px) {
    padding: 0 16px;
  }
`;

export const Logo = styled.a`
  display: block;
  min-width: 15.4rem;
  height: auto;
  margin-top: 0.6rem;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Navbar = styled.nav`
  font-size: 1.8rem;
  position: relative;
  flex: 1;

  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1;

    @media (max-width: 600px) {
      justify-content: flex-end;
    }

    > li {
      text-align: center;
    }
  }

  #openMobileMenu {
    display: none;

    @media (max-width: 600px) {
      display: block;
    }
  }
`;

export const NavLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 4rem;

  :hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export const NavLinkHome = styled.div`
  > a {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 6rem;

    :hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    ${({ isActive, theme }) =>
      isActive === true
        ? `
        color: ${theme.colors.primaryDark};
      `
        : `color: : ${theme.colors.secondary};
      `}
  }
`;

export const WrapperMenu = styled.div`
  display: flex;

  li {
    display: block;

    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const NotificationMobile = styled.li`
  display: flex;
  align-items: center;

  display: block;
  @media (min-width: 600px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 23.5px 16px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25.5px;
`;

export const MobileCloseLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const MobileMenuList = styled.ul`
  margin-top: 26px;

  li {
    display: block;
    padding-bottom: 24px;
    a {
      color: #fff;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
`;

export const MobileSeparator = styled.hr`
  width: 100%;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  margin-bottom: 3.2rem;
  margin-top: 2.4rem;
`;
