import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconLike } from '../Icons';
import LikesCounter from '../LikesCounter';

const Likes = ({ handleLikes, isDisabledLikeButton, isLiked, likesList }) => {
  return (
    <S.LikesWrapper>
      <S.LikeButton
        onClick={handleLikes}
        disabled={isDisabledLikeButton}
        aria-label="like"
        aria-pressed={isLiked}
      >
        <IconLike color="#616364" active={isLiked} />
      </S.LikeButton>
      <LikesCounter likesList={likesList} />
    </S.LikesWrapper>
  );
};

Likes.propTypes = {
  handleLikes: PropTypes.func,
  isDisabledLikeButton: PropTypes.bool,
  isLiked: PropTypes.bool,
  likesList: PropTypes.array
};

export default Likes;
