import styled from 'styled-components';

export const Page = styled.main`
  max-width: 77.5rem;
  margin: 0 auto;

  @media screen and (max-width: 728px) {
    margin: 0 16px 20px;
  }
`;

export const BackTopWrapper = styled.div`
  margin-top: 128px;
`;
