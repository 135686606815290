import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import AuthorBiography from '../../AuthorBiography';
import { IconArrowLeft, IconClose } from '../../Icons';
import * as S from './styles';

const FollowList = ({ list, message, showModal, handleCancel }) => {
  useEffect(() => {
    if (showModal) {
      handleOpenFolowList();
    }
  }, [showModal]);

  const handleOpenFolowList = () => {
    document.querySelector('body').style.overflow = 'hidden';
  };

  const handleCloseFollowList = () => {
    document.querySelector('body').style.overflow = 'auto';
    handleCancel();
  };

  const dialogRef = useClickOutside(() => {
    handleCloseFollowList();
  });

  return (
    <>
      {showModal && (
        <FocusTrap
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: 'none'
            }
          }}
        >
          <S.Modal tabIndex={-1} role="dialog" aria-modal="true">
            <S.ModalContent tabIndex={-1} ref={dialogRef}>
              <S.Header tabIndex={0}>
                <S.Back
                  onClick={handleCloseFollowList}
                  role="button"
                  name="back-button"
                  aria-label="Close modal"
                >
                  <IconArrowLeft
                    size="l"
                    color="#003D4F"
                    disabledHover={true}
                  />
                </S.Back>

                <S.Title>
                  {list.length} {list.length === 1 ? message : `${message}s`}
                </S.Title>

                <S.Close
                  onClick={() => handleCloseFollowList()}
                  name="close-button"
                  aria-label="Close modal"
                >
                  <IconClose size="m" color="#003D4F" disabledHover={true} />
                </S.Close>
              </S.Header>

              <S.List>
                {list.map((author, index) => (
                  <li key={index}>
                    <AuthorBiography
                      author={author}
                      isBiography={false}
                      showTotalPosts={false}
                    />
                    <S.Line />
                  </li>
                ))}
              </S.List>
            </S.ModalContent>
          </S.Modal>
        </FocusTrap>
      )}
    </>
  );
};

FollowList.propTypes = {
  list: PropTypes.array,
  message: PropTypes.string,
  showModal: PropTypes.bool,
  handleCancel: PropTypes.func
};

FollowList.defaultProps = {
  list: [],
  showModal: false
};

export default FollowList;
