import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from '../Checkbox';
import ChannelDefinition from './ChannelDefinition';
import * as S from './styles.js';
import TypeDefinition from './TypeDefinition';

const NotificationSettings = (props) => {
  const { settingsValues, onChangeSettingsValues } = props;
  const handleChangeCheck = (key, newValue) => {
    onChangeSettingsValues(key, newValue);
  };

  const addColumnCheckBoxOptions = (
    settingType,
    channelType,
    settingName,
    ariaLable
  ) => {
    const shiftRowPosition = 1;
    const shiftColumnPosition = 1;

    return (
      <S.CellPosition
        row={settingType + shiftRowPosition}
        column={channelType + shiftColumnPosition}
      >
        <Checkbox
          ariaLabel={ariaLable}
          checked={settingsValues[settingName]}
          sharpBorder={true}
          activateHover={true}
          setChecked={(newValue) => {
            handleChangeCheck(settingName, newValue);
          }}
        />
      </S.CellPosition>
    );
  };

  const blogColumnPosition = 1;
  const emailColumnPosition = 2;
  const likeRowPosition = 1;
  const commentRowPosition = 2;
  const repliesRowPosition = 3;
  const newFollowerRowPosition = 4;
  const blogsYouFollowRowPosition = 5;

  const addLikeYourPostSettings = () => {
    return (
      <>
        {addColumnCheckBoxOptions(
          likeRowPosition,
          blogColumnPosition,
          'blog_like_post',
          'like your post for blog'
        )}
        {addColumnCheckBoxOptions(
          likeRowPosition,
          emailColumnPosition,
          'email_like_post',
          'like your post for email'
        )}
      </>
    );
  };

  const addCommentSettings = () => {
    return (
      <>
        {addColumnCheckBoxOptions(
          commentRowPosition,
          blogColumnPosition,
          'blog_comment_post',
          'comment your post for blog'
        )}
        {addColumnCheckBoxOptions(
          commentRowPosition,
          emailColumnPosition,
          'email_comment_post',
          'comment your post for email'
        )}
      </>
    );
  };

  const addRepliesSettings = () => {
    return (
      <>
        {addColumnCheckBoxOptions(
          repliesRowPosition,
          blogColumnPosition,
          'blog_replies',
          'replies for blog'
        )}
        {addColumnCheckBoxOptions(
          repliesRowPosition,
          emailColumnPosition,
          'email_replies',
          'replies for email'
        )}
      </>
    );
  };

  const addNewFollowerSettings = () => {
    return (
      <>
        {addColumnCheckBoxOptions(
          newFollowerRowPosition,
          blogColumnPosition,
          'blog_new_follower',
          'new follower for blog'
        )}
        {addColumnCheckBoxOptions(
          newFollowerRowPosition,
          emailColumnPosition,
          'email_new_follower',
          'new follower for email'
        )}
      </>
    );
  };

  const addBlogsYouFollowSettings = () => {
    return (
      <>
        {addColumnCheckBoxOptions(
          blogsYouFollowRowPosition,
          blogColumnPosition,
          'blog_blogs_you_follow',
          'blogs you follow for blog'
        )}
        {addColumnCheckBoxOptions(
          blogsYouFollowRowPosition,
          emailColumnPosition,
          'email_blogs_you_follow',
          'blogs you follow for email'
        )}
      </>
    );
  };

  return (
    <>
      <S.NotificationSettingsDetails>
        <TypeDefinition type="Like your post" line={likeRowPosition} />
        <TypeDefinition type="Comment your post" line={commentRowPosition} />
        <TypeDefinition type="Replies" line={repliesRowPosition} />
        <TypeDefinition type="New follower" line={newFollowerRowPosition} />
        <TypeDefinition
          type="Blogs you follow"
          line={blogsYouFollowRowPosition}
        />

        <ChannelDefinition channel="Blog" position={blogColumnPosition} />
        <ChannelDefinition channel="Email" position={emailColumnPosition} />
        {addLikeYourPostSettings()}
        {addCommentSettings()}
        {addRepliesSettings()}
        {addNewFollowerSettings()}
        {addBlogsYouFollowSettings()}
      </S.NotificationSettingsDetails>
    </>
  );
};

NotificationSettings.propTypes = {
  settingsValues: PropTypes.any.isRequired,
  onChangeSettingsValues: PropTypes.func.isRequired
};

export default NotificationSettings;
