import { blogApiNew } from '../../servers/blogApiNew';

// ADD THE RIGHT PARAM WHERE IT`S CALLING IT
export const addLike = async (like) => {
  like.author = like.user;
  const url = `/likes`;
  const response = await blogApiNew.post(url, like);

  response.user = response.author;

  return response;
};

export const removeLike = async (likeId) => {
  const url = `/likes`;
  const response = await blogApiNew.delete(url, likeId);
  
  return response;
};
