const authApi = {
  getOktaToken: function () {
    const tokenStringFromStorage = localStorage.getItem('okta-token-storage');
    if (!tokenStringFromStorage) {
      throw new Error('Could not find okta token item on local storage');
    }

    let tokenConvertedToObject;
    try {
      tokenConvertedToObject = JSON.parse(tokenStringFromStorage);
    } catch (e) {
      throw new Error('Could not parse okta token string to a valid object');
    }

    if (
      !tokenConvertedToObject.accessToken ||
      !tokenConvertedToObject.accessToken.accessToken
    ) {
      throw new Error(
        'Could not find accessToken property on okta token converted object'
      );
    }

    return tokenConvertedToObject.accessToken.accessToken;
  }
};

export default authApi;
