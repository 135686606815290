import React from 'react';
import * as S from './styles';


import FeaturedItemSkeleton from './FeaturedItem/skeleton';

const postList = [0, 1, 2, 3];

const FeaturedListSkeleton = () => (
  <S.FeaturedListWrapper>
    {postList.map((index) => (
      <FeaturedItemSkeleton key={index} />
    ))}
  </S.FeaturedListWrapper>
);

export default FeaturedListSkeleton;
