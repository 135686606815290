import { blogApiNew } from '../../servers/blogApiNew';

const throwIfError = (data, message) => {
  if (data?.statusCode && data?.statusCode !== 200) {
    throw new Error(message);
  }
};

export const getFollowings = async (userEmail) => {
  const response = await blogApiNew.get(
    `/user-follows?filters[follower][email]=${userEmail}&sort=createdAt:desc&populate[0]=following`
  );

  throwIfError(response, 'Could not the number of followings!');

  const followings = response.map((follow) => follow.following);
  return followings;
};

export const getFollowers = async (userEmail) => {
  const response = await blogApiNew.get(
    `/user-follows?filters[following][email]=${userEmail}&sort=createdAt:desc&populate[0]=follower`
  );

  throwIfError(response, 'Could not the number of followers!');

  const followers = response.map((follow) => follow.follower);
  return followers;
};

export const findOneUserFollower = async (followerEmail, followingEmail) => {
  const userFollowers = await blogApiNew.get(
    `/user-follows?filters[follower][email]=${followerEmail}&filters[following][email]=${followingEmail}&populate=*`
  );

  throwIfError(userFollowers, 'Could not get follower and follwing!');

  return userFollowers?.length === 1 ? userFollowers[0] : {};
};

export const addFollower = async (followerId, followingId) => {
  const url = '/user-follows';
  const response = await blogApiNew.post(url, {
    follower: followerId,
    following: followingId
  });

  throwIfError(response, 'Could not add a new follower!');

  return response;
};

export const removeFollower = async (userFollowersId) => {
  const url = '/user-follows';
  const response = await blogApiNew.delete(url, userFollowersId);

  throwIfError(response, 'Could not remove follower!');

  return response;
};
