import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-right: 2.6rem ;

`;

export const SearchField = styled.input`
  border: none;
  outline: 0rem;
  margin-left: 1.4rem;
  margin-right: 0.7rem;
  flex:1;
  /* min-width: 29.7rem; */
  /* box-shadow: 0 0 0 30px white inset !important; */
`;

export const CleanButton = styled.button`
  /* padding-top: 0.35rem; */
  background-color: ${({ theme }) => theme.colors.gray30};
  line-height: 0;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
`;


// export const Icon = styled.span`
//   /* padding: 0.35rem 0.6rem;
//   width: 3rem; */
//   display: ;
// `;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.2rem;
  padding: 0.5rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  height: 3.5rem;
  width: 29.7rem;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Container = styled.div`
  padding-left: 1.0rem;
`;
