import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const WrapperFollow = styled.div`
  display: flax;
  gap: 48px;
  margin-bottom: 24px;
`;

export const Followers = styled.button`
  width: fit-content;
  display: flex;
  gap: 8px;
  ${(props) =>
    props.followers > 0 &&
    css`
      &:hover {
        h6 {
          cursor: pointer;
          color: ${({ theme }) => theme.colors.secondaryHover};
        }
      }
    `}
`;
export const Following = styled.button`
  width: fit-content;
  display: flex;
  gap: 8px;
  ${(props) =>
    props.followings > 0 &&
    css`
      &:hover {
        h6 {
          cursor: pointer;
          color: ${({ theme }) => theme.colors.secondaryHover};
        }
      }
    `}
`;

export const WrapperAction = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Amount = styled.h6`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Text = styled.h6`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const PostListHeader = styled.div`
  border-bottom: 1px solid #e1e2e3;
  padding-top: 4rem;
  color: ${({ theme }) => theme.colors.secondary};

  h4 {
    width: fit-content;
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 1.1rem;
    border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const FeedbackMessage = styled.p`
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;
  margin-top: 4.5rem;
  margin-bottom: 0.8rem;
`;

export const MessageScroll = styled.p`
  text-align: center;
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const PostsList = styled.ul`
  & > li {
    + li {
      border-top: solid 1px #e2e4ec;
    }
  }
`;
