import React, { useState, useEffect } from 'react';
import { addComment } from '../../services/comments/service';
import { useSelector, useDispatch } from 'react-redux';
import CommentForm from '../../components/Comments/Form';
import { addCommentToList } from '../../store/comments/actions';

const CreateCommentContainer = () => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const postId = useSelector((state) => state.post.readPostId);
  const [clearComment, setClearComment] = useState(false);
  const [requestState, setRequestState] = useState('loading');

  const dispatch = useDispatch();

  useEffect(() => {
    if (postId !== '') setRequestState('resolved');
  }, [postId]);

  const handleSubmit = (commentText) => {
    if (commentText) {
      const comment = {
        text: commentText,
        post: postId,
        author: loggedUser.id
      };

      addComment(comment).then((response) => {
        setClearComment(true);

   
        dispatch(addCommentToList(response));

        setClearComment(false);
      });
    }
  };

  switch (requestState) {
    case 'resolved':
      return (
        <CommentForm handleSubmit={handleSubmit} clearComment={clearComment} />
      );
    default:
      return null;
  }
};

export default CreateCommentContainer;
