import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 4rem;

  & > hr {
    width: 100%;
    border: none;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.gray30};
  }

  & > h4 {
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: 600px) {
    & > h4 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

export const Line = styled.hr`
  width: 100%;
  border: 4;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.colors.gray30};
`;

export const Message = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border: 4;
  color: ${({ theme }) => theme.colors.gray70};
`;

export const TopContributorsList = styled.ul`
  & > li {
    width: 100%;
    margin-top: 0.7rem;
    + li {
      margin-top: 1.7rem;
    }

    & > div {
      margin-top: 1.7rem;
    }
  }
`;

export const SkeletonElement = styled.div`
  width: 10%;
`;

