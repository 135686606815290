export function handleHttpResponse(response) {
  const responseContentType = response.headers.get('content-type');
  const responseIsNotJson = !responseContentType.includes('application/json');

  if (responseIsNotJson) {
    return Promise.reject({
      error: response.statusText,
      statusCode: response.status,
      message: 'Could not parse http response body cause it is not a json',
      url: response.url
    });
    // throw new Error(`Could not parse http response body. Content-type received is ${responseContentType}, and is expected a json`);
  }

  return handleJsonResponse(response);
}

function handleJsonResponse(response) {
  return response.json().then((json) => {
    if (response.ok) {
      return json;
    } else {
      return Promise.reject({
        status: response.status ?? 0,
        error: response.statusText,
        ...json,
        url: response.url
      });
    }
  });
}

export function handleHttpError(error) {
  console.error('Api call failed');
  return Promise.reject(error);
}
