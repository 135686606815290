import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import AuthorBiography from '../AuthorBiography';

const TopContributors = ({ topContributors }) => {
  return (
    <S.TopContributorsList>
      {topContributors.map((author, index) => (
        <li data-testid="author" key={index}>
          <div>
            <AuthorBiography author={author} isBiography={false} />
          </div>
        </li>
      ))}
    </S.TopContributorsList>
  );
};

TopContributors.propTypes = {
  topContributors: PropTypes.array
};

export default TopContributors;
