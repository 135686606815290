import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styles';

const ReplyCounter = ({ quantity }) => {
  const hideCounter = quantity === 0;

  return (
    <S.Paragraph hidden={hideCounter}>
      {quantity}
    </S.Paragraph>
  );
};

ReplyCounter.propTypes = {
  quantity: PropTypes.number
};

ReplyCounter.defaultProps = {
  quantity: 0
};

export default ReplyCounter;
