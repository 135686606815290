import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import * as S from './styles';

const PostContent = ({ content }) => {
  const sanitizeContent = () => {
    const postContentSanitized = DOMPurify.sanitize(content, {
      ADD_ATTR: ['rel', 'target', 'contenteditable']
    });

    return {
      __html: postContentSanitized
    };
  };

  return (
    <S.PostContentWrapper
      dangerouslySetInnerHTML={sanitizeContent()}
      tabIndex="0"
    />
  );
};

PostContent.propTypes = {
  content: PropTypes.string.isRequired,
  editorVersion: PropTypes.string
};

PostContent.defaultProps = {
  editorVersion: 'v2'
};

export default memo(PostContent);
