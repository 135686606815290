import { buildHeader } from '../buildHeader';
import {
  handleHttpError,
  handleHttpResponse
} from '../_httpRequestsErrorHandling';

const _buildRequestBody = (payload) => {
  return JSON.stringify({ data: payload });
};

const BLOG_API_URL =
  process.env.REACT_APP_BLOG_BACKEND_URL ||
  'https://blog-backend-run-dev-5rw7cbchda-uc.a.run.app/api';

export const blogApiNew = {
  get: async function (path, supressErrors = []) {
    const headers = await buildHeader(false, 'application/json');

    return fetch(`${BLOG_API_URL}${path}`, {
      method: 'GET',
      headers
    })
      .then(handleHttpResponse)
      .then((data) => data)
      .catch((error) => {
        const status = error.status;
        if (supressErrors instanceof Array && !supressErrors.includes(status)) {
          handleHttpError(error);
        }
      });
  },
  post: async function (path, payload) {
    const headers = await buildHeader(false, 'application/json');

    let url = `${BLOG_API_URL}${path}?populate=*`;

    return fetch(url, {
      method: 'POST',
      headers,
      body: _buildRequestBody(payload)
    })
      .then(handleHttpResponse)
      .then((data) => data)
      .catch(handleHttpError);
  },
  edit: async function (path, entityId, payload) {
    const headers = await buildHeader(false, 'application/json');

    let url = `${BLOG_API_URL}${path}/${entityId}?populate=*`;

    return fetch(url, {
      method: 'PUT',
      headers,
      body: _buildRequestBody(payload)
    })
      .then(handleHttpResponse)
      .then((data) => data)
      .catch(handleHttpError);
  },
  delete: async function (path, entityId) {
    const headers = await buildHeader(false, 'application/json');

    let url = `${BLOG_API_URL}${path}/${entityId}?populate=*`;

    return fetch(url, {
      method: 'DELETE',
      headers
    })
      .then(handleHttpResponse)
      .then((data) => data)
      .catch(handleHttpError);
  },
  count: async function (path, query = '') {
    const headers = await buildHeader(false, 'application/json');

    return fetch(`${BLOG_API_URL}${path}/count?${query}`, {
      method: 'GET',
      headers
    })
      .then(handleHttpResponse)
      .then((data) => data)
      .catch(handleHttpError);
  }
};
