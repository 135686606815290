import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top:4rem;

  & > hr {
    width: 100%;
    border: none;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.gray30};
  }
  & > h2 {
    font-size: 2.0rem;
    line-height: 3.0rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Line = styled.hr`
  width: 100%;
  border: 4;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.colors.gray30};
`

export const Message = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
  width: 100%;
  border: 4;
  color: ${({ theme }) => theme.colors.gray70};
`