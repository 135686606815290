import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyles = createGlobalStyle`
  /* CKEditor Customization */
  :root {
    --ck-font-size-base: 12px;
    --ck-color-toolbar-background:#fafafa;
    --ck-border-radius:0;
    --ck-color-button-on-color:#BD4257;
    --ck-color-button-on-background:#FEEFF2;
    --ck-color-focus-border:#BD4257;
    --ck-color-button-on-hover-background: #fcdde3;
    --ck-color-button-on-active-background: #fcdde3;
    --ck-color-focus-outer-shadow: #fcdde3;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
    font-weight: 400;
    line-height: 1.5;
  }

  html, textarea, button, input {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,'Helvetica Neue', sans-serif;
  }

  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    color: #212223;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h1, #createPostTitle, #editPostTitle {
    font-family: 'Bitter', serif;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.8rem;
  }

  h2 {
    font-size: 2.8rem;
    line-height: 4.2rem;
    font-weight: 700;
  }

  h3 {
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 700;
  }

  h4 {
    font-size: 2.0rem;
    line-height: 3.0rem;
    font-weight: 700;
  }

  h5 {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 700;
  }

  h6 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  iframe {
    display: none;
  }

  .Toastify__toast-container {
    --toastify-color-success: #4d805a;
    --toastify-color-error:#bb3e2a;
    --toastify-toast-width: 280px;
  }

  .Toastify__toast-body {
    font-size: 14px;
  }

  .Toastify__toast-container--bottom-right {
    right: 2em;
    bottom: 2em;
  }
`;

export default GlobalStyles;
