import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToggleSwitch from '../ToggleSwitch/index.jsx';
import * as S from './styles.js';

const NewsletterSettings = ({ isActiveWeeklyDigest, handleChange }) => {
  const [isChecked, setIsChecked] = useState(isActiveWeeklyDigest);

  const handleIsChecked = (event) => {
    const isCheckUpdated = event.target.checked;

    setIsChecked(isCheckUpdated);
    handleChange('weekly_highlights', isCheckUpdated);
  };

  return (
    <S.Wrapper>
      <S.SectionName tabIndex="0">Newsletters</S.SectionName>
      <S.SectionContent tabIndex="0">
        <S.Title>Weekly Digest</S.Title>
        <S.Description>
          Receive highlighted posts of the week by email
        </S.Description>

        <ToggleSwitch
          ariaLabel="Weekly Digest"
          isChecked={isChecked}
          handleChange={handleIsChecked}
        />
      </S.SectionContent>
    </S.Wrapper>
  );
};

NewsletterSettings.propTypes = {
  isActiveWeeklyDigest: PropTypes.bool,
  handleChange: PropTypes.func
};

NewsletterSettings.defaultProps = {
  isActiveWeeklyDigest: true
};

export default NewsletterSettings;
