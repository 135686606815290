import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Tags from '../../../../components/tags/tags';

const ResultTags = (props) => {
  const { tagList } = props;

  return (
    <S.Wrapper>
      {tagList.length > 0 ? (
        <Tags tags={tagList} isClickable={true} marginTop={'32px'} />
      ) : (
        <S.Message>We couldn’t find any Tags.</S.Message>
      )}
    </S.Wrapper>
  );
};

ResultTags.propTypes = {
  tagList: PropTypes.array
};

export default ResultTags;
