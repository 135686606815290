import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import MostPopularContainer from '../../containers/MostPopularContainer';
import LatestPostsContainer from '../../containers/LatestPostsContainer';
import MostUsedTagsContainer from '../../containers/MostUsedTagsContainer';
import TopsContributorsContainer from '../../containers/TopContributors';
import BackTop from '../../components/BackTop';
import FeaturedPostsContainer from '../../containers/FeaturedPostsContainer'; // Provider
import FeaturedPostContainer from '../../containers/FeaturedPostsContainer/FeaturedPostContainer'; // Consumer
import FeaturedListContainer from '../../containers/FeaturedPostsContainer/FeaturedListContainer'; // Consumer
import withPreventScrollFreezing from '../withPreventScrollFreezing';
import CelebrationBanner from '../../components/CelebrationBanner';
import { FeatureToggle } from '../../components/FeatureToggle';

const Home = () => {
  const scrollPointReference = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 900;

    if (isMobile && window.scrollY > 0) {
      const mobileScrollCorrection = 855;
      window.scrollTo(0, window.scrollY - mobileScrollCorrection);
    }
  }, []);

  return (
    <S.Page>
      <FeatureToggle featureName="1k_posts_celebration">
        <CelebrationBanner />
      </FeatureToggle>
      <S.Headline>Thoughtworks Central Blog</S.Headline>
      <S.GridLayout>
        <FeaturedPostsContainer />
        <S.FeaturedPostArea>
          <FeaturedPostContainer />
        </S.FeaturedPostArea>
        <S.FeaturedListArea>
          <FeaturedListContainer />
        </S.FeaturedListArea>
        <S.LatestPostsArea>
          <LatestPostsContainer />
        </S.LatestPostsArea>
        <S.AsideArea>
          <MostPopularContainer />
          <TopsContributorsContainer />
          <MostUsedTagsContainer />
          <S.ScrollPointReference ref={scrollPointReference} />
        </S.AsideArea>
      </S.GridLayout>
      <BackTop isFloating={true} scrollPoint={scrollPointReference} />
    </S.Page>
  );
};

Home.propTypes = {
  props: PropTypes.object
};

export default withPreventScrollFreezing(Home);
