import React from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MostPopularPostsSkeleton = () => {
  const postList = [0, 1, 2, 3, 4];

  return (
    <S.MostPopularList>
      {postList.map((post, index) => (
        <li key={index + 1}>
          <span>0{index + 1} </span>
          <S.Container>
            <S.SkeletonTitle>
              <h6>
                <Skeleton />
              </h6>
            </S.SkeletonTitle>
            <p>
              <span>
                <S.SkeletonAuthor>
                  <Skeleton />
                </S.SkeletonAuthor>
              </span>
            </p>
          </S.Container>
        </li>
      ))}
    </S.MostPopularList>
  );
};

export default MostPopularPostsSkeleton;
