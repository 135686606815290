import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NotFound from '../../../../components/notFound';
import LoadingCircle from '../../../components/LoadingCircle';
import Editor from '../../../components/Post/Editor';
import { showToastify } from '../../../components/ToastifyTemplate';
import { uploadImageB64 } from '../../../services/images/service';
import { getPostBySlugName, updatePost } from '../../../services/posts/service';
import { closeModal, showModal } from '../../../store/modals/actions';
import { forceProfileUpdate } from '../../../store/profile/actions';
import { isPostValid } from '../utils/postValidator';
import {
  addTag,
  getTagIdByName,
  getTagsSuggestions
} from '../../../services/tags/service';

const EditContainer = ({ showCK }) => {
  const { blog_slug: blogSlug, post_slug: postSlug } = useParams();
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const history = useHistory();
  const { state: locationState } = useLocation();
  const [post, setPost] = useState({});
  const [postTitle, setPostTitle] = useState('');
  const [postContent, setPostContent] = useState('');
  const [postEditorVersion, setPostEditorVersion] = useState('');
  const [requestState, setRequestState] = useState('loading');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [duplicatedTitleError, setDuplicatedTitleError] = useState(false);
  const [tags, setTags] = useState([]);
  const [isCoverEnabled, setIsCoverEnabled] = useState(false);

  const dispatch = useDispatch();

  const [featuredImage, setFeaturedImage] = useState({
    src: '',
    alt: '',
    croppedImage: ''
  });

  const isPostLoadedOnState = () => {
    return !!locationState?.post;
  };

  const loadPost = (post) => {
    setPostTitle(post.title);
    setPostContent(post.content);
    setPost(post);
    setTags(post.tags);
    setFeaturedImage({
      src: post.featured_image_url,
      alt: post.featured_image_alt,
      croppedImage: post.featured_image_url
    });
    setIsCoverEnabled(post.cover_enabled);
    setPostEditorVersion(post.editor_version);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleTagsAutocomplete = async (name) => {
    return await getTagsSuggestions(name, tags);
  };

  const handleAlertOldEditor = () => {
    const message = (
      <span>
        <b>Good news!</b> We now have a new text editor to make your content
        creation experience richer and better. Hence, we request you to review
        your content formatting and make sure everything is good as before.
        <br />
        <br />
        <b>Note:</b> You will only need to do this once.{' '}
      </span>
    );

    dispatch(
      showModal({
        showModal: true,
        handleConfirmation: handleCloseModal,
        textButton: 'Ok, I will check',
        message: message,
        title: 'Please review your post formatting!',
        type: 'error'
      })
    );
  };

  useEffect(() => {
    if (!loggedUser.id) {
      return;
    }

    if (isPostLoadedOnState()) {
      loadPost(locationState?.post);
      setRequestState('resolved');

      return;
    }

    if (loggedUser?.username === blogSlug) {
      getPostBySlugName(blogSlug, postSlug)
        .then((response) => {
          loadPost(response);
          setRequestState('resolved');
        })
        .catch((error) => {
          console.error(error);
          setRequestState('error');
        });
    } else {
      setRequestState('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogSlug, postSlug, loggedUser]);

  useEffect(() => {
    if (isPostValid(postTitle, postContent, showCK) && isPostChanged()) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [postContent, postTitle, post, featuredImage, tags]);

  const isPostChanged = () => {
    return (
      isPostTitleChanged() ||
      isPostContentChanged() ||
      isPostFeaturedImageChanged() ||
      isTagsChanged()
    );
  };

  const isPostTitleChanged = () => {
    return post.title !== postTitle;
  };

  const isPostContentChanged = () => {
    return post.content.replace(/\s/g, '') !== postContent.replace(/\s/g, '');
  };

  const isTagsChanged = () => {
    if (tags.length !== post.tags.length) return true;

    return tags.filter((tag) => tag.id === undefined).length > 0;
  };

  const isPostFeaturedImageChanged = () => {
    return (
      post.featured_image_url !== featuredImage.src ||
      post.featured_image_alt !== featuredImage.alt ||
      post.featured_image_url !== featuredImage.croppedImage ||
      post.cover_enabled !== isCoverEnabled
    );
  };

  const handleChangeTitle = (data) => {
    validateDuplicatedTitleCase();

    setPostTitle(data);
  };

  const handleChangeContent = (data) => {
    setPostContent(data);
  };

  const validateDuplicatedTitleCase = () => {
    if (duplicatedTitleError) {
      setDuplicatedTitleError(false);
    }
  };

  const cancelPost = (e) => {
    const response = e.target.value;

    if (response === 'true') {
      history.goBack();
    }

    dispatch(closeModal());
  };

  const handleCancelAction = () => {
    dispatch(
      showModal({
        showModal: true,
        handleConfirmation: cancelPost,
        message: 'Are you sure you want to discard all changes?',
        cancelButton: 'Not now',
        submitButton: 'Yes, discard',
        type: 'confirmation'
      })
    );
  };

  const handleSubmitAction = () => {
    dispatch(
      showModal({
        showModal: true,
        handleConfirmation: handlePublishPost,
        message: 'Update your post?',
        cancelButton: 'Not now',
        submitButton: 'Update',
        type: 'confirmation'
      })
    );
  };

  const handleUpdatePost = async () => {
    const editedPost = { editor_version: 'v2', author: loggedUser.id };
    if (isPostTitleChanged()) editedPost.title = postTitle.trim();
    if (isPostContentChanged()) editedPost.content = postContent;

    await addFeaturedPostImageOnEditedPost(editedPost);

    if (isTagsChanged() && tags.length > 0) {
      try {
        const tagList = tags.length > 0 ? await generateTagList() : [];
        editedPost.tags = tagList;
      } catch (e) {
        throw new Error(e.errorMessage);
      }
    }

    try {
      const updatedPost = await updatePost(post.id, editedPost);
      dispatch(forceProfileUpdate(true));
      backToPost(updatedPost);
    } catch (e) {
      if (e.message === 'duplicatedTitle') {
        setDuplicatedTitleError(true);

        throw new Error('Duplicated title.');
      }

      throw new Error();
    }
  };

  const generateTagList = async () => {
    return Promise.all(
      tags.map(async (tag) => {
        return await getTagIdByName(encodeURIComponent(tag.name)).then(
          async (response) => {
            if (response.length === 0) {
              return await addTag(tag.name).then((tag) => {
                return tag.id;
              });
            } else {
              return response[0].id;
            }
          }
        );
      })
    );
  };

  const addFeaturedPostImageOnEditedPost = async (editedPost) => {
    if (isPostFeaturedImageChanged()) {
      if (!featuredImage.croppedImage) {
        editedPost.featured_image_alt = '';
        editedPost.featured_image_url = '';
        editedPost.cover_enabled = false;

        return;
      }

      if (featuredImage.croppedImage !== post.featured_image_url) {
        const responseImg = await uploadImageB64(featuredImage.croppedImage);
        editedPost.featured_image_url = responseImg.data.link;
      }

      editedPost.featured_image_alt = featuredImage.alt;
      editedPost.cover_enabled = isCoverEnabled;
    }
  };

  const handlePublishPost = async (e) => {
    const response = e.target.value;

    if (response === 'true') {
      showToastify(handleUpdatePost(), {
        pending: 'Updating...',
        successTitle: 'Congrats!',
        successMessage: 'Your post was published.',
        errorTitle: 'Something went wrong!',
        errorMessage:
          "Don't worry, your progress was saved in cache. Please try again later."
      });
    }

    dispatch(closeModal());
  };

  const backToPost = (post) => {
    history.push({
      pathname: `/${post.blog.slug}/${post.slug}`,
      state: { prevPath: history.location, from: locationState?.from }
    });
  };

  const postToEdit = {
    published_date: new Date(),
    author: {
      name: loggedUser.name,
      email: loggedUser.email,
      username: loggedUser.username
    },
    title: postTitle,
    content: postContent,
    tags,
    featured_image_url: featuredImage.src,
    featured_image_alt: featuredImage.alt,
    croppedImage: featuredImage.croppedImage,
    cover_enabled: isCoverEnabled,
    post_editor_version: postEditorVersion
  };

  const handleChangeFeaturedImage = (data) => {
    setFeaturedImage(data);
  };

  const handleUploadImage = async (image) => {
    const { data } = await uploadImageB64(image);
    return data.link;
  };

  const headerProps = {
    submitButtonLabel: 'Update post',
    submitDisabled,
    handleSubmitAction,
    handleCancelAction
  };

  const bodyProps = {
    handleChangeTitle,
    handleChangeContent,
    handleChangeFeaturedImage,
    handleAlertOldEditor,
    post: postToEdit,
    showCK,
    duplicatedTitleError,
    featuredImage,
    setTags,
    handleUploadImage,
    isCoverEnabled,
    setIsCoverEnabled,
    handleTagsAutocomplete
  };

  switch (requestState) {
    case 'loading':
      return <LoadingCircle showLoading={true} loadingText="Loading ..." />;
    case 'resolved':
      return <Editor headerProps={headerProps} bodyProps={bodyProps} />;
    default:
      return <NotFound />;
  }
};

EditContainer.propTypes = {
  showCK: PropTypes.bool
};

EditContainer.defaultProps = {
  showCK: true
};

export default EditContainer;
