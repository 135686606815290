import styled from 'styled-components';

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  display: 'block';
  color: #808285;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.8;
`;
