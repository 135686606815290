import {
  SET_PROFILE_USER,
  SET_PROFILE_IS_LOOGED_USER,
  ADD_POSTS_ON_PROFILE_POSTS_LIST,
  CLEAN_ALL_PROFILE_INFO,
  SET_PROFILE_HAS_MORE_POSTS,
  FORCE_PROFILE_UPDATE,
  CLEAN_USER_POSTS_LIST,
  REMOVE_POST_IN_USER_POSTS_LIST
} from '../actionTypes';

export const setProfileUser = (value) => ({
  type: SET_PROFILE_USER,
  payload: value
});

export const setProfileIsLoggedUser = (value) => ({
  type: SET_PROFILE_IS_LOOGED_USER,
  payload: value
});

export const addPostsOnProfilePostsList = (value) => ({
  type: ADD_POSTS_ON_PROFILE_POSTS_LIST,
  payload: value
});

export const cleanAllProfileInfo = () => ({
  type: CLEAN_ALL_PROFILE_INFO
});

export const setProfileHasMorePosts = (value) => ({
  type: SET_PROFILE_HAS_MORE_POSTS,
  payload: value
});

export const forceProfileUpdate = (value) => ({
  type: FORCE_PROFILE_UPDATE,
  payload: value
});

export const cleanUserPostsList = (value) => ({
  type: CLEAN_USER_POSTS_LIST,
  payload: value
});

export const removePostInUserPostsList = (value) => ({
  type: REMOVE_POST_IN_USER_POSTS_LIST,
  payload: value
});