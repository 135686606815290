import styled from 'styled-components';

export const Page = styled.main`
  max-width: 110.8rem;
  margin: 0 auto 60px;

  @media screen and (max-width: 1108px) {
    margin: 0 16px 60px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  margin-top: 4.1rem;
  width: 53.9rem;
  height: 36.8rem;
  margin-right: 3.2rem;
  margin-bottom: 8.0rem;

  @media screen and (max-width: 900px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 5.6rem;
  font-weight: 70.0rem;
  line-height: 6.7rem;

  @media screen and (max-width: 600px) {
    font-size: 4.0rem;
    line-height: 4.8rem;
    margin: 0;
  }
`;

export const FirstMessage = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 2.0rem;
  font-weight: 400;
  font-family: Inter;

  @media screen and (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }
`;

export const Subtitle = styled.h3`
  margin-top: 4.0rem;
`;

export const SecondMessage = styled.p`
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-top: 1.2rem;

  @media screen and (max-width: 900px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: 1.5rem;
  }
`;

export const Image = styled.img`
  margin-top: 81px;
  width: 538px;
  height: 335px;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin-top: 20px;
    /* margin-bottom: 30px; */
  }
`;

export const Feedback = styled.a`
  color: ${({ theme }) => theme.colors.primaryDark};
  text-decoration-line: underline;

  :hover {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    @media screen and (max-width: 600px) {
      :hover{
      color: ${({ theme }) => theme.colors.primaryDark};
      opacity:1.0;
      box-shadow:none;
      }
    }
`;


