import ReactGA from 'react-ga';

const useEventTracker = () => {
  const sendEvent = (category, action, label) => {
    ReactGA.event({
      category,
      action,
      label,
      nonInteraction: false
    });
  };

  return sendEvent;
};

export default useEventTracker;
