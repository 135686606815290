import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 82.3rem;
  margin: 0 auto;
`;

export const Message = styled.h2`
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;
  margin-top: 4.5rem;
  margin-bottom: 0.8rem;
`;
