import styled from 'styled-components';

export const Wrapper = styled.div`
  button {
    padding-left: 0;
  }
`;

export const Modal = styled.main`
  background: ${({ theme }) => theme.colors.gray5};
  position: absolute;
  z-index: 3;
  width: 350px;
  padding: 1.6rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Content = styled.div`
  margin-top: 28px;
  h6 {
    margin-bottom: 12px;
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
  }
`;

export const Picture = styled.div`
  text-align: center;
  padding: 24px 0;
`;
