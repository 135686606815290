import React, { useEffect } from 'react';

// eslint-disable-next-line
const withPreventScrollFreezing = (WrappedComponent) => (props) => {
  useEffect(() => {
    preventScrollFreezing();
  }, []);

  function preventScrollFreezing() {
    document.querySelector('body').style.overflow = 'auto';
  }

  return <WrappedComponent {...props} />;
};

export default withPreventScrollFreezing;
