import React from 'react';
import * as S from './styles';
import AuthorSkeleton from '../../Author/skeleton';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FeaturedItemSkeleton = () => {
  return (
    <S.FeaturedItem>
      <S.Wrapper>
        <S.SkeletonTitle>
          <Skeleton />
        </S.SkeletonTitle>
        <S.WrapperSkeleton>
          <AuthorSkeleton />
        </S.WrapperSkeleton>
      </S.Wrapper>
      <S.PostFeaturedImage>
        <Skeleton square height={100} width={100} />
      </S.PostFeaturedImage>
    </S.FeaturedItem>
  );
};

export default FeaturedItemSkeleton;
