import { upload } from '../../servers/strapiAPI';
import dataUrlToFile from '../utils/images/dataUrlToFile';

export const upload_image = async (file) => {
  const fileHash = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');
  const fileName = `img-${file.name.substr(0, 20)}-${fileHash(10)}`;
  const uploadFile = new File([file], fileName);

  try {
    const result = await upload(uploadFile);
    return { data: { link: result[0].url } };
  } catch {
    return { data: null };
  }
};

export const uploadImageB64 = async (imageB64) => {
  if (!imageB64) {
    return { data: '' };
  }
  try {
    const imageFile = dataUrlToFile(imageB64, 'temp-blog-img');
    return await upload_image(imageFile);
  } catch (e) {
    console.error('B64 format is invalid. Cannot convert image');
    return { data: null };
  }
};
