import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IconLike } from '../Icons';
import LikesCounter from '../LikesCounter';
import * as S from './styles';

const LikesCount = ({ likesList }) => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);

  const isLikedByUser = useCallback((likesList, loggedUserId) => {
      return likesList.some((like) => like.author.id === loggedUserId);
  }, []);

  return (
    <S.LikesCountWrapper tabIndex="0">
      <IconLike
        size="m"
        color="#616364"
        disabledHover={true}
        active={isLikedByUser(likesList, loggedUser.id)}
      />
      <LikesCounter likesList={likesList} />
    </S.LikesCountWrapper>
  );
};

LikesCount.propTypes = {
  likesList: PropTypes.array
};

LikesCount.defaultProps = {
  likesList: []
};

export default memo(LikesCount);
