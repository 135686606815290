import React from 'react';
import * as S from './styles';
import PropTypes from 'prop-types';
import LoadingCircle from '../LoadingCircle';
import FocusTrap from 'focus-trap-react';

export const CampaignModal = ({
  showModal,
  handleConfirmation,
  handleCancel,
  message,
  cancelButton,
  submitButton
}) => {
  return (
    showModal && (
      <FocusTrap
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none'
          }
        }}
      >
        <S.ModalOverlay
          role="alertdialog"
          aria-labelledby="confirmation_modal"
          aria-modal="true"
        >
          <S.Modal data-testid="confirmationModal">
            <S.ModalTitle
              data-testid="modalTitle"
              aria-label="confirmation_modal"
              tabIndex={0}
            >
              {message}
            </S.ModalTitle>
            <S.ModalButtons>
              <S.SubmitButton
                // eslint-disable-next-line jsx-a11y/no-autofocus
                value={false}
                onClick={handleCancel}
                data-testid="cancelButton"
              >
                {cancelButton}
              </S.SubmitButton>
              <S.CancelButton
                value={true}
                onClick={handleConfirmation}
                data-testid="submitButton"
              >
                {submitButton}
              </S.CancelButton>
            </S.ModalButtons>
          </S.Modal>
        </S.ModalOverlay>
      </FocusTrap>
    )
  );
};

export const Modal = ({
  showModal,
  handleConfirmation,
  message,
  cancelButton,
  submitButton
}) => {
  return (
    showModal && (
      <FocusTrap
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: 'none'
          }
        }}
      >
        <S.ModalOverlay
          role="alertdialog"
          aria-labelledby="confirmation_modal"
          aria-modal="true"
        >
          <S.Modal data-testid="confirmationModal">
            <S.ModalTitle
              data-testid="modalTitle"
              aria-label="confirmation_modal"
            >
              {message}
            </S.ModalTitle>
            <S.ModalButtons>
              <S.CancelButton
                value={false}
                onClick={handleConfirmation}
                data-testid="cancelButton"
              >
                {cancelButton}
              </S.CancelButton>
              <S.SubmitButton
                // eslint-disable-next-line jsx-a11y/no-autofocus
                value={true}
                onClick={handleConfirmation}
                data-testid="submitButton"
              >
                {submitButton}
              </S.SubmitButton>
            </S.ModalButtons>
          </S.Modal>
        </S.ModalOverlay>
      </FocusTrap>
    )
  );
};

export const ModalAction = ({
  showModal,
  handleConfirmation,
  handleCancel,
  message,
  cancelButton,
  submitButton
}) => {
  return (
    showModal && (
      <S.ModalOverlay
        role="alertdialog"
        aria-labelledby="confirmation_modal"
        aria-modal="true"
      >
        <S.Modal data-testid="confirmationModal">
          <S.ModalTitleError
            data-testid="modalTitle"
            aria-label="confirmation_modal"
          >
            {message}
          </S.ModalTitleError>
          <S.ModalButtons>
            <S.CancelButton
              value={true}
              onClick={handleCancel}
              data-testid="cancelButton"
            >
              {cancelButton}
            </S.CancelButton>
            <S.SubmitButton
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={true}
              onClick={handleConfirmation}
              data-testid="submitButton"
            >
              {submitButton}
            </S.SubmitButton>
          </S.ModalButtons>
        </S.Modal>
      </S.ModalOverlay>
    )
  );
};

export const WarningModal = ({
  handleWarningConfirmation,
  showWarning,
  warningInfo,
  textButton
}) => {
  return (
    showWarning && (
      <S.ModalOverlay
        role="alertdialog"
        aria-labelledby="warning_modal"
        aria-modal="true"
      >
        <S.Modal data-testid="warningModal">
          <S.WarningTitle data-testid="warningTitle">
            {warningInfo.title}
          </S.WarningTitle>
          <S.WarningMessage
            data-testid="warningMessage"
            aria-label="warning_modal"
          >
            {warningInfo.message}
          </S.WarningMessage>
          <S.ModalButtons>
            <S.CancelButton
              value={true}
              onClick={handleWarningConfirmation}
              data-testid="submitButton"
            >
              {textButton}
            </S.CancelButton>
          </S.ModalButtons>
        </S.Modal>
      </S.ModalOverlay>
    )
  );
};

export const LoadingModal = ({ showModal, loadingText }) => {
  return (
    showModal && (
      <S.ModalOverlay
        role="alertdialog"
        aria-labelledby="loading_modal"
        aria-modal="true"
      >
        <S.Modal aria-label="loading_modal">
          <S.LoadingWrapper>
            <LoadingCircle showLoading={true} loadingText={loadingText} />
          </S.LoadingWrapper>
        </S.Modal>
      </S.ModalOverlay>
    )
  );
};

CampaignModal.propTypes = {
  showModal: PropTypes.bool,
  handleConfirmation: PropTypes.func,
  handleCancel: PropTypes.func,
  message: PropTypes.string,
  cancelButton: PropTypes.string,
  submitButton: PropTypes.string
};

Modal.propTypes = {
  showModal: PropTypes.bool,
  handleConfirmation: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cancelButton: PropTypes.string,
  submitButton: PropTypes.string
};

ModalAction.propTypes = {
  showModal: PropTypes.bool,
  handleConfirmation: PropTypes.func,
  handleCancel: PropTypes.func,
  message: PropTypes.string,
  cancelButton: PropTypes.string,
  submitButton: PropTypes.string
};

WarningModal.defaultProps = {
  textButton: 'Alright'
};

WarningModal.propTypes = {
  showWarning: PropTypes.bool,
  handleWarningConfirmation: PropTypes.func,
  warningInfo: PropTypes.object,
  textButton: PropTypes.string
};

LoadingModal.propTypes = {
  showModal: PropTypes.bool,
  loadingText: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
