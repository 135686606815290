import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-line-gap: 7px;
  grid-template-columns: auto;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 7px;
  align-items: center;
  margin-bottom: 14px;
`;

export const DialogMessage = styled.p`
  font-size: 1.4rem;
  padding: 12px;
  height: 84px;
  width: 231px;
`;

export const Description = styled.span`
  font-size: 1.4rem;
  margin-bottom: 16px;
`;

export const InputArea = styled.div`
  width: 314px;
  height: auto;
  padding: 12px;
  border: 1px #bdbec0 solid;
  border-radius: 3px;
  margin-bottom: 4px;
`;

export const InputTag = styled.input`
  outline: none;
  border: 0;
  width: 100%;
`;

export const Tooltip = styled.div`
  position: relative;
  margin-top: -25px;
`;

export const Warn = styled.small`
  font-size: 1.2rem;
`;

export const Inform = styled.span`
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  padding-bottom: 12px;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.primaryHover};
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  
  & > svg {
    align-items: center;
    margin-right: 5px ;
    margin-top: 2px;
}
`;
