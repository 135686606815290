import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Item from './Item';
import * as S from './styles';

const CommentsList = ({
  comments,
  handleSubmitEditedComment,
  handleCancelEdit,
  handleRemove,
  handleEdit,
  showEditForm,
  loggedUser
}) => {
  const [limit, setLimit] = useState(5);

  const filterCommentsToShow = (commentsList, maxListSize) => {
    return commentsList.slice(0, maxListSize);
  };

  const seeMoreComments = () => {
    setLimit(limit + 5);
  };

  return (
    <S.List>
      {filterCommentsToShow(comments, limit).map((comment, index) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <li tabIndex={0} key={index} aria-label="comment-item">
          <Item
            comment={comment}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            showEditForm={showEditForm}
            handleSubmitEditedComment={handleSubmitEditedComment}
            handleCancelEdit={handleCancelEdit}
            loggedUser={loggedUser}
          />
        </li>
      ))}
      {limit < comments.length && (
        <S.SeeMoreCommentsLink>
          <button onClick={seeMoreComments}>See more comments</button>
        </S.SeeMoreCommentsLink>
      )}
    </S.List>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.array.isRequired,
  handleSubmitEditedComment: PropTypes.func,
  handleCancelEdit: PropTypes.func,
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func,
  showEditForm: PropTypes.func,
  loggedUser: PropTypes.object
};

export default CommentsList;
