import styled from 'styled-components';

export const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  z-index: 100000;
  max-width: 312px;
  border-radius: 4px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  box-shadow: 0px 0px 10px #bfbfbf;
  margin-top: 10px;
  margin-bottom: 12px;
`;

export const Pointer = styled.span`
  height: 0px;
  width: 0px;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
  position: absolute;
  top: -20px;
  margin-left: 161px;
`;

export const Content = styled.div`
  font-size: 1.2rem;
  padding: 14px 12px;
  border-bottom: 1px solid #e1e2e3;
`;

export const SuggestionName = styled.li`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryHover};
  margin: 8px 4px;
  & > button{
    text-decoration-line: underline;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.primaryHover};
    &:hover {
      cursor: pointer;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primaryHover};
     }
  }
`;

export const SuggestionList = styled.div`
  border-bottom: 1px solid #e1e2e3;
  padding-bottom: 14px;
  overflow: auto;
  white-space: nowrap;
  max-height: 200px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid ${({ theme }) => theme.colors.gray30};
    background-color: ${({ theme }) => theme.colors.gray30};
  }

  &::-webkit-scrollbar {
    width: 6px;
    box-shadow: nset 0 0 6px ${({ theme }) => theme.colors.gray20};
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 4px solid ${({ theme }) => theme.colors.secondary};
  }

`;

export const Message = styled.p`
  padding-top: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray70};
`;

export const NewTagMessage = styled.div`

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  margin: 8px 4px;
  text-align: center;

`;