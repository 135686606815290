import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NotFound from '../../../../components/notFound';
import LoadingCircle from '../../../components/LoadingCircle';
import { ModalAction } from '../../../components/Modals';
import Reader from '../../../components/Post/Reader';
import { getPostBySlugName, deletePost } from '../../../services/posts/service';
import { setReadPostId } from '../../../store/posts/actions';
import { forceProfileUpdate } from '../../../store/profile/actions';

const ReaderContainer = () => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const { blog_name, post_title } = useParams();
  const [requestState, setRequestState] = useState('loading');
  const [post, setPost] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = () => {
    deletePost(post.id).then((response) => {
      if (response.id === post.id) {
        dispatch(forceProfileUpdate(true));
        history.push(`/${loggedUser.username}`);
        closeDeleteModal();
      }
    });
  };

  const modalConfig = {
    showModal: false,
    message: 'Delete this post permanently?',
    cancelButton: 'Not now',
    submitButton: 'Delete'
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getPostBySlugName(blog_name, post_title).then(
      (data) => {
        setPost(data);
        dispatch(setReadPostId(data.id));
        document.title = `${data.title} - by ${data.author.name} | Blog - ThoughtWorks`;
        setRequestState('resolved');
      },
      (error) => {
        console.error('error', error);
        setRequestState('error');
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blog_name, post_title]);

  if (requestState === 'loading') {
    return <LoadingCircle showLoading={true} loadingText="Loading ..." />;
  }

  if (requestState === 'error') {
    return <NotFound />;
  }

  if (requestState === 'resolved') {
    return (
      <>
        <Reader
          post={post}
          isAllowed={post.author.id === loggedUser?.id}
          handleDelete={openDeleteModal}
        />
        <ModalAction
          data-testid={'New'}
          showModal={showDeleteModal}
          handleConfirmation={handleDelete}
          handleCancel={closeDeleteModal}
          message={modalConfig.message}
          cancelButton={modalConfig.cancelButton}
          submitButton={modalConfig.submitButton}
        />
      </>
    );
  }
};

export default ReaderContainer;
