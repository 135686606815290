import { blogApiNew } from '../../servers/blogApiNew';
import { featuredPosts } from '../../store/posts/actions';

const isFeaturedOrderMandatory = async () => {
  const data = await blogApiNew.get(
    `/posts?filters[featured_order][$null]=false`
  );
  if (data) {
    return data.length > 0;
  } else {
    return false;
  }
};

export const getFeaturedPosts = async (dispatch) => {
  const enforceOrder = await isFeaturedOrderMandatory();

  let url = `/posts?filters[is_featured]=true&pagination[limit]=5&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`;

  if (enforceOrder) {
    url += `&sort=featured_order`;
  }

  const response = await blogApiNew.get(url);

  dispatch(featuredPosts(response));
};

export const getLatestPosts = async (limit, start) => {
  return blogApiNew
    .get(
      `/posts?filters[is_featured]=false&sort=published_date:desc&pagination[limit]=${limit}&pagination[start]=${start}&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`
    )
    .then((response) => {
      if (!response.error) {
        return response;
      }
    });
};

export const getUserPosts = (limit, start, username) => {
  return blogApiNew
    .get(
      `/posts?filters[author][username]=${username}&sort=published_date:desc&pagination[limit]=${limit}&pagination[start]=${start}&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`
    )
    .then((response) => {
      if (!response.error) {
        return response;
      }
    });
};

export const getMostPopularPosts = async (limit) => {
  const response = await blogApiNew.get(
    `/posts?pagination[limit]=${limit}&count[likes]=desc&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`
  );
  return response;
};

export const getTopContributors = async (limit) => {
  const response = await blogApiNew.get(
    `/posts/top-contributors?pagination[limit]=${limit}`
  );

  return response;
};

export const getPostBySlugName = async (blogSlug, postSlug) => {
  const data = await blogApiNew.get(
    `/posts?filters[blog][slug]=${blogSlug}&filters[slug]=${postSlug}&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`
  );

  if (data.length === 0) {
    throw new Error('Could not find the post');
  }

  return data[0];
};

export const getPostsByTagName = async (tagName, start, limit) => {
  const data = await blogApiNew.get(
    `/posts?filters[tags][name]=${encodeURIComponent(
      tagName
    )}&sort=createdAt:desc&pagination[start]=${start}&pagination[limit]=${limit}&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`
  );
  return data;
};

export const publishPost = async (payload) => {
  try {
    return await blogApiNew.post('/posts', payload);
  } catch (e) {
    if (e.statusCode === 409) throw new Error('duplicatedTitle');

    throw new Error('error');
  }
};

export const updatePost = async (postID, payload) => {
  try {
    return await blogApiNew.edit('/posts', postID, payload);
  } catch (e) {
    if (e.statusCode === 409) throw new Error('duplicatedTitle');

    throw new Error('error');
  }
};

export const getByTagName = async (tagName, start, limit) => {
  const data = await blogApiNew.get(
    `/posts?filters[tags][name]=${encodeURIComponent(
      tagName
    )}&sort=createdAt:desc&pagination[start]=${start}&pagination[limit]=${limit}`
  );

  const returnError = !Array.isArray(data);
  if (returnError) throw new Error('Could not list post for this tag');

  return data;
};

export const deletePost = async (postId) => {
  const data = await blogApiNew.delete('/posts', postId);

  if (!data.id) throw new Error('Could not delete post');

  return data;
};
