import styled from "styled-components";

export const NotificationSettingsDetailsItemType = styled.div`
    grid-column-start: ${({column}) => column};
    grid-column-end: ${({column}) => column};
    grid-row-start: ${({row}) => row};
    grid-row-end: ${({row}) => row};
`;

export const NotificationSettingsTypeLabel = styled.div`
    font-size: 14px;
`;