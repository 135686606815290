import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tag from './tag';

const Tags = (props) => {
  const history = useHistory();
  const { tags, marginTop, isClickable } = props;

  const handleClick = (e) => {
    history.push({
      pathname: '/tag',
      search: `?name=${e.target.getAttribute('data-tagname')}`,
      state: { prevPath: history.location }
    });
  };

  return (
    <TagList marginTop={marginTop}>
      {tags &&
        tags.map((tag, index) => {
          return isClickable ? (
            <Tag
              key={index}
              tabIndex="0"
              text={tag.name}
              handleClick={handleClick}
            />
          ) : (
            <Tag key={index} tabIndex="0" text={tag.name} />
          );
        })}
    </TagList>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
  marginTop: PropTypes.string,
  isClickable: PropTypes.bool
};

export default Tags;

const TagList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) => props.marginTop};
`;
