import React from 'react';
import * as S from './styles';
import Dialog from '../../Dialog';
import PropTypes from 'prop-types';

const ProfilePhotoHelp = (props) => {
  const { showIcon } = props;

  const HelpButton = (
    <S.HelpButton aria-label="Help about profile photo">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9.5" cy="9.5" r="7.5" fill="white" />
        <path
          d="M9.50004 17.4166C5.12767 17.4166 1.58337 13.8724 1.58337 9.49998C1.58337 5.12761 5.12767 1.58331 9.50004 1.58331C13.8724 1.58331 17.4167 5.12761 17.4167 9.49998C17.4167 13.8724 13.8724 17.4166 9.50004 17.4166ZM9.50004 15.8333C11.1797 15.8333 12.7907 15.1661 13.9784 13.9783C15.1661 12.7906 15.8334 11.1797 15.8334 9.49998C15.8334 7.82028 15.1661 6.20937 13.9784 5.02164C12.7907 3.83391 11.1797 3.16665 9.50004 3.16665C7.82034 3.16665 6.20943 3.83391 5.0217 5.02164C3.83397 6.20937 3.16671 7.82028 3.16671 9.49998C3.16671 11.1797 3.83397 12.7906 5.0217 13.9783C6.20943 15.1661 7.82034 15.8333 9.50004 15.8333ZM8.70837 11.875H10.2917V13.4583H8.70837V11.875ZM10.2917 10.5727V11.0833H8.70837V9.89581C8.70837 9.68585 8.79178 9.48449 8.94025 9.33602C9.08872 9.18756 9.29008 9.10415 9.50004 9.10415C9.72494 9.10413 9.9452 9.04026 10.1352 8.91995C10.3252 8.79964 10.4772 8.62785 10.5734 8.42457C10.6696 8.22128 10.706 7.99486 10.6786 7.77165C10.6511 7.54844 10.5608 7.33761 10.4182 7.1637C10.2756 6.98979 10.0866 6.85995 9.87309 6.78929C9.65959 6.71863 9.4304 6.71004 9.21221 6.76454C8.99402 6.81904 8.79579 6.93437 8.64058 7.09713C8.48538 7.25988 8.37958 7.46336 8.3355 7.6839L6.78225 7.37277C6.87854 6.89151 7.1009 6.44444 7.4266 6.07728C7.7523 5.71011 8.16966 5.43602 8.63601 5.28303C9.10236 5.13003 9.60097 5.10361 10.0809 5.20647C10.5608 5.30933 11.0048 5.53778 11.3674 5.86847C11.7301 6.19915 11.9985 6.62022 12.1451 7.08861C12.2917 7.55701 12.3113 8.05594 12.2019 8.53439C12.0925 9.01285 11.858 9.45367 11.5224 9.81179C11.1868 10.1699 10.7621 10.4325 10.2917 10.5727Z"
          fill="#003D4F"
        />
      </svg>
    </S.HelpButton>
  );

  function openPage() {
    window.open('https://myaccount.google.com/');
  }

  return (
    <S.ProfilePhotoHelp>
      {showIcon ? (
        <Dialog
          trigger={HelpButton}
          boxPosition="right"
          boxDistanceX={20}
          boxDistanceY={5}
          connectorPosition="top"
          connectorDistance={12}
        >
          <S.Message>
            Your profile photo comes from your Thoughtworks Google account. If
            you want to change it, you will need to go to your{' '}
            <S.LinkAcount onClick={openPage}>
              google account settings.
            </S.LinkAcount>{' '}
            Once it is changed there, it will be updated on Central
            Blogs within the next 24 hours.
          </S.Message>
        </Dialog>
      ) : null}
    </S.ProfilePhotoHelp>
  );
};

ProfilePhotoHelp.propTypes = {
  showIcon: PropTypes.bool
};

export default ProfilePhotoHelp;
