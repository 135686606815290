import React from 'react';
import PropTypes from 'prop-types';
import { format } from '../../utils/date/formatDate';
import Avatar from '../../components/Avatar';
import * as S from './styles';
import useEventTracker from '../../hooks/useEventTracker';
import Timestamp from '../../../components/timestamp';

const Author = ({
  authorName,
  authorEmail,
  username,
  publishedAt,
  isEdited,
  showAvatar,
  sizeAvatar,
  variant
}) => {
  const sendEvent = useEventTracker();

  return (
    <S.Info>
      {showAvatar && (
        <Avatar fullName={authorName} size={sizeAvatar} email={authorEmail} />
      )}
      {variant === 'reply' ? (
        <S.AuthorName>by {authorName}</S.AuthorName>
      ) : (
        <span tabIndex={0}>
          <S.InfoAuthor
            onClick={() =>
              sendEvent(
                'Profile',
                `Click on ${username} - From ${window.location.pathname}`,
                'PostAuthor'
              )
            }
            href={`/${username}`}
            tabIndex={-1}
          >
            by {authorName}
          </S.InfoAuthor>

          {variant === 'post' ? (
            <S.InfoDate>{format(publishedAt)}</S.InfoDate>
          ) : (
            <Timestamp
              date={publishedAt}
              preText={isEdited ? 'edited -' : ''}
            />
          )}
        </span>
      )}
    </S.Info>
  );
};

Author.propTypes = {
  authorName: PropTypes.string,
  authorEmail: PropTypes.string,
  username: PropTypes.string,
  publishedAt: PropTypes.string,
  isEdited: PropTypes.bool,
  showAvatar: PropTypes.bool,
  sizeAvatar: PropTypes.string,
  variant: PropTypes.string
};

Author.defaultProps = {
  authorName: '',
  authorEmail: '',
  username: '',
  publishedAt: '',
  isEdited: false,
  showAvatar: true,
  variant: ''
};

export default Author;
