import { blogApiNew } from '../../servers/blogApiNew';

export const getBlog = async (ownerId) => {
  const data = await blogApiNew.get(`/blogs?filters[owner_id]=${ownerId}&populate=*`, false);
  return data.length === 0 ? null : data[0];
};

//This endpoint will be deprecated, this logic is implemented on strapi v4
export const createBlog = async (payload) => {
  const data = await blogApiNew.post('/blogs', payload);

  if (data?.statusCode && data?.statusCode !== 200) {
    throw new Error('Could not create the blog');
  }

  return data;
};
