import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Button = (props) => {
  const {
    label,
    size,
    variant,
    onClick,
    disabled,
    icon,
    dataTestid,
    iconColor
  } = props;

  return (
    <S.Button
      size={size}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      label={label}
      data-testid={dataTestid}
      iconColor={iconColor}
      {...props}
    >
      {icon ? icon : null}
      {label}
    </S.Button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  iconColor: PropTypes.string,
  dataTestid: PropTypes.string
};

Button.defaultProps = {
  size: 'm',
  variant: 'primary',
  disabled: false,
  icon: null,
  iconColor: null,
  dataTestid: 'button'
};

export default Button;
