import React from 'react';
import PropTypes from 'prop-types';
import ToastifyBase from '../ToastifyBase';
import { IconInfo } from '../../Icons';
import theme from '../../../styles/theme';

const ToastifyInfo = ({ title, message }) => {
  return (
    <ToastifyBase
      icon={
        <IconInfo
          size="l"
          color={theme.colors.white}
          disabledHover={true}
        />
      }
      backGroundColor="var(--toastify-color-info)"
      title={title}
      message={message}
    />
  );
};

ToastifyInfo.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired
};

ToastifyInfo.defaultProps = {
  title: 'Info!'
};

export default ToastifyInfo;
