import styled from 'styled-components';

export const Banner = styled.div`
  background: #edf1f3;
  border: 1px solid #f2617a;
  border-radius: 8px;
  padding: 11px 19px;
  max-width: 728px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 728px) {
    flex-direction: column;
    padding: 12px;
  }
`;

export const Wrapper = styled.div`
  @keyframes confetti-slow {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
      transform: translate3d(25px, 40vh, 0) rotateX(360deg) rotateY(180deg);
    }
  }

  @keyframes confetti-medium {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
      transform: translate3d(100px, 40vh, 0) rotateX(100deg) rotateY(360deg);
    }
  }

  @keyframes confetti-fast {
    0% {
      transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }

    100% {
      transform: translate3d(-50px, 40vh, 0) rotateX(10deg) rotateY(250deg);
    }
  }

  .confetti-container {
    perspective: 700px;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0%;

    &--animation-slow {
      animation: confetti-slow 2.25s linear 1 forwards;
    }

    &--animation-medium {
      animation: confetti-medium 1.75s linear 1 forwards;
    }

    &--animation-fast {
      animation: confetti-fast 1.25s linear 1 forwards;
    }
  }
`;

export const Title = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #bd4257;
`;

export const Text = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-top: 4px;
`;

export const LinkToPost = styled.a`
  background: #f2617a;
  border-radius: 4px;
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 8.5px 14px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: 728px) {
    margin-top: 20px;
  }

  & > svg {
    margin-left: 8px;
  }
`;
