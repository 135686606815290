import styled from 'styled-components';

export const ImageUpload = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  input::-webkit-file-upload-button {
    visibility: hidden;
  }

  input {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 30px;
    width: 126px;
    background: ${({theme}) => theme.colors.secondary};
    cursor: pointer;
    color: transparent;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 16px;

    &::before {
      display: flex;
      height: 30px;
      width: 126px;
      justify-content: center;
      align-items: center;
      outline: none;
      color: ${({theme}) => theme.colors.white};
      content: '${(props) => props.label}';
    }

    &:hover {
      background: ${({theme}) => theme.colors.secondaryHover};
    }

    &:disabled {
      color: ${({theme}) => theme.colors.gray70};
      background: ${({theme}) => theme.colors.gray30};
    }
  }

  p {
    margin: 0;
    color:  ${({theme}) => theme.colors.gray70};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-left: 24px;
  }
`;

export const ErrorMessage = styled.p`
  width: 434px;
  font-size: 14px;
  line-height: 21px;
  color: ${({theme}) => theme.colors.error};
`;
