import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FeaturedItem = styled.li`
  display: flex;
  position: relative;
`;

export const SkeletonTitle = styled.a`
  margin-bottom: 1.6rem;
  display: block;
  width: 100%;
`;

export const Title = styled(Link)`
  margin-bottom: 1.6rem;
  display: block;
  text-decoration: none;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  overflow-wrap: break-word;
  word-wrap: break-word;

  :hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 600px) {
    h5 {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
`;

export const WrapperSkeleton = styled.div`
  flex: none;
  display: flex;
  position: none;
`;

export const PostFeaturedImage = styled.div`
  span {
    width: 14.2rem;
    height: 11rem;
    margin-left: 2.4rem;
    border-radius: 0.4rem;
    display: flex;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
      width: 9.8rem;
      height: 9.8rem;
      margin-left: 1.2rem;
    }
  }
`;
