import React from 'react';
import notFound from '../../images/page-not-found.svg';
import * as S from './styles';

const NotFound = () => {
  return (
    <S.Page>
      <S.Wrapper>
        <div>
          <S.Title aria-label="PAGE NOT FOUND" data-testid="title">PAGE NOT FOUND</S.Title>
          <S.Subtitle aria-label="Oops! It seems like the page you were trying to find isnt around anymore (or at least isnt here).">
            {
              "Oops! It seems like the page you were trying to find isn't around anymore (or at least isn't here)."
            }
          </S.Subtitle>
          <S.Instructions>
            <h3 aria-label="What next?">What next?</h3>
            <p aria-label="Try checking the URL for errors, or hit the back button on your
              browser." data-testid="message">
              Try checking the URL for errors, or hit the back button on your
              browser.
            </p>
            <br />
            <p>
              Or you can simply start from the{' '}
              <S.Link href="/">blog homepage</S.Link> and explore the latest posts from there.
            </p>
          </S.Instructions>
        </div>
        <S.Image data-testid="image" alt="Page not found" src={notFound} />
      </S.Wrapper>
    </S.Page>
  );
};

export default NotFound;
