import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

export const Desktop = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const PostInteractions = styled.div`
  display: flex;
  justify-content: space-between;

  > :nth-child(2){
    margin-left:1.8rem;
  }
`;

export const PostActionsButtons = styled.div`
  position: relative;
  display: flex;

`;

export const EditButton = styled(Link)`
  background-color: #fff;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  position: relative;

  &:hover {
    background-color: #f4f4f4;
  }

  &[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 8px;
    position: absolute;
    left: -28px;
    top: 26px;
    white-space: nowrap;
    z-index: 20;
    background: rgb(97, 99, 100);
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    height: 14px;
    width: 34px;
    color: #fff;
    display: flex;
    justify-content: center;
  }
`;

export const DeleteButton = styled.button`
  padding: 0;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: #f4f4f4;
  }

  &[aria-label]:hover:after {
    content: attr(aria-label);
    padding: 4px 8px;
    position: absolute;
    left: -28px;
    top: 26px;
    white-space: nowrap;
    z-index: 20;
    background: rgb(97, 99, 100);
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    height: 14px;
    width: 34px;
    color: #fff;
    display: flex;
    justify-content: center;
  }
`;

export const MobileMenu = styled.ul`
  list-style: none;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgb(244, 244, 244);
  box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 0.5);
  width: 123px;
  display: block;
  padding: 10px 22px;
  position: absolute;
  right: -8px;
  top: 34px;

  &::before {
    content: '';
    border-style: solid;
    border-color: #fff transparent;
    border-width: 0px 8px 12px 8px;
    position: absolute;
    top: -8px;
    right: 0px;
    filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2));
  }
`;

export const MobileMenuItem = styled.button`
  list-style: none;
  background-color: transparent;
  height: 21px;
  display: flex;
  align-items: center;
  border: none;
  margin: 10px 0;

  & > img {
    height: 18px;
    width: 18px;
  }

  & > span {
    color: rgb(33, 34, 35);
    font-size: 14px;
    height: 21px;
    letter-spacing: 0px;
    line-height: 21px;
    margin-left: 11px;
  }
`;

export const ShowMoreButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  position: relative;
`;
