import styled from 'styled-components';

export const LinkAcount = styled.a`
  text-decoration-line: underline;
  color: ${({theme}) => theme.colors.primaryHover};

  &:hover {
    cursor: pointer;
    font-weight: 700;
  }
`;

export const ProfilePhotoHelpStyled = styled.div`
  position: relative;
  width: 0;
  height: 0;
`;

export const HelpButton = styled.button`
  position: relative;
  z-index: 2;
  right: 15px;
  top: 6px;
`;

export const HelpTooltip = styled.div`
  position: absolute;
  z-index: 3;
  background-color: #fff;
  width: 230px;
  border-radius: 4px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  box-shadow: -4px 0px 15px rgba(191, 191, 191, 0.5);
  left: 12px;
  top: 0;

  & span {
    display: block;
    height: 8.86px;
    width: 11.7px;
    background-color: inherit;
    top: 11px;
    left: -8px;
    position: absolute;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    transform: rotate(-90deg);
  }

  & p {
    font-size: 14px;
    line-height: 21px;
    color: #212223;
    margin: 12px;
  }
`;

export const Message = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #212223;
  margin: 12px;
  width: 23.7rem;
`;

export const ProfilePhotoHelp = styled.div`
  position: relative;
  top: 1.2rem;
  width: 20px;
  height: 20px;
`;
