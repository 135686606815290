import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  margin-top: 3.2rem;
  display: flex;
  align-items: center;
`;

export const PostTitle = styled.h1`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media (max-width: 600px) {
    font-size: 2.8rem;
    line-height: 4.2rem;
    margin-top: 1.6rem;
    margin-bottom: 1.2rem;
  }
`;

export const MenuAuthorButton = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  margin-left: 16px;
  position: relative;
  z-index: 2;
`;

export const MenuAuthorOptionsLink = styled(Link)`
  height: 25px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 22px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};

  :hover {
    background-color: ${({ theme }) => theme.colors.wave90};
    color: ${({ theme }) => theme.colors.secondary};
  }

  svg {
    margin-right: 10px;
  }
`;

export const MenuAuthor = styled.div`
  width: 123px;
  height: 95px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;
  box-shadow: 0px 0px 10px rgba(191, 191, 191, 0.5);
  z-index: 3;
`;

export const MenuAuthorOptions = styled.button`
  height: 25px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 22px;
  color: ${({ theme }) => theme.colors.secondary};

  :hover {
    background-color: ${({ theme }) => theme.colors.wave90};
  }

  svg {
    margin-right: 10px;
  }
`;

export const WrapperMenuAuthor = styled.div``;

export const CKEditorReadOnlyWrapper = styled.div`
  .ck-widget__selection-handle {
    display: none !important;
  }

  figure.table {
    outline: none !important;
  }

  td.ck-editor__nested-editable {
    border-right: none !important;
  }
`;
