import styled from 'styled-components';

export const Page = styled.main`
  max-width: 82.3rem;
  margin: 0 auto 60px;

  @media screen and (max-width: 900px) {
    margin: 0 16px 60px;
  }
`;

export const Text = styled.h2`
  font-size: 16px;
  padding: 30px 0px;
`;

export const PostList = styled.ul`
  width: 100%;

  & > li {
    + li {
      border-top: 1px solid #e1e2e3;
    }
  }
`;
