import { get, edit } from '../../infrastructure/client/client';

export const getUserPosts = (userId) => {
  return get(`/posts?author.id=${userId}&_sort=created_at:DESC`, '').then(
    (response) => response
  );
};

export const editBiography = (userId, body) => {
  return edit('/users', '', userId, body).then((response) => response);
};
