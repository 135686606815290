import authApi from '../../servers/authApi';
import { showToastifyError } from '../ToastifyTemplate';

const getCredentials = async () => {
  const authorizationToken = process.env.REACT_APP_STRAPI_AUTHORIZATION_TOKEN;

  const oktaToken = await authApi.getOktaToken();
  return { authorizationToken, oktaToken };
};

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    getCredentials().then(({ authorizationToken, oktaToken }) => {
      this.authorizationToken = authorizationToken;
      this.oktaToken = oktaToken;
    });
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open('POST', `${process.env.REACT_APP_BLOG_BACKEND_URL}/upload`, true);

    xhr.responseType = 'json';
  }

  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        showToastifyError({
          title: 'Image was not uploaded!',
          message:
            'Make sure your image is in .jpg, .png or .gif format and is less than 5 MB.'
        });

        return reject();
      }

      resolve({
        default: response[0].url
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file) {
    const data = new FormData();

    data.append('files', file, file.name);

    this.xhr.setRequestHeader(
      'Authorization',
      'Bearer ' + this.authorizationToken
    );
    this.xhr.setRequestHeader('Okta-Token', this.oktaToken);
    this.xhr.send(data);
  }
}

export default function UploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}
