import React from 'react';
import { useSelector } from 'react-redux';
import {
  CampaignModal,
  LoadingModal,
  Modal,
  WarningModal
} from '../../components/Modals';
import FollowList from '../../components/Modals/FollowList';

const ModalContainer = () => {
  const modalConfig = useSelector((state) => state.modal.config);

  const modal = {
    confirmation: (
      <Modal
        showModal={modalConfig.showModal}
        handleConfirmation={modalConfig.handleConfirmation}
        message={modalConfig.message}
        cancelButton={modalConfig.cancelButton}
        submitButton={modalConfig.submitButton}
      />
    ),
    error: (
      <WarningModal
        showWarning={modalConfig.showModal}
        handleWarningConfirmation={modalConfig.handleConfirmation}
        warningInfo={{ message: modalConfig.message, title: modalConfig.title }}
        textButton={modalConfig.textButton}
      />
    ),
    loading: (
      <LoadingModal
        showModal={modalConfig.showModal}
        loadingText={modalConfig.message}
      />
    ),
    campaign: (
      <CampaignModal
        showModal={modalConfig.showModal}
        handleConfirmation={modalConfig.handleConfirmation}
        handleCancel={modalConfig.handleCancel}
        message={modalConfig.message}
        cancelButton={modalConfig.cancelButton}
        submitButton={modalConfig.submitButton}
      />
    ),
    follow: (
      <FollowList
        showModal={modalConfig.showModal}
        message={modalConfig.message}
        list={modalConfig.list}
        handleCancel={modalConfig.handleCancel}
      />
    )
  };

  const buildModal = (type) => {
    return modal[type] ?? null;
  };

  return buildModal(modalConfig.type);
};

export default ModalContainer;
