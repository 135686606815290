export const getLondonTimeZone = (timeZones) => {
  const londonTimeZone = timeZones.find((timeZone) =>
    timeZone.description.includes('London')
  );
  return londonTimeZone.id;
};

export const sortTimeZoneByOffset = (timeZones) => {
  return timeZones.sort((timeZoneA, timeZoneB) => {
    return timeZoneA.offset - timeZoneB.offset;
  });
};
