import { blogApiNew } from '../../servers/blogApiNew';
import { transformToSnakeCase } from '../utils/migrationFunctions';

export const replyComment = async (commentId, authorId, text) => {
  const response = await blogApiNew.post('/replies', {
    comment: commentId,
    author: authorId,
    text
  });

  return transformToSnakeCase(response);
};

export const getRepliesByPostId = async (postId) => {
  const response = await blogApiNew.get(
    `/replies?filters[comment][post][id]=${postId}&sort=createdAt:asc`
  );

  return response.map((item) => transformToSnakeCase(item));
};

export const getRepliesFromServer = async (commentId) => {
  const response = await blogApiNew.get(
    `/replies?[comment][id]=${commentId}&sort=createdAt:asc`
  );

  return response.map((item) => transformToSnakeCase(item));
};

export const removeReply = async (replyId) => {
  const response = await blogApiNew.delete('/replies', replyId);

  return response;
};

export const editReply = async (replyId, newReplyText) => {
  const body = { text: newReplyText };
  const response = await blogApiNew.edit('/replies', replyId, body);

  return transformToSnakeCase(response);
};
