import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import * as S from './styles';

const Dialog = (props) => {
  const {
    name,
    trigger,
    boxPosition,
    boxDistanceX,
    boxDistanceY,
    connectorPosition,
    connectorDistance,
    children,
    closeDialog,
    ariaLabel,
    handleEntryDialog
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const dialogRef = useClickOutside(() => setShowDialog(false));

  useEffect(() => {
    setShowDialog(false);
  }, [closeDialog]);

  const handleDialog = () => {
    setShowDialog((state) => !state);

    if (handleEntryDialog) {
      handleEntryDialog();
    }
  };

  const getFirstParentDialog = (element) => {
    let currentParent = element.parentElement;

    const roleValue = (currentElement) =>
      currentElement.getAttribute('role') !== null
        ? currentElement.getAttribute('role')
        : '';

    while (
      roleValue(currentParent) !== 'dialog' &&
      currentParent.tagName.toLowerCase() !== 'html'
    ) {
      currentParent = currentParent.parentElement;
    }

    return currentParent;
  };

  const handleClickOnChildrenLink = (event) => {
    const myParent = getFirstParentDialog(event.target);
    if (myParent.getAttribute('data-name') === name) {
      const targetName = event.target.tagName.toLowerCase();
      if (targetName === 'a' || targetName === 'button') {
        handleDialog();
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      handleDialog();
      event.preventDefault();
    }
  };

  return (
    <S.Wrapper ref={dialogRef}>
      <S.Trigger onKeyDown={handleKeyDown} onClick={handleDialog}>
        {trigger}
      </S.Trigger>
      {showDialog ? (
        <S.DialogBox
          tabIndex={-1}
          data-name={name}
          role="dialog"
          aria-modal={true}
          boxPosition={boxPosition}
          boxDistanceX={boxDistanceX}
          boxDistanceY={boxDistanceY}
          connectorPosition={connectorPosition}
          connectorDistance={connectorDistance}
          onClick={handleClickOnChildrenLink}
          aria-label={ariaLabel}
        >
          {children}
        </S.DialogBox>
      ) : null}
    </S.Wrapper>
  );
};

Dialog.propTypes = {
  name: PropTypes.string,
  trigger: PropTypes.any,
  boxPosition: PropTypes.string,
  boxDistanceX: PropTypes.number,
  boxDistanceY: PropTypes.number,
  connectorPosition: PropTypes.string,
  connectorDistance: PropTypes.number,
  children: PropTypes.any,
  closeDialog: PropTypes.bool,
  ariaLabel: PropTypes.string,
  handleEntryDialog: PropTypes.func
};

Dialog.defaultProps = {
  boxPosition: 'bottom',
  boxDistanceX: 10,
  boxDistanceY: 20,
  connectorPosition: 'right',
  connectorDistance: 20,
  ariaLabel: ''
};

export default Dialog;
