import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  #tabs {
    display: flex;

    > li {
      margin-right: 3.6rem;
    }
  }
`;

export const PostListHeader = styled.div`
  border-bottom: 1px solid #e1e2e3;
  padding-top: 4rem;
  color: ${({ theme }) => theme.colors.secondary};

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    width: ${(props) => (props.owner ? 7.2 : 4.6)}rem;
    padding-bottom: 1.1rem;
    border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const ContainerAuthor = styled.div`
  max-width: 66.3rem;
  height: auto;
  padding-top: 3.2rem;
`;

export const ContainerPosts = styled.ul`

  display: block;
  flex: 1;

  & > li {
    margin: 0;
    padding: 0;
    border-top: solid 1px #e2e4ec;
    width: 100%;
    display: flex;

    & > div {
      flex: 1;
    }

    :first-child{
      border-top: 0px;
    }
  }
`;

