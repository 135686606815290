import styled from 'styled-components';

export const NotificationButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 2.6rem;

  @media (max-width: 600px) {
    & > div > div[role='dialog'] {
      right: -50px;

      &::before {
        right: 52px;
      }
    }
  }
`;

export const MarkAllAsReadContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const DialogContent = styled.div`
  width: 381px;
  padding-bottom: 8px;
  border-radius: 4px;

  /* Mobile */
  @media (max-width: 600px) {
    width: 90vw;
    max-height: 471px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #e1e2e3;
  height: 44px;
`;

export const Title = styled.span`
  height: 18px;
  width: 75px;
  left: 744px;
  top: 91px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 13px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const NotificationsList = styled.ul`
  overflow-y: auto;
  margin: 7px 0;
  max-height: 400px;

  > li {
    margin: 0;
    border: none;
  }

  > li:last-child {
    > a > span {
      border-bottom: solid 1px transparent;
    }
  }

  &::-webkit-scrollbar {
    width: 12.5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e1e2e3;
    border: 4px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #003d4f;
    border-radius: 5px;
    border-left: 4px solid #fff;
    border-right: 4px solid #fff;
  }
`;

export const EmptyNotification = styled.div`
  padding: 32px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.text};
`;

export const NotificationSettingsArea = styled.div`
  padding: 0;
`;

export const NotificationSettingsButton = styled.button`
  margin-right: 18px;
  margin-top: 13px;
`;
