import styled from 'styled-components';

export const FeaturedImage = styled.div`
  margin-top: 48px;
`;

export const Subtitle = styled.p`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 18px;
  width: 100%;
  color: ${({theme}) => theme.colors.black};
`;

export const FeaturedImagePreview = styled.div`
  margin-top: 18px;
  margin-bottom: 25px;

  span {
    box-sizing: border-box;
    width: 217px;
    height: 169px;
    background: #ffffff;
    border-radius: 4px;
    ${(props) => {
      if (props.featuredImageSrc) {
        return `display:flex;
      background-image: url(${props.featuredImageSrc});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      justify-content: flex-end;
      padding: 8px;
      `;
      } else {
        return 'display:none;';
      }
    }}
  }
`;

export const EditButton = styled.button`
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  width: 24px;
  height: 24px;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
`;
