import styled from 'styled-components';

export const BackGround = styled.div`
  background: ${({ theme }) => theme.colors.gray5};
  height: 100vh;
  @media (max-width: 600px) {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const Container = styled.div`
  width: 47.2rem;
  margin: 0 auto;
  padding-top: 3.2rem;
  @media (max-width: 600px) {
    padding-top: 2.0rem;
    width: 100%;
  }
`;

export const BackLinkWrapper = styled.div`
  @media (max-width: 600px) {
    *{display: none};
  }
`;
