import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { getProfilePhoto } from '../../services/photos/service';
import ContentLoader from 'react-content-loader';

const Avatar = ({ fullName, email, photo, size }) => {
  const [userPhoto, setUserPhoto] = useState('');
  const [loading, setLoading] = useState(!photo);

  useEffect(() => {
    if (email && !photo) {
      getProfilePhoto(email)
        .then((response) => {
          if (response) setUserPhoto(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const getTwoFirstLetters = (fullName) => {
    let names = fullName
      ? fullName
          .trim()
          .split(' ')
          .filter((name) => name !== '')
      : [];
    if (names.length > 2) {
      names = names.slice(0, 2);
    }
    return names
      .map((name) => name[0])
      .join('')
      .toUpperCase();
  };

  const AvatarLoader = () => (
    <ContentLoader>
      <circle cx="15" cy="15" r="56" />
    </ContentLoader>
  );

  const AvatarTwoLetters = <p>{getTwoFirstLetters(fullName)}</p>;
  const AvatarPhotoFromServer = (
    <img id="photo" src={userPhoto} alt={`${fullName}`} />
  );
  const AvatarPhotoFromProps = (
    <img id="photo" src={photo} alt={`${fullName}`} />
  );

  return (
    <S.Avatar
      size={size}
      data-testid="avatar"
      tabIndex={0}
      aria-label={`Photo from ${fullName}`}
    >
      {loading ? (
        <AvatarLoader />
      ) : photo ? (
        AvatarPhotoFromProps
      ) : userPhoto ? (
        AvatarPhotoFromServer
      ) : (
        AvatarTwoLetters
      )}
    </S.Avatar>
  );
};

Avatar.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  photo: PropTypes.string,
  size: PropTypes.string
};

Avatar.defaultProps = {
  fullName: '',
  photo: '',
  size: 'm'
};

export default Avatar;
