import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const LoadingCircle = ({ showLoading, loadingText }) => {
  return showLoading ? (
    <S.Wrapper>
      <S.CircleSpinning
        aria-label="loading circle"
        tabIndex={-1}
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.7279 5.27197L28.8999 8.09997C24.1774 3.37695 16.7762 2.6476 11.2226 6.35795C5.66896 10.0683 3.50968 17.185 6.06525 23.3557C8.62082 29.5265 15.1795 33.0327 21.7302 31.7301C28.281 30.4276 32.9994 24.679 32.9999 18H36.9999C36.9998 26.5873 30.9337 33.9787 22.5114 35.654C14.089 37.3292 5.65618 32.8217 2.37001 24.888C-0.91616 16.9543 1.85959 7.80411 8.99973 3.03329C16.1399 -1.73753 25.6558 -0.800229 31.7279 5.27197Z"
          fill="#F2617A"
        />
      </S.CircleSpinning>
      {loadingText ? (
        <S.LoadingMessage aria-label="loading"> {loadingText}</S.LoadingMessage>
      ) : null}
    </S.Wrapper>
  ) : null;
};

LoadingCircle.propTypes = {
  showLoading: PropTypes.bool,
  loadingText: PropTypes.string
};

LoadingCircle.defaultProps = {
  showLoading: false,
  loadingText: ''
};

export default LoadingCircle;
