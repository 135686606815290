import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useFeature } from '@growthbook/growthbook-react';
import { useHistory } from 'react-router-dom';
import notificationFollowIcon from '../../../../images/notification-follow.svg';
import notificationLikeIcon from '../../../../images/notification-like.svg';
import Avatar from '../../Avatar';
import Dialog from '../../Dialog';
import { IconDelete, IconMenuKebab } from '../../Icons';
import * as S from './styles';

const NotificationItem = (props) => {
  const { notificationData, onDeleteNotification, updateNotification } = props;
  const history = useHistory();

  const isNotificationClickActivated = useFeature(
    'notifications-click-activated'
  ).on;

  const { name: author, email: authorEmail } = notificationData.sender;
  const postTitle = notificationData?.post?.title;
  const type = notificationData.resourceType;
  const createdAt = notificationData.createdAt;
  const amountLiked = notificationData.amount_post_likes;
  const amountFollowed = notificationData.amount_follows;

  const deleteNotification = () => {
    onDeleteNotification(notificationData.id);
    handleOnMouseLeave();
  };

  const buildTemplateNotification = (total, message) => {
    const THE_LAST_PERSON = 1;

    const amountNotification = total - THE_LAST_PERSON;
    const checkOtherOrOthers = amountNotification === 1 ? `other` : 'others';

    return amountNotification >= 1 ? (
      <S.AmountOthers>
        and{' '}
        <b>
          {amountNotification} {checkOtherOrOthers}
        </b>{' '}
        {message}
      </S.AmountOthers>
    ) : (
      <>{message}</>
    );
  };

  const notificationMessage = {
    like: buildTemplateNotification(amountLiked, 'liked your post'),
    follow: buildTemplateNotification(amountFollowed, 'followed your blog'),
    comment: 'commented on your post',
    reply: 'replied your comment on the post',
    blogs_you_follow: 'published the post'
  };

  const handleOnMouseLeave = () => {
    document.querySelector('body').style.overflow = 'auto';
  };

  const handleOnMouseEnter = () => {
    document.querySelector('body').style.overflow = 'hidden';
  };

  const renderAvatarOfNotificationByType = () => {
    switch (type) {
      case 'like':
        return (
          <S.AvatarNotificationIcon
            src={notificationLikeIcon}
            alt="Icon Like"
            tabIndex={-1}
          />
        );
      case 'follow':
        return (
          <S.AvatarNotificationIcon
            src={notificationFollowIcon}
            alt="Icon Follow"
            tabIndex={-1}
          />
        );
      default:
        return (
          <Avatar
            tabIndex={-1}
            fullName={author}
            size="l"
            email={authorEmail}
          />
        );
    }
  };

  const kebabMenu = () => {
    return (
      <>
        <Dialog
          trigger={
            <S.MenuKebab role="button" tabIndex={0} aria-label="Options">
              <IconMenuKebab />
            </S.MenuKebab>
          }
          boxPosition="bottom"
          boxDistanceX={5}
          boxDistanceY={45}
          connectorPosition="right"
          connectorDistance={10}
          name="notificationItemContextMenu"
        >
          <S.KebabContextMenu
            tabIndex={-1}
            onMouseLeave={handleOnMouseLeave}
            onMouseEnter={handleOnMouseEnter}
          >
            <li tabIndex={-1}>
              <button
                aria-label="delete notification"
                tabIndex={0}
                onClick={deleteNotification}
              >
                <IconDelete size="m" color="#4D7784" disabledHover={true} />
                Delete
              </button>
            </li>
          </S.KebabContextMenu>
        </Dialog>
      </>
    );
  };

  const preventScrollFreezing = () => {
    document.querySelector('body').style.overflow = 'auto';
  };

  const handleUpdateNotification = async () => {
    await updateNotification(notificationData);

    if (isNotificationClickActivated) {
      if (type === 'follow') {
        notificationRouterActionFollow();
      } else {
        notificationRouterActionPostDependent();
      }

      preventScrollFreezing();
    }
  };

  const getBlogSlug = (post) => {
    if (post && post.author) {
      return post.author.blog.slug;
    }
  };

  const notificationRouterActionFollow = () => {
    history.push({
      pathname: `/${notificationData.receiver.username}`,
      from: 'follower-notification'
    });
  };

  const notificationRouterActionPostDependent = () => {
    const url = `${getBlogSlug(notificationData?.post)}/${
      notificationData.post?.slug
    }`;

    if (!history.location.pathname.toString().includes(url)) {
      history.push({ pathname: `/${url}` });
    }
  };

  const buildNotificationsTabFlowByStatus = () => {
    const baseTabFlowToReadNotification =
      'notificationDescription notificationDate';

    return notificationData.status == 'unread'
      ? `unreadBadge ${baseTabFlowToReadNotification}`
      : baseTabFlowToReadNotification;
  };

  const renderUnreadBadge = () => {
    if (notificationData.status == 'unread') {
      return (
        <S.UnreadBadge id="unreadBadge" aria-label="unread notification" />
      );
    }
  };

  return (
    <S.NotificationItem
      tabIndex={0}
      aria-labelledby={buildNotificationsTabFlowByStatus()}
    >
      <S.MainContent>
        <S.ClickabledArea
          id="notificationDescription"
          onClick={handleUpdateNotification}
        >
          {renderAvatarOfNotificationByType()}
          <S.NotificationText>
            {author} <span>{notificationMessage[type]}</span> {postTitle}
          </S.NotificationText>
        </S.ClickabledArea>

        {kebabMenu()}
      </S.MainContent>
      <S.StatusArea>
        <S.Time id="notificationDate">
          {`${moment.utc(createdAt).fromNow()}`.trim()}
        </S.Time>

        {renderUnreadBadge()}
      </S.StatusArea>
      <S.SeparatorLine />
    </S.NotificationItem>
  );
};

NotificationItem.propTypes = {
  notificationData: PropTypes.object.isRequired,
  onDeleteNotification: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired
};

export default NotificationItem;
