import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect, useState } from 'react';
import { getFeatures } from '../../services/featureToggle';

export const FeatureToggleContainer = ({ children }) => {
  const [growthbookInstance, setGrowthbookInstance] = useState(new GrowthBook());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_GROWTH_BOOK_API_KEY) {
      getFeatures().then((response) => {
        const features = response.features;
        setGrowthbookInstance((state) => {
          state.setFeatures(features);
          return state;
        });

        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <GrowthBookProvider growthbook={growthbookInstance}>{children}</GrowthBookProvider>
  );
};
