import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconClose } from '../Icons';
import * as S from './styles';

const Tag = ({ label, handleRemoveTag }) => {
  const history = useHistory();

  const handleClick = (e) => {
    history.push({
      pathname: '/tag',
      search: `?name=${e.target.getAttribute('name')}`,
      state: { prevPath: history.location }
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      handleClick(event);
    }
  };

  return (
    <S.Wrapper
      tabIndex={0}
      role="button"
      name={label}
      onClick={handleRemoveTag ? null : handleClick}
      onKeyDown={handleRemoveTag ? null : handleKeyDown}
    >
      {label}
      {handleRemoveTag && (
        <S.RemoveTag
          type="button"
          aria-label="remove tag"
          onClick={() => handleRemoveTag(label)}
        >
          <IconClose size="ss" color="#003D4F" disabledHover={true} />
        </S.RemoveTag>
      )}
    </S.Wrapper>
  );
};

export default Tag;

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  handleRemoveTag: PropTypes.func
};
