import React from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import AuthorSkeleton from '../Author/skeleton';
import LikesCountSkeleton from '../LikesCount/skeleton';


const FeaturedPostSkeleton = () => {
  return (
    <S.FeaturedPostSection>
      <S.FeaturedPostImage>
        <Skeleton square height={'100%'} width={'100%'} />
      </S.FeaturedPostImage>
      <S.SkeletonTitle>
        <Skeleton  style={{lineHeight: '4.2rem'}}/>
      </S.SkeletonTitle>
      <S.Summary>
        <Skeleton count={2} height={12} width={'100%'} />
      </S.Summary>
      <S.Wrapper>
        <AuthorSkeleton />
        <LikesCountSkeleton />
      </S.Wrapper>
    </S.FeaturedPostSection>
  );
};

export default FeaturedPostSkeleton;
