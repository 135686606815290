import styled from 'styled-components';

export const ButtonPink = styled.button`
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 30px;
  width: 60px;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 16px;

  ${(props) =>
    props.size === 'md'
      ? `height: 35px;
        width: 129px;
        font-size: 14px;`
      : ``}

  &:hover {
    background: #b11c54;
  }

  &:disabled {
    color: #616364;
    background: #e1e2e3;
  }
`;

export const ButtonWhiteLink = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #fff;
  height: 30px;
  width: 60px;
  background: #fff;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryDark};
  font-size: 12px;
  font-weight: 'regular';
  letter-spacing: 0px;
  text-align: center;
  text-decoration: underline;

  &:hover {
    font-weight: 700;
  }

  ${(props) =>
    props.size === 'md'
      ? `width: 49px;
        height: 19px;
        font-size: 14px;`
      : ``}
`;
