import React from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AuthorSkeleton from '../Author/skeleton';
import LikesCountSkeleton from '../LikesCount/skeleton';

const PostSummarySkeleton = () => {
  return (
    <S.PostSummarySkeleton data-testid="post-summary">
      <div>
        <S.PostSummaryContainerSkeleton>
          <S.PostSummaryTitleSkeleton>
            <Skeleton height={30} />

            <S.PostSummaryContent>
              <Skeleton height={10} />
              <Skeleton height={10} />
              <Skeleton height={10} />
            </S.PostSummaryContent>
          </S.PostSummaryTitleSkeleton>

          <S.PostFeaturedImage>
            <Skeleton square />
          </S.PostFeaturedImage>
        </S.PostSummaryContainerSkeleton>

        <S.WrapperSkeleton>
          <AuthorSkeleton />
          <LikesCountSkeleton />
        </S.WrapperSkeleton>
      </div>
    </S.PostSummarySkeleton>
  );
};

export default PostSummarySkeleton;
