import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconUp, IconDown, IconSearch } from '../Icons';
import theme from '../../styles/theme';
import * as S from './styles';

const DropDown = (props) => {
  const { itens, selectedItem, showSearchBox, onChange, ariaLabel } = props;

  const [internalItens, setInternalItens] = useState(itens);
  const [isDropDownListOpen, setDropDownListOpen] = useState(false);
  const [selected, setSelected] = useState(
    itens.find((item) => item.id === selectedItem)
  );

  const handlerToggleDropDownList = () => {
    if (!isDropDownListOpen) {
      setTimeout(() => {
        window.addEventListener('click', handlerForceCloseDropDownList);
      }, 0);
    }

    setDropDownListOpen((actualValue) => !actualValue);
  };

  const handlerForceCloseDropDownList = () => {
    setDropDownListOpen(false);
    setTimeout(() => {
      window.removeEventListener('click', handlerForceCloseDropDownList);
    }, 0);
  };

  const handlerSetSelected = (item) => {
    setSelected(item);
    handlerToggleDropDownList();
    if (onChange !== undefined) {
      onChange(item);
    }
  };

  const handlerSearchFieldKeydown = (e) => {
    const filteredItens = itens.filter((item) =>
      item.text.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setInternalItens(filteredItens);
  };

  const handlerKeyDown = (e) => {
    if (e.keyCode === 32) {
      const mouseClickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      e.target.dispatchEvent(mouseClickEvent);
    }
  };

  const handlerKeyDownDropDownItem = (e) => {
    const nextElement = e.target.nextElementSibling;
    const prevElement = e.target.previousElementSibling;
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        if (nextElement) {
          nextElement.focus();
        }
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        if (prevElement) {
          prevElement.focus();
        }
        break;
      }
      case 'Enter': {
        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });
        e.target.dispatchEvent(clickEvent);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <S.Wrapper>
      <S.DropDownHeader
        role="list"
        onClick={handlerToggleDropDownList}
        tabIndex="0"
        onKeyDown={handlerKeyDown}
        aria-labelledby={ariaLabel}
        aria-label="test"
      >
        <S.DropDownHeaderText>
          {selected !== undefined ? selected.text : ''}
        </S.DropDownHeaderText>
        {isDropDownListOpen ? (
          <IconUp
            size="l"
            color={theme.colors.secondary}
            disabledHover={true}
          />
        ) : (
          <IconDown
            size="l"
            color={theme.colors.secondary}
            disabledHover={true}
          />
        )}
      </S.DropDownHeader>
      {isDropDownListOpen && (
        <S.DropDownList role="list">
          {showSearchBox && (
            <S.DropDownListSearch>
              <IconSearch
                size="s"
                color={theme.colors.secondary}
                disabledHover={true}
              />
              <S.DropDownListSearchField
                data-testid="search-input"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handlerSearchFieldKeydown(e)}
                placeholder="Search"
                aria-label="search-field"
                role="textbox"
              />
            </S.DropDownListSearch>
          )}
          <S.WrapperDropDownListItens>
            <S.DropDownListItens>
              {internalItens.map((item) => (
                <S.DropDownListItem
                  tabIndex="0"
                  key={item.id}
                  onClick={() => handlerSetSelected(item)}
                  onKeyDown={handlerKeyDownDropDownItem}
                  aria-label={item.text}
                  aria-labelledby={item.text}
                >
                  {item.text}
                </S.DropDownListItem>
              ))}
            </S.DropDownListItens>
          </S.WrapperDropDownListItens>
        </S.DropDownList>
      )}
    </S.Wrapper>
  );
};

DropDown.propTypes = {
  onChange: PropTypes.func,
  itens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  showSearchBox: PropTypes.bool,
  selectedItem: PropTypes.number,
  ariaLabel: PropTypes.string
};

DropDown.defaultProps = {
  showSearchBox: true,
  ariaLabel: ''
};

export default DropDown;
