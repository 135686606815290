import styled from 'styled-components';
import TextArea from '../TextArea';

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 1.9rem;
`;

export const WrapperAvatar = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Username = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
    margin-right: 2rem;

  @media (max-width: 600px) {
    font-size: 2.2rem;
    margin-right: 1rem;
  }
`;

export const Biography = styled(TextArea)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 20px 0px;
  width: 66%;
  resize: none;
  border: none;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MobileMenu = styled.ul`
  list-style: none;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid rgb(244, 244, 244);
  box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 0.5);
  width: 123px;
  padding: 10px 22px;
  position: absolute;
  right: 7px;
  top: 162px;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }

  &::before {
    content: '';
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.white} transparent;
    border-width: 0px 8px 12px 8px;
    position: absolute;
    top: -8px;
    right: 12px;
    filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2));
  }
`;

export const MobileMenuItem = styled.button`
  list-style: none;
  background-color: transparent;
  height: 21px;
  display: flex;
  align-items: center;
  border: none;
  margin: 10px 0;

  & > img {
    height: 18px;
    width: 18px;
  }

  & > span {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 14px;
    height: 21px;
    letter-spacing: 0px;
    line-height: 21px;
    margin-left: 11px;
  }
`;

export const ShowMoreButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  position: relative;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;
