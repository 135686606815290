import { SEARCH_POSTS, SEARCHING, CLEAN_SEARCH_POSTS, SEARCH_TAGS, SEARCH_AUTHORS, HAS_MORE_POSTS, HAS_MORE_AUTHORS } from '../actionTypes';

export const searchPosts = (value) => ({
  type: SEARCH_POSTS,
  payload: value
});

export const searchTags = (value) => ({
  type: SEARCH_TAGS,
  payload: value
});

export const searchAuthors = (value) => ({
  type: SEARCH_AUTHORS,
  payload: value
});

export const searching = (value) => ({
  type: SEARCHING,
  payload: value
});

export const cleanSearch = () => ({
  type: CLEAN_SEARCH_POSTS
});

export const hasMorePosts = (value) => ({
  type: HAS_MORE_POSTS,
  payload: value
});

export const hasMoreAuthors = (value) => ({
  type: HAS_MORE_AUTHORS,
  payload: value
});
