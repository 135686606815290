import { useOktaAuth } from '@okta/okta-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logged from '../../../components/logged';
import btnClose from '../../../images/button_close.svg';
import newTwLogo from '../../../images/new-tw-logo.svg';
import NotificationContainer from '../../containers/NotificationContainer';
import SearchField from '../SearchField';
import * as S from './styles';

const Header = () => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const { authState } = useOktaAuth();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const isHome = (pathname) => {
    const path = '/' + window.location.href.split('/').slice(-1)[0];
    return path === pathname;
  };

  const handleOpenMobileMenu = () => {
    openMobileMenu ? setOpenMobileMenu(false) : setOpenMobileMenu(true);
  };

  if (!authState?.isAuthenticated) {
    return null;
  }

  return (
    <>
      <S.HeaderContainer>
        <S.Container
          id={'logo-tw'}
          aria-label={'thoughtworks logo: click to access the home page'}
        >
          <S.Logo href="/">
            <img
              src={newTwLogo}
              alt={'thoughtworks logo'}
              aria-labelledby={'logo-tw'}
            />
          </S.Logo>

          <S.Navbar>
            <ul role={'menubar'}>
              <S.WrapperMenu
                role={'list'}
                tabIndex={0}
                aria-label={'kick menu'}
              >
                <li role={'menuitem'}>
                  <S.NavLinkHome role={'button'} isActive={isHome('/')}>
                    <Link to="/">Home</Link>
                  </S.NavLinkHome>
                </li>

                <li role="menuitem">
                  <S.NavLink href="https://central.thoughtworks.net/home/">
                    TW Central
                  </S.NavLink>
                </li>

                <li role="menuitem">
                  <S.NavLink href="https://central.thoughtworks.net/home/feedback-blog">
                    Feedback
                  </S.NavLink>
                </li>
              </S.WrapperMenu>

              <S.WrapperActions>
                <SearchField />
                <NotificationContainer />
                <Logged />
              </S.WrapperActions>

              <S.NotificationMobile data-testid="notificationMobile">
                <NotificationContainer />
              </S.NotificationMobile>

              <li id="openMobileMenu">
                <button
                  data-testid="mobileMenuBtn"
                  aria-label="openMobileMenu"
                  id="js-navbar-toggle"
                  onClick={handleOpenMobileMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
                  </svg>
                </button>
              </li>
            </ul>
          </S.Navbar>
        </S.Container>
      </S.HeaderContainer>

      {openMobileMenu && (
        <S.MobileMenu>
          <S.MobileModalHeader>
            {/* eslint-disable-next-line */}
            <S.MobileCloseLink alt="close" onClick={handleOpenMobileMenu}>
              Close
              {/* eslint-disable-next-line */}
              <img alt="close" src={btnClose} />
            </S.MobileCloseLink>
          </S.MobileModalHeader>
          <Logged showNameOnAvatar={true} />
          <S.MobileMenuList>
            <li>
              <a href={`/${loggedUser.username}`}>My profile</a>
            </li>
            <S.MobileSeparator />
            <li>
              <a href="/" data-testid="mobileHome">
                Home
              </a>
            </li>
            <li>
              <a
                href="https://central.thoughtworks.net/home/"
                data-testid="mobileAccessIntranet"
              >
                TW Central
              </a>
            </li>
            <li>
              <a
                href="https://central.thoughtworks.net/home/feedback-blog"
                data-testid="mobileGiveFeedback"
              >
                Give Feedback
              </a>
            </li>
          </S.MobileMenuList>
        </S.MobileMenu>
      )}
    </>
  );
};

export default Header;
