import {
  LIST_FEATURED_POSTS,
  GET_LATEST_POSTS,
  GET_USER_POSTS,
  CLEAN_USER_POSTS,
  ADD_LIKE_LATEST_POSTS,
  ADD_LIKE_FEATURED_POSTS,
  REMOVE_LIKE_LATEST_POSTS,
  REMOVE_LIKE_FEATURED_POSTS, 
  SET_READ_POST_ID
} from '../actionTypes';

export const featuredPosts = (value) => ({
  type: LIST_FEATURED_POSTS,
  featuredPosts: value
});

export const latestPosts = (value) => ({
  type: GET_LATEST_POSTS,
  latestPosts: value
});

export const userPosts = (value) => ({
  type: GET_USER_POSTS,
  payload: value
});

export const cleanUserPosts = (value) => ({
  type: CLEAN_USER_POSTS,
  userPosts: value
});

export const addLikeLatestPost = (value) => ({
  type: ADD_LIKE_LATEST_POSTS,
  payload: value
});

export const addLikeFeaturedPost = (value) => ({
  type: ADD_LIKE_FEATURED_POSTS,
  payload: value
});

export const removeLikeLatestPost = (value) => ({
  type: REMOVE_LIKE_LATEST_POSTS,
  payload: value
});

export const removeLikeFeaturedPost = (value) => ({
  type: REMOVE_LIKE_FEATURED_POSTS,
  payload: value
});

export const setReadPostId = (value) => ({
  type: SET_READ_POST_ID,
  payload: value
});
