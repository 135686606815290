import React from 'react';
import PropTypes from 'prop-types';
import Author from '../../../Author';
import * as S from './styles';

const Header = ({loggedUser}) => {
  return (
    <S.ReplyFormHeaderStyled>
      <Author
        authorName={loggedUser.name}
        authorEmail={loggedUser.email}
        username={loggedUser.username}
        variant='reply'/>
    </S.ReplyFormHeaderStyled>
  );
};

Header.propTypes = {
  loggedUser: PropTypes.object
};

export default Header;
