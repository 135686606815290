import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import FeaturedItem from './FeaturedItem';

const FeaturedList = ({ postList }) => (
  <S.FeaturedListWrapper>
    {postList.map((post, index) => (
      <FeaturedItem
        key={post.id}
        featuredImageUrl={post.featured_image_url}
        featuredImageAlt={post.featured_image_alt}
        postTitle={post.title}
        authorName={post.author.name}
        authorEmail={post.author.email}
        username={post.author.username}
        publishedAt={post.published_date}
        postUrl={`/${post.blog.slug}/${post.slug}`}
        position={index}
      />
    ))}
  </S.FeaturedListWrapper>
);

FeaturedList.propTypes = {
  postList: PropTypes.array
};

export default FeaturedList;
