import authApi from '../../2.0/servers/authApi';

export const get = async (url, access_token) => {
  const response = await fetch(process.env.REACT_APP_PLATFORM_URL + url, {
    method: 'GET',
    headers: buildHeader(access_token)
  });
  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(response.statusText);
  }
};

export const post = (url, access_token, data) => {
  return fetch(process.env.REACT_APP_PLATFORM_URL + url, {
    method: 'POST',
    headers: buildHeader(access_token),
    body: JSON.stringify(data)
  })
    .then((response) => response.json())
    .then((data) => data);
};

export const remove = (url, access_token, entityId) => {
  return fetch(process.env.REACT_APP_PLATFORM_URL + url + `/${entityId}`, {
    method: 'DELETE',
    headers: buildHeader(access_token)
  })
    .then((response) => response.json())
    .then((data) => data);
};

export const edit = (url, access_token, entityId, body) => {
  return fetch(process.env.REACT_APP_PLATFORM_URL + url + `/${entityId}`, {
    method: 'PUT',
    headers: buildHeader(access_token),
    body: JSON.stringify(body)
  })
    .then((response) => response.json())
    .then((data) => data);
};

export const count = (url, access_token, filter = '') => {
  return fetch(process.env.REACT_APP_PLATFORM_URL + url + `/count` + filter, {
    method: 'GET',
    headers: buildHeader(access_token)
  })
    .then((response) => response.json())
    .then((data) => data);
};

const buildHeader = (
  access_token,
  rss = false,
  contentType = 'application/json'
) => {
  return {
    'Content-Type': contentType,
    Authorization: 'Bearer ' + access_token,
    'Okta-Token': authApi.getOktaToken(),
    rss: rss
  };
};
