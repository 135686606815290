import React from 'react';
import PropTypes from 'prop-types';
import ToastifyBase from '../ToastifyBase';
import { IconError } from '../../Icons';
import theme from '../../../styles/theme';

const ToastifyError = ({ title, message }) => {
  return (
    <ToastifyBase
      icon={
        <IconError
          size="m"
          color={theme.colors.white}
          disabledHover={true}
        />
      }
      backGroundColor="var(--toastify-color-error)"
      title={title}
      message={message}
    />
  );
};

ToastifyError.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

ToastifyError.defaultProps = {
  title: 'Something went wrong!',
  message: 'Sorry! Please reload the page and try again.'
};

export default ToastifyError;
