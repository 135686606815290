import React, { useEffect } from 'react';
import LatestPosts from '../../components/LatestPosts';
import LatestPostsSkeleton from '../../components/LatestPosts/skeleton';

import { getLatestPosts } from '../../services/posts/service';
import LoadingDots from '../../components/LoadingDots';
import * as S from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux';
import MessageScroll from '../../components/MessageScroll';
import { hasMorePosts } from '../../store/search/actions';
import { latestPosts } from '../../store/posts/actions';

const LatestPostsContainer = () => {
  const latestPostsList = useSelector((state) => state.post.latestPosts);
  const morePosts = useSelector((state) => state.search.hasMorePosts);
  const LIMIT_POSTS_PER_REQUEST = 6;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPostsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPostsList = async () => {
    const paginationStart = latestPostsList.length;

    if (morePosts) {
      const response = await getLatestPosts(
        LIMIT_POSTS_PER_REQUEST,
        paginationStart
      );

      if(response.length === 0) dispatch(hasMorePosts(false));
      dispatch(latestPosts(response));

      return [...latestPostsList, ...response];
    }
  };

  return (
    <S.Wrapper>
      {latestPostsList.length >= 1 ? (
        <InfiniteScroll
          dataLength={latestPostsList.length}
          next={fetchPostsList}
          hasMore={morePosts}
          loader={
            latestPostsList.length < 6 ? null : (
              <LoadingDots showLoading={true} />
            )
          }
          endMessage={<MessageScroll message={'Yay! You have seen it all'} />}
        >
          <LatestPosts postList={latestPostsList} />
        </InfiniteScroll>
      ) : (
        <LatestPostsSkeleton />
      )}
    </S.Wrapper>
  );
};

export default LatestPostsContainer;
