const matomoSiteIdBasedOnEnv = {
  'non-prod': '123',
  production: '124'
};

const matomoCointainerIdBasedOnEnv = {
  'non-prod': 'G33VuSHW',
  production: 'XMdv2X73'
};

const getEnvType = () =>
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_ENVIRONMENT
    : 'non-prod';

const getMatomoSiteId = () => {
  const envType = getEnvType();
  return matomoSiteIdBasedOnEnv[envType];
};

const getMatomoContainerId = () => {
  const envType = getEnvType();
  return matomoCointainerIdBasedOnEnv[envType];
};

const trackingUrl = 'https://thoughtworks.innocraft.cloud/';
const containerUrl = `https://thoughtworks.innocraft.cloud/js/container_${getMatomoContainerId()}.js`;

function preContainerSetup() {
  const _mtm = (global._mtm = global._mtm || []);
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
}

function addContainerScript() {
  const scriptElement = document.createElement('script');
  const firstScriptElement = document.getElementsByTagName('script')[0];

  preContainerSetup();

  scriptElement.src = containerUrl;
  scriptElement.async = true;
  firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
}

const removeMatomo = () => {
  const matomoScripts = document.querySelectorAll(
    '[src^="https://thoughtworks.innocraft.cloud"]'
  );

  if (matomoScripts.length > 0) {
    matomoScripts.forEach((scriptElement) => {
      scriptElement.parentNode.removeChild(scriptElement);
    });
  }
};

function trackingSetup() {
  const siteId = getMatomoSiteId();
  const _paq = (global._paq = []);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setTrackerUrl', trackingUrl + 'matomo.php']);
  _paq.push(['setSiteId', siteId]);
  window.addEventListener('popstate', function () {
    const _paq = (global._paq = global._paq || []);
    const path = document.location.pathname.replace('/', '');
    _paq.push(['setCustomUrl', '/' + path]);
    _paq.push(['setDocumentTitle', path]);
  });
}

const addMatomo = () => {
  trackingSetup();
  addContainerScript();
};

export const setupMatomo = () => {
  removeMatomo();
  addMatomo();
};
