import React from 'react';
import * as S from './styles';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LikesCountSkeleton = () => {
  return (
    <S.LikesCountWrapper>
      <S.LikesCountWrapperElementSkeleton>
        <Skeleton square height={20} width={18} />
      </S.LikesCountWrapperElementSkeleton>
      <S.LikesCountWrapperElementSkeleton>
        <Skeleton square height={20} width={18} />
      </S.LikesCountWrapperElementSkeleton>
    </S.LikesCountWrapper>
  );
};

export default LikesCountSkeleton;
