import { SHOW_MODAL, CLOSE_MODAL } from '../actionTypes';

export const showModal = (value) => ({
  type: SHOW_MODAL,
  payload: value
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});
