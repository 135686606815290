import styled from 'styled-components';

export const Header = styled.header`
  position: fixed;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 98px;
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  width: 100%;
  max-width: 1110px;
  margin: 0 auto;

  /* Tablet */
  @media (min-width: 601px) and (max-width: 1279px) {
    max-width: 80%;
    margin: 0 10%;
  }

  /* Mobile */
  @media (max-width: 600px) {
    max-width: 288px;
    margin: 0 auto;
  }
`;

export const Separator = styled.span`
  border-right: 1px solid ${({ theme }) => theme.colors.gray40};
  width: 0.1rem;
  height: 2.1rem;
  margin: 0 12px;
`;

export const ModeSelectorWrapper = styled.div`
  width: 250px;
  display: flex;
  align-items: center;

  > button {
    width: 45%;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ModeSelectorButton = styled.button`
  padding: 0.8rem 0;
  background: transparent;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  border: none;
  outline: none;

  ${({ isActive, theme }) =>
    isActive === true
      ? `
          color: ${theme.colors.primaryHover};
          border-bottom: 2px solid ${theme.colors.primaryHover};
          font-weight: 700;
        `
      : `color: : ${theme.colors.black};
        `}
`;
