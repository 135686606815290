import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const ListItem = ({
  showMessage,
  message,
  children,
  itemId,
  listId,
}) => (
  <S.Wrapper>
    {showMessage ? <S.Title>{message}</S.Title> : null}
    <S.List id={listId}>
      {children.map((item, index) => {
        return (
          <li data-testid={itemId} key={index}>
            {item}
          </li>
        );
      })}
    </S.List>
  </S.Wrapper>
);

ListItem.propTypes = {
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.any,
  listId: PropTypes.string,
  itemId: PropTypes.string
};

export default ListItem;
