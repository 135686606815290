import styled from 'styled-components';

export const TextArea = styled.textarea`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #c0c4d7;
  height: 91px;
  width: -webkit-fill-available;
  width: 100%;
  color: #808285;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 28px;
  padding: 8px;
  resize: none;
  margin-top: 8px;
  resize: none;
  color: #000;

  &::placeholder {
    margin: 0;
    padding: 0;
    color: #6d7278;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;

  & > button {
    cursor: pointer;
  }
`;
