import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.4rem;
  border: 1px solid transparent;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
    & > svg > path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  & > svg {
    margin-right: 0.8rem;

    & > path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ size }) => {
    switch (size) {
      case 's':
        return css`
          padding: 0.7rem 1.2rem;
          font-size: 1.2rem;

          & > svg {
            width: 16px;
            height: 16px;
          }
        `;
      case 'l':
        return css`
          padding: 0.9rem 1.8rem;
          font-size: 1.8rem;

          & > svg {
            width: 24px;
            height: 24px;
          }
        `;
      default:
        return css`
          padding: 0.8rem 1.4rem;
          font-size: 1.4rem;
          align-self: center;
          & > svg {
            width: 20px;
            height: 20px;
          }
        `;
    }
  }}

  ${({ disabled, variant }) => {
    if (disabled) {
      switch (variant) {
        case 'outlined':
          return css`
            background-color: transparent;
            border: 1px solid ${({ theme }) => theme.colors.gray40};
            color: ${({ theme }) => theme.colors.gray70};
            cursor: not-allowed;
            & > svg > path {
              fill: ${({ theme }) => theme.colors.gray70};
            }

            &:hover {
              background-color: ${({ theme }) => theme.colors.white};
              color: ${({ theme }) => theme.colors.gray70};

              & > svg > path {
                fill: ${({ theme }) => theme.colors.gray70};
              }
            }
          `;
        case 'link':
          return css`
            background-color: transparent;
            border: 1px solid transparent;
            color: ${({ theme }) => theme.colors.gray70};
            font-weight: 400;
            text-decoration: underline;
            cursor: not-allowed;
            & > svg > path {
              fill: ${({ theme }) => theme.colors.gray70};
            }
            &:hover {
              color: ${({ theme }) => theme.colors.gray70};
              background-color: transparent;
              & > svg > path {
                fill: ${({ theme }) => theme.colors.gray70};
              }
            }
          `;
        default:
          return css`
            background-color: ${({ theme }) => theme.colors.gray40};
            color: ${({ theme }) => theme.colors.gray70};
            cursor: not-allowed;
            & > svg > path {
              fill: ${({ theme }) => theme.colors.gray70};
            }
            &:hover {
              background-color: ${({ theme }) => theme.colors.gray40};
              & > svg > path {
                fill: ${({ theme }) => theme.colors.gray70};
              }
            }
          `;
      }
    } else {
      switch (variant) {
        case 'secondary':
          return css`
            background-color: ${({ theme }) => theme.colors.secondary};
            &:hover {
              background-color: ${({ theme }) => theme.colors.secondaryHover};
            }
          `;
        case 'outlined':
          return css`
            background-color: transparent;
            border: 1px solid ${({ theme }) => theme.colors.primaryHover};
            color: ${({ theme }) => theme.colors.primaryHover};
            & > svg > path {
              fill: ${({ theme }) => theme.colors.primaryHover};
            }
            &:hover {
              background-color: ${({ theme }) => theme.colors.primaryHover};
              color: ${({ theme }) => theme.colors.white};
              & > svg > path {
                fill: ${({ theme }) => theme.colors.white};
              }
            }
          `;
        case 'link':
          return css`
            background-color: transparent;
            border: 1px solid transparent;
            color: ${({ theme }) => theme.colors.primaryHover};
            font-weight: 400;
            text-decoration: underline;

            &::after {
              content: '${({ label }) => label}';
              color: transparent;
              font-weight: 700;
              position: absolute;
              text-decoration: underline;
            }

            & > svg > path {
              fill: ${(props) =>
                props.iconColor
                  ? props.iconColor
                  : ({ theme }) => theme.colors.primaryHover};
            }

            &:hover {
              background-color: transparent;
              color: transparent;
              &::after {
                color: ${({ theme }) => theme.colors.primaryHover};
              }
              & > svg > path {
                fill: ${({ theme }) => theme.colors.primaryHover};
              }
            }
          `;
        default:
          return css`
            background-color: ${({ theme }) => theme.colors.primary};
          `;
      }
    }
  }}
`;
