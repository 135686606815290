/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../Dialog/index';
import { IconInfo } from '../../Icons';
import PostTags from '../../PostTags/index';
import * as S from './styles';
import {
  isRepeated,
  removeInvalidCharacters,
  hasAnInvalidSize,
  hasPercentChar
} from './functions';
import SuggestionList from './SuggestionList';

const TagFormAutocomplete = (props) => {
  const { tags, setTags, handleTagsAutocomplete } = props;
  const [error, setError] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const lastPromise = useRef();

  const addTag = (tagName) => {
    if (!isRepeated(tagName, tags)) {
      setTags([...tags, { name: tagName.toLowerCase() }]);
    }
    setSuggestionList([]);
    setInputValue('');
    setShowSuggestions(false);
  };

  const removeLastTag = () => {
    const lastTagElement = tags[tags.length - 1].name;
    removeTag(lastTagElement);
  };

  const handleKeyUp = async (e) => {
    const typedValue = e.target.value;
    const validTag = removeInvalidCharacters(typedValue);
    const validatedTag = isValidTag(validTag);

    const actionToSubmit = e.key === 'Enter' || e.key === ',';
    if (actionToSubmit && validatedTag) {
      addTag(validTag);
      e.target.value = '';
    }
  };

  const handleKeyDown = (e) => {
    const typedValue = e.target.value;
    const validTag = removeInvalidCharacters(typedValue);

    const isEmptyTag = validTag === '';
    const actionToDelete = e.key === 'Backspace' || e.key === 'Delete';

    if (isEmptyTag && actionToDelete) {
      removeLastTag();
    }
  };

  const removeTag = (name) => {
    setTags(tags.filter((tag) => tag.name !== name));
  };

  const isValidTag = (tag) => {
    setError('');

    if (hasAnInvalidSize(tag)) {
      setError('A tag name must be less then 25 characters.');
      setShowSuggestions(false);
      return false;
    }

    if (hasPercentChar(tag)) {
      setError("You can't add % on a tag");
      return false;
    }

    return true;
  };

  const handleOnChange = async (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setInputValue(inputValue);
    const value = removeInvalidCharacters(inputValue);
    if (value.length <= 1 || !isValidTag(value)) {
      setSuggestionList([]);
      setShowSuggestions(false);
    }

    if (value.length >= 2 && isValidTag(value)) {
      setShowSuggestions(true);
      setLoadingSuggestions(true);

      const asyncHandleTagsAutocomplete = async () => {
        return await handleTagsAutocomplete(value);
      };

      lastPromise.current = asyncHandleTagsAutocomplete;

      asyncHandleTagsAutocomplete().then(
        (result) => {
          if (asyncHandleTagsAutocomplete === lastPromise.current) {
            setSuggestionList(result);
            setLoadingSuggestions(false);
          }
        },
        (error) => {
          if (asyncHandleTagsAutocomplete === lastPromise.current) {
            setSuggestionList([]);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <S.Wrapper>
      <S.Header>
        <h6 tabIndex={0}>Tags</h6>
        <Dialog
          boxPosition="right"
          boxDistanceX={35}
          boxDistanceY={10}
          connectorPosition="bottom"
        >
          <S.DialogMessage>
            Start typing tags. Hit comma or return to complete. Hit
            backspace/delete to remove.
          </S.DialogMessage>
        </Dialog>
      </S.Header>
      <S.Description tabIndex={0}>
        Make this post findable by adding tags
      </S.Description>
      <S.InputArea>
        <PostTags
          aria-label="my inserted tags"
          tags={tags}
          handleRemoveTag={removeTag}
        />
        {tags.length < 5 && (
          <S.InputTag
            id="inputTags"
            placeholder="Add a tag..."
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            value={inputValue}
            onChange={handleOnChange}
          ></S.InputTag>
        )}
      </S.InputArea>
      <SuggestionList
        suggestions={suggestionList}
        addTag={addTag}
        showSuggestions={showSuggestions}
        loadingSuggestions={loadingSuggestions}
      />
      <S.Inform visible={error}>
        <IconInfo color="#BD4257" size="s" disabledHover={true} />
        {error}
      </S.Inform>
      <S.Warn tabIndex={0}>Maximum 5 tags</S.Warn>
    </S.Wrapper>
  );
};

export default TagFormAutocomplete;

TagFormAutocomplete.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
  handleTagsAutocomplete: PropTypes.func
};

TagFormAutocomplete.defaultProps = {
  tags: []
};
