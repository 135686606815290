import React, { Component } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga';
import { FeatureToggleContainer } from './2.0/containers/FeatureToggleContainer';
import { LoggerProvider } from './logger';
import Router from './Router';

import { ToastContainer } from 'react-toastify';

//Theme
import { ThemeProvider } from 'styled-components';
import ErrorGeneral from './2.0/pages/ErrorGeneral';
import GlobalStyles from './2.0/styles/global';
import theme from './2.0/styles/theme';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


const errorHandler = (error, info) => {
  console.error(
    'ErrorMessage',
    error,
    '\n',
    'ComponentStack',
    info.componentStack
  );
};
class App extends Component {
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    ReactGA.exception({
      description:
        'Location: ' +
        window.location.href +
        '. ' +
        error +
        '. More details: ' +
        errorInfo.componentStack,
      fatal: true
    });
  }

  render() {
    return (
      <FeatureToggleContainer>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ErrorBoundary
            FallbackComponent={ErrorGeneral}
            onError={errorHandler}
          >
              <LoggerProvider>
                <Router />
              </LoggerProvider>
            <ToastContainer
              position="bottom-right"
              autoClose={6000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </ErrorBoundary>
        </ThemeProvider>
      </FeatureToggleContainer>
    );
  }
}

export default App;
