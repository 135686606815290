import React from 'react';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import AvatarSkeleton from '../Avatar/skeleton';

const AuthorSkeleton = () => {
  return (
    <S.Info>
      <AvatarSkeleton size={'m'} />
      <span>
        <S.AuthorName>
          <Skeleton height={10} width={100} />
        </S.AuthorName>
        <S.InfoAuthor>
          {' '}
          <Skeleton  height={10} width={100} />
        </S.InfoAuthor>
      </span>
    </S.Info>
  );
};

export default AuthorSkeleton;
