import React from 'react';
import ToastifyBase from '../ToastifyBase';
import PropTypes from 'prop-types';
import { IconSuccess } from '../../Icons';

const ToastifySuccess = ({ title, message }) => {
    return (
        <ToastifyBase
          icon={
            <IconSuccess
              size="m"
              color="var(--toastify-color-success)"
              disabledHover={true}
            />
          }
          backGroundColor="var(--toastify-color-success)"
          title={title}
          message={message}
        />
      );
};

ToastifySuccess.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired
};

ToastifySuccess.defaultProps = {
  title: 'Success!'
};

export default ToastifySuccess;
