import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const TabTitle = ({ title, setSelectedTab, index, handleClick, isActive }) => {
  const onClick = useCallback(
    (e) => {
      setSelectedTab(index);
      handleClick(e);
    },
    [setSelectedTab, index, handleClick]
  );

  return (
    <S.SearchHeader tabIndex={0} isActive={isActive}>
      <button data-testid="select-tab" onClick={onClick}>
        {title}
      </button>
    </S.SearchHeader>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number,
  setSelectedTab: PropTypes.func,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool
};

export default TabTitle;
