import styled from 'styled-components';

export const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 44.4rem;
  max-height: auto;
  border-radius: 0.4rem;
  margin-top: 26.2rem;
  padding: 2.8rem;

  @media (max-width: 600px) {
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 18.2rem;
  }
`;

export const LoadingWrapper = styled.div`
  & > div {
    margin: 3rem 8.5rem;
  }
`;

export const ModalOverlay = styled.div`
  height: auto;
  display: flex;
  z-index: 5;
  justify-content: space-evenly;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const CampaignTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 2rem;
  height: 2.9rem;
  letter-spacing: 0px;
  line-height: 2.85rem;
  margin-top: 5.6rem;
  margin-left: 4.8rem;
  margin-bottom: 1.6rem;
`;

export const CampaignModalButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 14rem;

  & > button {
    margin-right: 2.4rem;
  }
`;

export const SubmitButton = styled.button`
  color: ${({ theme }) => theme.colors.primaryDark};
  font-size: 1.4rem;
  letter-spacing: 0rem;
  text-align: center;
  text-decoration: underline;
  border: none;
  height: 3.5rem;
  width: 8.9rem;

  :hover {
    font-weight: bold;
    color: ${({ value, theme }) =>
      value ? theme.colors.secondary : theme.colors.primaryDark};

    @media (max-width: 600px) {
      color: ${({ theme }) => theme.colors.primaryDark};
      opacity: 1;
      box-shadow: none;
    }
  }
`;

export const CancelButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 0.4rem;
  height: 3.5rem;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0rem;
  padding: 0 1rem;

  & :hover {
    font-weight: bold;
    color: ${({ value, theme }) =>
      value ? theme.colors.secondary : theme.colors.primaryDark};

    @media (max-width: 600px) {
      color: ${({ theme }) => theme.colors.primaryDark};
      opacity: 1;
      box-shadow: none;
    }
  }
`;

export const ModalTitle = styled.p`
  color: #000000;
  font-size: 2rem;
  height: auto;
  letter-spacing: 0rem;
  line-height: 3rem;
  text-align: left;
  margin-bottom: 2.6rem;

  @media (max-width: 600px) {
    color: #000000;
    font-size: 1.8rem;
    letter-spacing: 0rem;
    line-height: 2.85rem;
    text-align: left;
    margin-top: 1.9rem;
    margin-left: 2.8rem;
  }
`;

export const ModalTitleError = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 2rem;
  height: auto;

  letter-spacing: 0rem;
  line-height: 3rem;
  text-align: left;
  margin-bottom: 2.6rem;

  @media screen and(max-width: 600px) {
    color: #000000;
    font-size: 1.8rem;
    letter-spacing: 0rem;
    line-height: 2.85rem;
    text-align: left;
    margin-top: 1.9rem;
    margin-left: 2.8rem;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3.2rem;

  & > button {
    margin-right: 2.4rem;
  }
`;

export const WarningModal = styled.div`
  background-color: #ffffff;
  width: 44.4rem;
  height: 26.7rem;
  border-radius: 0.4rem;
  margin-top: 26.3rem;

  @media screen and(max-width: 600px) {
    background-color: #ffffff;
    width: 90%;
    border-radius: 0.4rem;
    margin-top: 18.2rem;
  }
`;

export const WarningTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 2rem;
  font-weight: 600;
  height: 2.9rem;
  letter-spacing: 0rem;
  line-height: 2.85rem;
`;

export const WarningMessage = styled.p`
  color: #000000;
  font-size: 1.8rem;
  margin-top: 1.6rem;
  height: auto;
  letter-spacing: 0px;
  width: 34.8rem;
`;
