import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Author from '../Author';
import RemoveMarkdown from 'remove-markdown';
import useEventTracker from '../../hooks/useEventTracker';
import LikesCount from '../LikesCount';

const FeaturedPost = ({
  featuredImageUrl,
  featuredImageAlt,
  postTitle,
  content,
  authorName,
  authorEmail,
  username,
  publishedAt,
  likes,
  postUrl
}) => {
  const sendEvent = useEventTracker();
  const getSummary = (content) => {
    if (!content) {
      return '';
    }
    content = removeAnyHtmlTag(content);
    const charLimit = window.innerWidth <= 600 ? 85 : 180;
    let summary = content.substring(0, charLimit) + '...';
    summary = getContentWithoutMarkdownImageSintax(summary);
    summary = RemoveMarkdown(summary);
    summary = summary.replace(/>/g, '');
    summary = summary.replace(/#/g, '');
    return summary;
  };

  const removeAnyHtmlTag = (content) => {
    return content.replace(/(<([^>]+)>)/gi, '');
  };

  const getContentWithoutMarkdownImageSintax = (summary) => {
    return summary.replace(/!\[[^\]]+\]\([^)]+\)/, ' ');
  };

  return (
    <S.FeaturedPostSection>
      <S.FeaturedPostImage src={featuredImageUrl}>
        <span role="img" aria-label={featuredImageAlt} />
      </S.FeaturedPostImage>
      <S.Title
        onClick={() =>
          sendEvent('FeaturedPost', `Click on ${postUrl}`, 'FeaturedPost')
        }
        to={`${postUrl}`}
      >
        <h2>{postTitle}</h2>
      </S.Title>
      <S.Summary data-testid="summary">{getSummary(content)}</S.Summary>
      <S.Wrapper>
        <Author
          authorName={authorName}
          authorEmail={authorEmail}
          username={username}
          publishedAt={publishedAt}
          variant={'post'}
        />
        <LikesCount likesList={likes} />
      </S.Wrapper>
    </S.FeaturedPostSection>
  );
};

FeaturedPost.propTypes = {
  featuredImageUrl: PropTypes.string,
  featuredImageAlt: PropTypes.string,
  postTitle: PropTypes.string,
  content: PropTypes.string,
  authorName: PropTypes.string,
  authorEmail: PropTypes.string,
  username: PropTypes.string,
  publishedAt: PropTypes.string,
  likes: PropTypes.array,
  postUrl: PropTypes.string
};

export default FeaturedPost;
