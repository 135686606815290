import styled from 'styled-components';

export const Checkbox = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  display: flex;
  align-items: center;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  visibility: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

export const Text = styled.label`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 9px;
`;

export const StyledCheckbox = styled.label`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  width: 16px;
  height: 16px;
  ${({ sharpborder }) => (sharpborder ? `` :`border-radius: 4px;`)};
  ${({ activatehover }) =>
    activatehover
      ? `&:hover { border: 1px solid #F2617A; box-sizing: border-box; }`
      : ``};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  ${({ checked, theme }) =>
    checked
      ? `background: ${theme.colors.primary}; border: 1px solid ${theme.colors.primary};`
      : `background: ${theme.colors.white}; border: 1px solid #212223;`};

  img {
    width: 12px;
    height: 9px;
  }
`;
