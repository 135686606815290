import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './styles';

const BackLink = ({ handleBackAction }) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    if (!location.state) {
      history.push('/');
      return;
    }

    if (handleBackAction) {
      handleBackAction(history);
      return;
    }

    history.goBack();
  };

  return (
    <S.BackLinkStyled
      onClick={handleClick}
      aria-label={'Back to previews page'}
    >
      <S.BackArrow
        tabIndex={-1}
        aria-label="Icon back arrow"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.5235 6.16689H13.6668V7.83355H3.5235L7.9935 12.3036L6.81516 13.4819L0.333496 7.00022L6.81516 0.518555L7.9935 1.69689L3.5235 6.16689Z"
          fill="#212223"
        />
      </S.BackArrow>
      <S.Text tabIndex={-1}>Back</S.Text>
    </S.BackLinkStyled>
  );
};

export default BackLink;

BackLink.propTypes = {
  handleBackAction: PropTypes.func
};
