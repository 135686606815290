import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;

`

export const DialogBox = styled.div`
    position: absolute;
    border-radius: 0.4rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: -4px 0px 15px rgba(191, 191, 191, 0.5);
    z-index: 3;

    ${({ boxPosition, connectorPosition, boxDistanceX, boxDistanceY, connectorDistance }) => {
    const positionConnector = `${boxPosition}-${connectorPosition}`;
    switch (positionConnector) {
      case 'bottom-left':
        return css`
          top: ${boxDistanceY}px;
          left: ${-boxDistanceX}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: #fff transparent;
            border-width: 0px 8px 12px 8px;
            top: -12px;
            left: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      case 'top-left':
        return css`
          bottom: ${boxDistanceY}px;
          left: ${-boxDistanceX}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: #fff transparent;
            border-width: 12px 8px 0px 8px;
            bottom: -12px;
            left: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      case 'top-right':
        return css`
          bottom: ${boxDistanceY}px;
          right: ${-boxDistanceX}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: #fff transparent;
            border-width: 12px 8px 0px 8px;
            bottom: -12px;
            right: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      case 'right-top':
        return css`
          left: ${boxDistanceX}px;
          top: ${-boxDistanceY}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: transparent #fff;
            border-width: 8px 12px 8px 0px;
            left: -12px;
            top: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      case 'right-bottom':
        return css`
          left: ${boxDistanceX}px;
          bottom: ${-boxDistanceY}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: transparent #fff;
            border-width: 8px 12px 8px 0px;
            left: -12px;
            bottom: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      case 'left-top':
        return css`
          right: ${boxDistanceX}px;
          top: ${-boxDistanceY}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: transparent #fff;
            border-width: 8px 0px 8px 12px;
            right: -12px;
            top: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      case 'left-bottom':
        return css`
          right: ${boxDistanceX}px;
          bottom: ${-boxDistanceY}px;

          ::before {
            content: '';
            border-style: solid;
            position: absolute;
            border-color: transparent #fff;
            border-width: 8px 0px 8px 12px;
            right: -12px;
            bottom: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
      default: //bottom-right
        return css`
          top: ${boxDistanceY}px;
          right: ${-boxDistanceX}px;

          ::before {
            content: '';
            border-style: solid;
            border-color: #fff transparent;
            position: absolute;
            border-width: 0px 8px 12px 8px;
            top: -12px;
            right: ${connectorDistance}px;
            /* filter: drop-shadow(0 -6px 12px rgba(0, 0, 0, 0.2)); */
          }
        `;
    }
  }}
`;

export const Trigger = styled.div`
  font-size:0;
  width: fit-content;
`
