import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const TextArea = styled(TextareaAutosize)`
  box-sizing: border-box;
  margin: 0;
  color: ${({theme}) => theme.colors.text};
  font-size: 16px;
  line-height: 24px;
  display: block;
  width: 100%;
  border: none;
  overflow: hidden;
  resize: none;
`;
