import styled from 'styled-components';

export const CommentGroupOffset = styled.div`
  border-left: 2px solid transparent;
  border-color: ${(props) => (props.showLine ? '#f4f4f4' : 'transparent')};
  margin-left: 20px;
  margin-top: 8px;
  margin-bottom: 15px;
  padding-left: 34px;

  @media screen and (max-width: 900px) {
    border-left: 2px solid transparent;
    margin-left: 0;
    padding-left: 0;
  }
`;

export const CommentStyled = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;
