import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import React from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';

import Layout from './2.0/components/Layout';
import NewShowTag from './2.0/components/Tag/Show/';
import { MatomoContainer } from './2.0/containers/MatomoContainer';
import ModalContainer from './2.0/containers/ModalContainer';
import UserSessionContainer from './2.0/containers/UserSessionContainer';
import ErrorGeneral from './2.0/pages/ErrorGeneral';
import Home from './2.0/pages/Home';
import NotificationSettings from './2.0/pages/NotificationSettings';
import Create from './2.0/pages/Post/Create';
import Edit from './2.0/pages/Post/Edit';
import Profile2 from './2.0/pages/Profile';
import NewReadPost from './2.0/pages/ReadPost';
import Search from './2.0/pages/Search';

import Logout from './components/logout';
import NotFound from './components/notFound';
import EditProfile from './components/profile/editProfile';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ISSUER,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI
});

const Router = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    if (history) {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    } else {
      window.location.replace(
        toRelativeUrl(originalUri, window.location.origin)
      );
    }
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <UserSessionContainer />
        <ModalContainer />
        <MatomoContainer />
        <Switch>
          <Route path="/callback" component={LoginCallback} />
          <SecureRoute path="/post/create" exact={true} component={Create} />

          <SecureRoute
            path="/post/edit/:blog_slug/:post_slug"
            exact={true}
            component={Edit}
          />

          <Layout>
            <Switch>
              <SecureRoute path="/logout" exact={true} component={Logout} />
              <SecureRoute path="/notFound" exact={true} component={NotFound} />
              <SecureRoute
                path="/error"
                exact={true}
                component={ErrorGeneral}
              />
              <SecureRoute exact={true} path="/search" component={Search} />
              <SecureRoute path="/tag" exact={true} component={NewShowTag} />
              <SecureRoute
                path="/notification-settings"
                exact={true}
                component={NotificationSettings}
              />
              <SecureRoute
                path="/profile/:userId/edit"
                exact={true}
                component={EditProfile}
              />
              <SecureRoute
                path="/:blog_name/:post_title"
                exact={true}
                component={NewReadPost}
              />
              <SecureRoute
                exact={true}
                path="/:username"
                component={Profile2}
              />
              <SecureRoute path="/" exact={true} component={Home} />
              <SecureRoute path="*" component={NotFound} />
            </Switch>
          </Layout>
        </Switch>
      </Security>
    </BrowserRouter>
  );
};

export default Router;
