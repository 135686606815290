import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPostBySlugName } from '../../services/posts/service';
import { setReadPostId } from '../../store/posts/actions';
import CommentsCounter from '../../components/CommentsCount';
import LikesContainer from '../LikesContainer';
import * as S from './styles';

const ReadPostStatsContainer = () => {
  const { blog_name, post_title } = useParams();
  const [requestState, setRequestState] = useState('loading');
  const [post, setPost] = useState({});
  const comments = useSelector((state) => state.comments.commentsList);
  const [amountReplies, setAmountReplies] = useState(0);

  const dispatch = useDispatch();

  const countReplies = (comments) => {
    const replies = comments.reduce((amount, comment) => {
      return comment.replies.length + amount;
    }, 0);
    setAmountReplies(replies);
  };

  useEffect(() => {
    countReplies(comments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  useEffect(() => {
    getPostBySlugName(blog_name, post_title).then(
      (data) => {
        setPost(data);
        dispatch(setReadPostId(data.id));
        document.title = `${data.title} - by ${data.author.name} | Blog - ThoughtWorks`;
        setRequestState('resolved');
      },
      (error) => {
        console.error('error', error);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blog_name, post_title]);

  if (requestState === 'loading') {
    return null;
  }
  if (requestState === 'resolved') {
    return (
      <>
        <S.Separator />
        <S.Wrapper>
          <>
            <LikesContainer post={post} />
          </>
          <CommentsCounter commentsAmount={comments.length + amountReplies} />
        </S.Wrapper>
      </>
    );
  }
};

export default ReadPostStatsContainer;
