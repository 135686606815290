import styled from 'styled-components';

export const List = styled.ul`
  width: 100%;
  margin-top: 38px;

  & > li {
    border: none;
    padding: 0;
    margin-top: 24px;
  }

  @media screen and (max-width: 900px) {
    & > li {
      border-bottom: 1px solid #e1e2e3;
      padding-top: 27px;
    }

    & > li:last-of-type {
      border-bottom: 1px solid transparent;
    }
  }
`;

export const SeeMoreCommentsLink = styled.li`
  margin-top: 38.5px;

  & > button {
    color: ${({theme}) => theme.colors.primaryDark};
    font-size: 14px;
    font-weight: normal;
    height: 19px;
    letter-spacing: 0px;
    text-align: center;
    text-decoration: underline;
    width: 135px;
    background: none;
    border: none;
    width: auto;
    cursor: pointer;
    padding: 0;
  }

  & > button:hover {
    font-weight: 700;
  }
`;
