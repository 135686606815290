import React from 'react';
import ReaderContainer from '../../containers/Post/ReaderContainer';
import * as S from './styles.js';
import CreateCommentContainer from '../../containers/CreateCommentContainer';
import ListCommentsContainer from '../../containers/ListCommentsContainer';
import BackTop from '../../components/BackTop';
import ReadPostStatsContainer from '../../containers/ReadPostStatsContainer';

const ReadPost = () => {
  return (
    <S.Page>
      <ReaderContainer />
      <ReadPostStatsContainer />
      <CreateCommentContainer />
      <ListCommentsContainer />
      <S.BackTopWrapper>
        <BackTop isFloating={false} />
      </S.BackTopWrapper>
    </S.Page>
  );
};

export default ReadPost;
