import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../../../../Button';
import * as S from './styles';

const ItemEditForm = (props) => {
  const { reply, closeEditForm, handleEditReply } = props;
  const [textEdited, setTextEdited] = useState(reply.text);

  const handleCancelEdit = (e) => {
    e.preventDefault();
    closeEditForm();
  };

  const handleChange = (e) => {
    setTextEdited(e.target.value);
  };

  const handleConfirmEdit = (e) => {
    e.preventDefault();
    handleEditReply(reply, textEdited);
    closeEditForm();
  };

  return (
    <S.Form onSubmit={handleConfirmEdit} data-testid="editTextForm" aria-label='Edit Form' > 
      <S.TextArea
        type="text"
        rows="1"
        data-testid="editTextInput"
        defaultValue={reply.text}
        onChange={handleChange}
      />
      <S.WrapperButtons>
        <Button
          size="s"
          variant="link"
          onClick={handleCancelEdit}
          label="Cancel"
        />
        <Button
          size="s"
          type="submit"
          variant="primary"
          disabled={!textEdited}
          label="Update"
          data-testid="updateButton"
        />
      </S.WrapperButtons>
    </S.Form>
  );
};

ItemEditForm.propTypes = {
  reply: PropTypes.object,
  closeEditForm: PropTypes.func,
  handleEditReply: PropTypes.func
};

export default ItemEditForm;
