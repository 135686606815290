import styled from 'styled-components';

export const PostCoverImageWrapper = styled.div`
  max-width: 728px;
  max-height: 368px;
  margin: 3.2rem 0;

  img {
    width: 100%;
    height: auto;
  }
`;
