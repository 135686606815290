import { get, count } from '../../infrastructure/client/client';

export const getPostsFromServer = async (start, limit) => {
  const data = await get(
    `/posts?_sort=published_date:DESC&_start=${start}&_limit=${limit}`,
    ''
  );
  return data.error ? [] : data;
};

export const countPosts = () => {
  return count(`/posts`, '').then((data) => {
    return data;
  });
};

export const buildPostList = (postList) => {
  let buildedList = [];

  postList.forEach((item) => {
    const url = '/' + item.blog.slug + '/' + item.slug;

    const post = {
      ...item,
      url: url
    };

    buildedList.push(post);
  });
  return buildedList;
};
