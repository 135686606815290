import styled from 'styled-components';

export const PostPreviewStyled = styled.article`
  width: 70%;
  height: 100%;
  margin: 0 15% 40px 15%;
  padding-top: 130px;

  img {
    margin-top: 32px;
    width: 728px;
    height: 368px;
  }

  h1 {
    width: 100%;
    color: ${({ theme }) => theme.colors.black};
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-top: 32px;
    margin-bottom: 24px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

export const LayoutContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;

  /* Tablet */
  @media (min-width: 601px) and (max-width: 1279px) {
    max-width: 80%;
    margin: 0 10%;
  }

  /* Mobile */
  @media (max-width: 600px) {
    max-width: 288px;
    margin: 0 auto;
  }
`;

export const PostTitle = styled.h1`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  font-size: 3.2rem;
  line-height: 4.2rem;
  margin-top: 3.2rem;
  margin-bottom: 2.4rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media (max-width: 600px) {
    font-size: 2.8rem;
    margin-top: 1.6rem;
    margin-bottom: 1.2rem;
  }
`;
