import styled from 'styled-components';

export const DialogOverlay = styled.div`
  display: flex;
  z-index: 99;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const Dialog = styled.div`
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  width: 823px;
  border-radius: 8px;

  @media screen and (max-width: 600px) {
    background-color: ${({ theme }) => theme.colors.white};
    width: 80%;
    height: 166px;
    border-radius: 4px;
    margin-top: 182px;
  }

  header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray30};
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 24px 159px;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;

  & > button:first-child {
    margin-right: 6px;
  }
`;

export const UploadSelectorButton = styled.button`
  padding: 0.8rem 0;
  background: transparent;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  border: none;
  outline: none;

  ${({ isActive, theme }) =>
    isActive === true
      ? `
          color: ${theme.colors.primaryHover};
          border-bottom: 2px solid ${theme.colors.primaryHover};
          font-weight: 700;
        `
      : `color: : ${theme.colors.black};
        `}
`;

export const ImageUploadPreview = styled.span`
  width: 217px;
  height: 169px;
  border-radius: 4px;
  display: flex;
  background-image: url('${(props) => props.croppedImage}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export const AltTex = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;

  #title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.text};
    margin: 0px;
    margin-bottom: 8px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: ${({ theme }) => theme.colors.gray70};
      margin-left: 8px;
    }
  }

  textarea {
    box-sizing: border-box;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid ${({ theme }) => theme.colors.gray40};
    border-radius: 3px;
    width: 100%;
    height: 90px;
    outline: none;
    font-size: 16px;
    line-height: 24px;

    ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.gray70};
    }
  }
`;

export const WordCounter = styled.p`
  display: flex;
  justify-content: flex-end;
  color: #696c80;
  font-size: 12px;
  font-weight: bold;
  height: 17px;
  letter-spacing: 0px;
  margin-top: 8px;
`;
