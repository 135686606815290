import {
  COMMENTS_LIST,
  REMOVE_COMMENT,
  ADD_COMMENT,
  UPDATE_COMMENT,
  ADD_REPLY_COMMENT,
  REMOVE_REPLY_COMMENT,
  UPDATE_REPLY_COMMENT
} from '../actionTypes';

export const commentsList = (value) => ({
  type: COMMENTS_LIST,
  payload: value
});

export const removeCommentFromList = (value) => ({
  type: REMOVE_COMMENT,
  payload: value
});

export const addCommentToList = (value) => ({
  type: ADD_COMMENT,
  payload: value
});

export const updateCommentFromList = (value) => ({
  type: UPDATE_COMMENT,
  payload: value
});

export const addReplyComment = (value) => ({
  type: ADD_REPLY_COMMENT,
  payload: value
});

export const removeReplyComment = (value) => ({
  type: REMOVE_REPLY_COMMENT,
  payload: value
});

export const updateReplyComment = (value) => ({
  type: UPDATE_REPLY_COMMENT,
  payload: value
});
