import PropTypes from 'prop-types';
import React from 'react';
import Tag from '../Tag';
import * as S from './styles';

const PostTags = ({ tags, handleRemoveTag }) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <S.TagsList tabIndex={0} aria-label="list of tags">
      {tags.map((tag, index) => (
        <Tag key={index} label={tag.name} handleRemoveTag={handleRemoveTag} />
      ))}
    </S.TagsList>
  );
};

PostTags.propTypes = {
  tags: PropTypes.array.isRequired,
  handleRemoveTag: PropTypes.func
};

PostTags.defaultProps = {
  tags: []
};

export default PostTags;
