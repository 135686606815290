import { loadingReducer } from './loading/reducer';
import { postReducer } from './posts/reducer';
import { userSessionReducer } from './loggedUser/reducer';
import { searchReducer } from './search/reducer';
import { notificationReducer } from './notification/reducer';
import { profileReducer } from './profile/reducer';
import { combineReducers } from 'redux';
import { commentsReducer } from './comments/reducer';
import { modalReducer } from './modals/reducer';

export const Reducers = combineReducers({
  post: postReducer,
  showLoading: loadingReducer,
  userSession: userSessionReducer,
  search: searchReducer,
  notification: notificationReducer,
  profile: profileReducer,
  comments: commentsReducer,
  modal: modalReducer
});
