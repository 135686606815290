import styled from 'styled-components';

export const CommentsCountWrapper = styled.div`
  display: flex;
  align-items: center;

  & > span{
    color: ${({ theme }) => theme.colors.gray70};
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin-left: 1rem;
  }
`;
