import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Header from './Header';
import * as S from './styles';

const ReplyForm = ({ comment, loggedUser, handleClose, handleAddReply }) => {
  const [replyText, setReplyText] = useState('');

  const handleCancelReply = (e) => {
    e.preventDefault();
    setReplyText('');
    handleClose();
  };

  const handleSubmitReplyComment = (e) => {
    e.preventDefault();
    handleAddReply(comment.id, loggedUser.id, replyText);


    handleClose();
  };

  const handleChangeReplyText = (e) => {
    setReplyText(e.target.value);
  };

  return (
    <form onSubmit={handleSubmitReplyComment} data-testid="replyCommentForm">
      <Header loggedUser={loggedUser} />
      <S.TextArea
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        aria-label="Add your reply"
        id="commentReply"
        onChange={handleChangeReplyText}
        type="text"
        rows="1"
        defaultValue={''}
        data-testid="replyCommentText"
        placeholder="Add your reply"
      />
      <S.WrapperButtons>
        <Button
          size="s"
          variant="link"
          onClick={handleCancelReply}
          label="Cancel"
        />
        <Button
          size="s"
          type="submit"
          variant="primary"
          disabled={!replyText}
          label="Reply"
          data-testid="confirmReplyButton"
        />
      </S.WrapperButtons>
    </form>
  );
};

ReplyForm.propTypes = {
  comment: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  handleAddReply: PropTypes.func,
  loggedUser: PropTypes.object
};

export default ReplyForm;
