import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import moment from 'moment';

const MostPopularPosts = ({ postList }) => {
  const formatTitleByLength = (title) => {
    const charLimit = 52;
    let titleFormatted = title;

    if (title > charLimit) {
      titleFormatted = `${title.substring(0, charLimit)}...`;
    }

    return titleFormatted;
  };

  return (
    <S.MostPopularList>
      {postList.map((post, index) => (
        <li key={post.id}>
          <span>0{index + 1} </span>
          <div>
            <S.Title
              to={{
                pathname: post.url
                  ? post.url
                  : `/${post.blog.slug}/${post.slug}`,
                state: { prevPath: '/' }
              }}
            >
              <h6>{formatTitleByLength(post.title)}</h6>
            </S.Title>
            <p>
              by{' '}
              <S.Author
                data-testid="author"
                to={{
                  pathname: post.url ? post.url : `/${post.author.username}`
                }}
              >
                {post.author.name}
              </S.Author>{' '}
              on{' '}
              <span>
                {moment(post.published_date).utc().format('MMMM D, YYYY')}{' '}
              </span>
            </p>
          </div>
        </li>
      ))}
    </S.MostPopularList>
  );
};

MostPopularPosts.propTypes = {
  postList: PropTypes.array
};

export default MostPopularPosts;
