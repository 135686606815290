import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const PostCoverImage = ({ imageUrl, altText, coverEnabled }) => {
  if (!coverEnabled || !imageUrl) return null;

  return (
    <S.PostCoverImageWrapper tabIndex="0">
      <img src={imageUrl} alt={altText} />
    </S.PostCoverImageWrapper>
  );
};

PostCoverImage.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  coverEnabled: PropTypes.bool.isRequired
};

export default PostCoverImage;
