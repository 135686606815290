import React from 'react';
import * as S from './styles';
import error from '../../../images/page-error.svg'

const ErrorGeneral = () => {
  return (
    <S.Page>
      <S.Wrapper>
        <S.Content>
          <S.Title aria-label="Something went wrong" data-testid="title">Something <br/>went wrong</S.Title>
          <S.FirstMessage aria-label="Sorry! It seems that something wrong is happening with our system,
            we are working to fix this issue." data-testid="first-message">
            Sorry! It seems that something wrong is happening with our system,
            we are working to fix this issue.
          </S.FirstMessage>
          <S.Subtitle aria-label="What next?">What next?</S.Subtitle>
          <S.SecondMessage  aria-label="Please, try to access the Central Blog again, if the problem
            persists send us a{' '}" data-testid="second-message">
            Please, try to access the Central Blog again, if the problem
            persists send us a{' '}
            <S.Feedback
              data-testid="feedback"
              to={{}}
              onClick={() =>
                window.open(
                  'https://central.thoughtworks.net/home/feedback-blog',
                  '_self'
                )
              }
            >
              feedback
            </S.Feedback>{' '}
            and we will be in touch.
          </S.SecondMessage>
        </S.Content>
        <S.Image data-testid="image" alt="PC Bug Image" src={error} />
      </S.Wrapper>
    </S.Page>
  );
};


export default ErrorGeneral;
