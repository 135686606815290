import styled from 'styled-components';

export const NotificationSettingsForm = styled.form`
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 24px 24px 32px 24px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  @media (max-width: 600px) {
    padding-top: 32px;
    margin-bottom: 0;
    border: none;
  }
`;

export const WrapperDropDown = styled.div`
  padding-left: 22px;
  margin-top: 12px;
  margin-bottom: 32px;
  ${({ isActive }) =>
    isActive
      ? `pointer-events: all;opacity: 1;`
      : `pointer-events: none; opacity: 0.6;pointer-events: none;`};
`;

export const DropDownTitle = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  position: static;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Title = styled.h5`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SessionSubtitle = styled.h6`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 600;
  }
`;

export const RadioEmailFrequency = styled.input`
  &[type='radio'] {
    &:checked:before {
      content: '';
      background: orange;
      border-radius: 100%;
      left: 0;
      top: 0;
    }
  }
`;

export const ButtonToRight = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    margin-top: 24px;
    margin-right: 16px;
    padding: 0 0 40px 0;
  }
`;

export const DescriptionRadioButton = styled.p`
  font-size: 12px;
  margin-left: 22px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray70};
`;

export const GroupRadioButton = styled.ul`
  > li:first-child {
    margin-bottom: 16px;
  }
  > li:last-child {
    margin-bottom: 12px;
  }

  ${({ isActive }) =>
    isActive
      ? `pointer-events: all;opacity: 1;`
      : `pointer-events: none; opacity: 0.6;`};
`;
