import styled from 'styled-components';

export const AnchorBell = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.secondary};
  width: 22px;
  height: 22px;
  position: relative;

  svg {
    width: 100%;

    & > path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:hover {
    svg > path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Badge = styled.span`
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: -2px;
  right: -2px;
`;
