import styled from 'styled-components';

export const Container = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 1.8rem;

`;

export const Note = styled.span`
  font-size: 14px;
  line-height: 21px;
  color:#747576;
`;


export const Paragraph = styled.p`
margin-bottom:2.0rem;

@media (max-width: 600px) {
    margin: none;
    margin-bottom:none;
}

`;
