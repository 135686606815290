import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editComment,
  getCommentsByPostId,
  removeComment
} from '../../services/comments/service';
import CommentsList from '../../components/Comments/List';
import { Modal } from '../../components/Modals';
import {
  commentsList,
  removeCommentFromList,
  updateCommentFromList
} from '../../store/comments/actions';

const ListCommentsContainer = () => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const postId = useSelector((state) => state.post.readPostId);
  const comments = useSelector((state) => state.comments.commentsList);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [commentToRemoveId, setCommentToRemoveId] = useState(0);
  const [commentToEditId, setCommentToEditId] = useState();
  const [requestState, setRequestState] = useState('loading');

  useEffect(() => {
    if (postId) {
      const fetchData = async () => {
        getCommentsByPostId(postId).then((commentList) => {
          dispatch(commentsList(commentList));
          setRequestState('resolved');
        });
      };

      fetchData();
    }

    // eslint-disable-next-line
  }, [postId]);

  const handleRemove = (comment) => {
    setCommentToRemoveId(comment.id);
    openModal();
  };

  const handleEdit = (commentItem) => {
    setCommentToEditId(commentItem.id);
  };

  const showEditForm = (commentId) => {
    return commentToEditId === commentId;
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleConfirmation = async (e) => {
    const response = e.target.value;
    if (response === 'true') {
      const commentRemoved = await removeComment(commentToRemoveId);
      dispatch(removeCommentFromList(commentRemoved.id));
    }
    closeModal();
  };

  const handleSubmitEditedComment = (e) => {
    e.preventDefault();

    const editedCommentText = e.target.elements.editedText.value.trim();

    if (editedCommentText === '') {
      handleRemove(commentToEditId);
      return;
    }

    const commentToUpdate = {
      text: editedCommentText
    };

    editComment(commentToEditId, commentToUpdate).then((response) => {
      dispatch(updateCommentFromList(response));
      setCommentToEditId(0);
    });
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setCommentToEditId(0);
  };

  switch (requestState) {
    case 'resolved':
      return (
        <>
          <CommentsList
            comments={comments}
            handleSubmitEditedComment={handleSubmitEditedComment}
            handleCancelEdit={handleCancelEdit}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
            showEditForm={showEditForm}
            loggedUser={loggedUser}
          />
          <Modal
            showModal={showModal}
            handleConfirmation={handleConfirmation}
            message="Delete this comment permanently?"
            cancelButton="Not now"
            submitButton="Delete"
          />
        </>
      );
    default:
      return null;
  }
};

export default ListCommentsContainer;
