import { blogApiNew } from '../../servers/blogApiNew';
import { showLoading } from '../../store/loading/actions';
import {
  hasMoreAuthors,
  hasMorePosts,
  searchAuthors,
  searching,
  searchPosts,
  searchTags
} from '../../store/search/actions';

export const getSearchPosts = async (searchTitle, limit, start, dispatch) => {
  dispatch(showLoading(true));
  const response = await blogApiNew.get(
    `/posts?filters[title][$containsi]=${searchTitle}&pagination[limit]=${limit}&pagination[start]=${start}&populate[0]=author&populate[1]=blog&populate[2]=comments&populate[3]=tags&populate[4]=likes.author`
  );

  if (response.length === 0) {
    dispatch(hasMorePosts(false));
  }

  dispatch(searchPosts(response));
  dispatch(showLoading(false));
  dispatch(searching(false));
};

export const getSearchTags = async (tagName, dispatch) => {
  const response = await blogApiNew.get(
    `/tags?filters[name][$containsi]=${tagName}&populate=*`
  );
  dispatch(searchTags(response));
};

export const getSearchAuthors = async (term, limit, start, dispatch) => {
  dispatch(showLoading(true));
  const response = await blogApiNew.get(
    `/posts/authors?name=${term}&pagination[limit]=${limit}&pagination[start]=${start}`
  );

  if (response.length === 0) {
    dispatch(hasMoreAuthors(false));
  }

  dispatch(searchAuthors(response));
  dispatch(showLoading(false));
  dispatch(searching(false));
};
