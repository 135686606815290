import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  margin-top: 10rem;
`;

export const CircleSpinning = styled.svg`
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
`;

export const LoadingMessage = styled.p`
    font-size: 2rem;
    text-align: center;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    margin-top: 1.4rem;
`;
