import { blogApiNew } from '../../servers/blogApiNew';

const throwIfError = (data, message) => {
  if (data?.statusCode && data?.statusCode !== 200) {
    throw new Error(message);
  }
};

export const listNotificationsByReceiverId = async (receiverId) => {
  let date = new Date();
  date.setDate(date.getDate() - 30);
  date.setHours(0, 0, 0, 0);
  const last30days = date.toISOString();

  const data = await blogApiNew.get(
    `/notifications?filters[receiver]=${receiverId}&filters[status][$ne]=disabled&filters[deleted_at][$null]=true&sort=createdAt:desc&createdAt[$gt]=${last30days}&populate[0]=sender&populate[1]=receiver&populate[2]=post.author.blog`
  );

  throwIfError(data, 'Could not get notifications');
  return data;
};

export const markAllAsRead = async (notificationList) => {
  const url = '/notifications/center/mark-all-as-read';
  const data = await blogApiNew.post(url, notificationList);

  throwIfError(data, 'Could not update notifications!');

  return data;
};

export const updateNotificationStatus = async (notificationId, status) => {
  const body = { status };
  const url = `/notifications`;
  const data = await blogApiNew.edit(url, notificationId, body);

  throwIfError(data, 'Could not update notification!');

  return data;
};

export const deleteNotification = async (notificationId) => {
  const url = `/notifications`;
  const data = await blogApiNew.delete(url, notificationId);

  throwIfError(data, 'Could not delete the notification!');

  return data;
};
