import styled from 'styled-components';

export const ImageCropWrapper = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const CropContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 455px;
  height: 230px;
  position: relative;
  background: #eeeeef;
`;
