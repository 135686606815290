import styled from 'styled-components';

export const Page = styled.main`
  max-width: 110.8rem;
  margin: 0 auto 60px;

  @media screen and (max-width: 1108px) {
    margin: 0 16px 60px;
  }
`;

export const Headline = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 2.4rem;
  margin-bottom: 3.2rem;

  @media screen and (max-width: 600px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-areas:
    'featuredPost featuredList'
    'latestPosts aside';
  grid-template-rows: auto auto;
  grid-template-columns: 7fr 5fr;
  grid-row-gap: 4rem;
  grid-column-gap: 3.2rem;

  @media screen and (max-width: 900px) {
    grid-template-areas:
      'featuredPost'
      'featuredList'
      'aside'
      'latestPosts';
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
  }
`;

export const FeaturedPostArea = styled.section`
  grid-area: featuredPost;
`;

export const FeaturedListArea = styled.section`
  grid-area: featuredList;
`;

export const LatestPostsArea = styled.section`
  grid-area: latestPosts;
`;

export const AsideArea = styled.aside`
  grid-area: aside;
  padding-left: 2.4rem;

  @media screen and (max-width: 900px) {
    padding-left: 0;
  }
`;

export const ScrollPointReference = styled.hr`
  border: none;
`;
