import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconArrowLeft, IconClose } from '../Icons';
import useClickOutside from '../../hooks/useClickOutside';
import AuthorBiography from '../AuthorBiography';

const LikesCounter = ({ likesList }) => {
  likesList = likesList.sort((a, b) => b.id - a.id);
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenLikeList = () => {
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector("button[name='up-to-button-button']").style.display =
      'none';
    setShowDialog(true);
  };

  const handleCloseLikeList = () => {
    document.querySelector('body').style.overflow = 'auto';
    document.querySelector("button[name='up-to-button-button']").style.display =
      'flex';
    setShowDialog(false);
  };

  const dialogRef = useClickOutside(() => {
    handleCloseLikeList();
  });

  return (
    <>
      <S.Likes aria-label={`number of likes ${likesList.length}`} tabIndex="0">
        <S.ClickList
          role="button"
          onClick={likesList.length >= 1 ? handleOpenLikeList : null}
          clickable={likesList.length >= 1}
        >
          {likesList.length}
        </S.ClickList>
      </S.Likes>

      {showDialog ? (
        <S.Modal
          role="alertdialog"
          aria-labelledby="confirmation_modal"
          aria-modal="true"
        >
          <S.ModalContent
            id="notifications"
            data-testid="dialogContent"
            ref={dialogRef}
          >
            <S.Header>
              <S.Back
                onClick={handleCloseLikeList}
                role="button"
                name="back-button"
                aria-label="Close list of likes"
              >
                <IconArrowLeft size="l" color="#003D4F" disabledHover={true} />
              </S.Back>

              <S.Title>
                {likesList.length} {likesList.length === 1 ? ' Like' : ' Likes'}
              </S.Title>

              <S.Close
                onClick={handleCloseLikeList}
                role="button"
                name="close-button"
                aria-label="Close list of likes"
              >
                <IconClose size="m" color="#003D4F" disabledHover={true} />
              </S.Close>
            </S.Header>

            <S.List>
              {likesList.map((author, index) => (
                <li key={index}>
                  <AuthorBiography
                    author={author}
                    isBiography={false}
                    showTotalPosts={false}
                  />
                  {<S.Line />}
                </li>
              ))}
            </S.List>
          </S.ModalContent>
        </S.Modal>
      ) : null}
    </>
  );
};

LikesCounter.propTypes = {
  likesList: PropTypes.array
};

LikesCounter.defaultProps = {
  likesList: []
};

export default LikesCounter;
