import React, { useEffect, useState } from 'react';
import { getMostPopularPosts } from '../../services/posts/service';
import MostPopularPosts from '../../components/MostPopularPosts';
import * as S from './styles';
import MostPopularPostsSkeleton from '../../components/MostPopularPosts/skeleton';

const MostPopularContainer = () => {
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    let qtdPosts = 5;
    getMostPopularPosts(qtdPosts).then((response) => {
      setPostList(response);
    });
  }, []);

  return (
    <S.Wrapper>
      <h3> Most Popular</h3>
      <hr />
      {postList.length >= 1 ? (
        <MostPopularPosts postList={postList} />
      ) : (
        <MostPopularPostsSkeleton postList={postList} />
      )}
    </S.Wrapper>
  );
};

export default MostPopularContainer;
