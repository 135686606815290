import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RepliesList from '../../components/Replies/List';
import {
  addReplyComment,
  removeReplyComment,
  updateReplyComment
} from '../../store/comments/actions';
import {
  replyComment,
  removeReply,
  editReply
} from '../../services/replies/service';
import { Modal } from '../../components/Modals';

const RepliesContainer = ({ comment }) => {
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [replyToRemoveId, setReplyToRemoveId] = useState(0);

  const handleAddReplyComment = (commentId, authorId, replyText) => {
    const validText = replyText.trim();
    if (validText) {
      replyComment(commentId, authorId, validText).then((data) => {
        dispatch(addReplyComment(data));
      });
    }
  };

  const openModal = (replyId) => {
    setReplyToRemoveId(replyId);
    setShowModal(true);
  };

  const closeModal = () => {
    setReplyToRemoveId(0);
    setShowModal(false);
  };

  const handleRemoveReply = (e) => {
    const response = e.target.value;

    if (response === 'true') {
      removeReply(replyToRemoveId).then((data) => {
        dispatch(removeReplyComment(data));
      });
    }

    closeModal();
  };

  const handleEditReply = (reply, newReplyText) => {
    if (newReplyText.trim() === '') {
      handleRemoveReply(reply);
      return;
    }

    editReply(reply.id, newReplyText).then((data) => {
      dispatch(updateReplyComment(data));
    });
  };

  return (
    <>
      <RepliesList
        comment={comment}
        handleAddReply={handleAddReplyComment}
        handleEditReply={handleEditReply}
        loggedUser={loggedUser}
        openModal={openModal}
      />
      <Modal
        showModal={showModal}
        handleConfirmation={handleRemoveReply}
        message="Delete this comment permanently?"
        cancelButton="Not now"
        submitButton="Delete"
      />
    </>
  );
};

RepliesContainer.propTypes = {
  comment: PropTypes.object
};

export default RepliesContainer;
