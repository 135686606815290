import { CLOSE_MODAL, SHOW_MODAL } from '../actionTypes';

const initialState = {
  config: {
    showModal: false,
    handleConfirmation: undefined,
    title: '',
    message: '',
    cancelButton: '',
    submitButton: '',
    type: '',
    list: []
  }
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        config: action.payload
      };
    case CLOSE_MODAL:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};
