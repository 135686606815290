import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.6rem;
`;

export const LoadingDots = styled.div`
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary};
  animation: dotsFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.primary};
    animation: dotsFlashing 1s infinite alternate;
  }

  &::before {
    left: -1.5rem;
    animation-delay: 0s;
  }

  &::after {
    left: 1.5rem;
    animation-delay: 1s;
  }

  @keyframes dotsFlashing {
    0% {
      background-color: ${({theme}) => theme.colors.primary};
    }
    50%,
    100% {
      background-color: ${({theme}) => theme.colors.primaryLight};
    }
  }
`;
