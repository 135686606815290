import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../styles.js';
import { IconNotification, IconEmail } from '../../Icons/index.jsx';

const ChannelDefinition = (props) => {
  const { channel, position } = props;

  const iconChannel = {
    Blog: <IconNotification size="m" color="#003D4F" disabledHover={true} />,
    Email: <IconEmail size="m" color="#003D4F" disabledHover={true} />
  };

  const columnShift = 1;
  const rowPosition = 1;
  const columnPosition = position + columnShift;

  return (
    <>
      <S.CellPosition row={rowPosition} column={columnPosition} height="24px">
        {iconChannel[channel]}
        <S.NotificationSettingsChannelLabel>
          {channel}
        </S.NotificationSettingsChannelLabel>
      </S.CellPosition>
    </>
  );
};

ChannelDefinition.propTypes = {
  channel: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired
};

export default ChannelDefinition;
