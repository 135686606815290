import styled from 'styled-components';

export const ReplyFormHeaderStyled = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 26px;
`;
