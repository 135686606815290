import PropTypes from 'prop-types';
import React from 'react';
import Author from '../../Author';
import PostContent from '../../PostContent';
import PostCoverImage from '../../PostCoverImage';
import PostTags from '../../PostTags';
import * as S from './styles';
import CKEditorCustom from '../../CKEditorCustom';

const PostPreview = (props) => {
  const { post } = props;

  return (
    <S.LayoutContainer>
      <S.PostPreviewStyled>
        <Author
          authorName={post.author.name}
          authorEmail={post.author.email}
          username={post.author.username}
          publishedAt={post.published_date.toString()}
          showAvatar={true}
          sizeAvatar="l"
          variant={'post'}
        />
        <PostCoverImage
          imageUrl={post.featured_image_url}
          altText={post.featured_image_alt}
          coverEnabled={post.cover_enabled}
        />
        <S.PostTitle>{post.title}</S.PostTitle>
        {post.editor_version === 'v1' ? (
        <PostContent
          content={post.content}
        />
      ) : (
        <CKEditorCustom
          data={post.content}
          featureId='ck-post-preview'
        />
      )}
        <PostTags tags={post.tags} />
      </S.PostPreviewStyled>
    </S.LayoutContainer>
  );
};

PostPreview.propTypes = {
  post: PropTypes.object
};

export default PostPreview;
