import { blogApiNew } from '../../servers/blogApiNew';

export const getUserByUsername = async (username) => {
  const response = await blogApiNew.get(`/users?filters[username]=${username}`);

  if (!response) {
    throw new Error(`Error when getting a user with username ${username}`);
  }

  if (response.length === 0) {
    throw new Error(`Could not find an user with username ${username}`);
  }

  return response[0];
};

export const getUserByEmail = async (userEmail) => {
  const response = await blogApiNew.get(`/users?filters[email]=${userEmail}`);

  if (!response) {
    throw new Error(`Error when getting a user with email ${userEmail}`);
  }

  if (response.length > 0) {
    return response[0];
  }

  return {};
};
