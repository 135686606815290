import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Followers from '../../components/Followers';
import {
  addFollower,
  findOneUserFollower,
  removeFollower
} from '../../services/followers/service';

const FollowersContainer = () => {
  const [followedByLoggedUser, setFollowedByLoggedUser] = useState({});
  const [disableActionButton, setDisableActionButton] = useState(false);
  const [showFollowers, setShowFollowers] = useState(false);

  const isLoggedUser = useSelector((state) => state.profile.isLoggedUser);
  const loggedUser = useSelector((state) => state.userSession.loggedUser);
  const profileUser = useSelector((state) => state.profile.user);

  useEffect(() => {
    if (loggedUser.email && profileUser.email) {
      findOneUserFollower(loggedUser.email, profileUser.email)
        .then((result) => {
          setFollowedByLoggedUser(result);
          setShowFollowers(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser.email, profileUser.email]);

  const isFollowByLoggedUser = () => {
    return followedByLoggedUser.id !== undefined;
  };

  const handleOnClickFollow = async () => {
    setDisableActionButton(true);
    if (isFollowByLoggedUser()) {
      removeFollower(followedByLoggedUser.id)
        .then(async () => {
          setFollowedByLoggedUser({});
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setDisableActionButton(false);
        });
    } else {
      addFollower(loggedUser.id, profileUser.id)
        .then(async () => {
          setFollowedByLoggedUser(
            await findOneUserFollower(loggedUser.email, profileUser.email)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setDisableActionButton(false);
        });
    }
  };

  return (
    showFollowers && (
      <Followers
        isFollowedByLoggedUser={isFollowByLoggedUser()}
        isLoggedUser={isLoggedUser}
        actionOnClick={handleOnClickFollow}
        disableActionButton={disableActionButton}
      />
    )
  );
};

export default FollowersContainer;
