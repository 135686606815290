import React from 'react';
import * as S from './styles';

import 'react-loading-skeleton/dist/skeleton.css';
import PostSummarySkeleton from '../PostSummary/skeleton';

const listSkeleton = [0, 1, 2, 3, 4]

const LatestPostsSkeleton = () => {
    return (
        <S.LatestPostsWrapper>
            <S.Title>Latest Posts</S.Title>
            <S.LatestPostsList id="postList">
                <S.WrapperSkeleton>
                    <S.ListSkeleton>
                        {listSkeleton.map((post, index) => (
                            <li key={index}>
                                <PostSummarySkeleton />

                            </li>
                        ))}
                    </S.ListSkeleton>
                </S.WrapperSkeleton>
            </S.LatestPostsList>
        </S.LatestPostsWrapper>
    );
};

export default LatestPostsSkeleton;
