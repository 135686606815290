import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWhiteLink, ButtonPink } from '../buttons/Buttons';

const ButtonsWrapper = (props) => {
  const { cancelName, submitName, handleCancelAction, style, size } = props;
  return (
    <div className={style}>
      <ButtonWhiteLink
        size={size}
        type="button"
        value={false}
        onClick={handleCancelAction}
      >
        {cancelName}
      </ButtonWhiteLink>
      <ButtonPink size={size} type="submit" value={true}>
        {submitName}
      </ButtonPink>
    </div>
  );
};

ButtonsWrapper.propTypes = {
  cancelName: PropTypes.string,
  submitName: PropTypes.string,
  handleCancelAction: PropTypes.func,
  handleSubmitAction: PropTypes.func,
  style: PropTypes.any,
  size: PropTypes.string
};

export default ButtonsWrapper;
