import { blogApiNew } from '../../servers/blogApiNew';
import { transformToSnakeCase } from '../utils/migrationFunctions';

//Name changed from getCommentsFromServer  to getCommentsByPostId
export const getCommentsByPostId = async (postId) => {
  const response = await blogApiNew.get(
    `/comments?filters[post][id]=${postId}&sort=createdAt:desc&[populate][0]=author&populate[1]=post&populate[2]=replies.author`
  );
  return response.map((item) => transformToSnakeCase(item));
};

export const addComment = async (comment) => {
  const url = `/comments`;
  const response = await blogApiNew.post(url, comment);
  return transformToSnakeCase(response);
};

export const removeComment = async (commentId) => {
  const url = `/comments`;
  const response = await blogApiNew.delete(url, commentId);
  return response;
};

export const editComment = async (commentId, body) => {
  const url = `/comments`;
  const response = await blogApiNew.edit(url, commentId, body);
  return transformToSnakeCase(response);
};
