import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TabTitle from './TabTitle';
import * as S from './styles';

const Tabs = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <S.TabList id="tabs">
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            handleClick={item.props.handleClick}
            isActive={item.props.isActive}
          />
        ))}
      </S.TabList>

      {children[selectedTab]}
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.array
};

export default Tabs;
