import React, { useEffect } from 'react';
import Confettiful from './Confettiful';
import * as S from './styles';


const CelebrationBanner = () => {

  const CELEBRATION_POST_URL =
    'https://blog.thoughtworks.net/giordana-sousa/1000-posts';

    useEffect(() => {
    window.confettiful = new Confettiful(
      document.querySelector('.js-container')
    );
  }, []);


  return (
    <S.Wrapper>
      <S.Banner className="js-container">
        <div>
          <S.Title>We reached 1,000 posts!</S.Title>
          <S.Text>
            We are very happy and grateful to everyone for being part of this
            moment.
          </S.Text>
        </div>
        <S.LinkToPost
          href={CELEBRATION_POST_URL}
          id='celebration-1k-posts-click'
        >
          Read more
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9766 10.5L6.85156 6.37499L8.0299 5.19666L13.3332 10.5L8.0299 15.8033L6.85156 14.625L10.9766 10.5Z"
              fill="white"
            />
          </svg>
        </S.LinkToPost>
      </S.Banner>
    </S.Wrapper>
  );
};

export default CelebrationBanner;
