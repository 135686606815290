import styled, { css } from 'styled-components';

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: transparent;
  border-radius: 50%;
  text-align: center;

  ${(props) => {
    switch (props.size) {
      case 's':
        return css`
          height: 24px;
          width: 24px;
          font-size: 10px;
          line-height: 1.8;
          font-weight: 700;
        `;
      case 'l':
        return css`
          height: 40px;
          width: 40px;
          font-size: 18px;
          line-height: 2.2;
          font-weight: 600;
        `;
      case 'xl':
        return css`
          height: 56px;
          width: 56px;
          font-size: 20px;
          line-height: 2.8;
          font-weight: 600;

          @media (max-width: 600px) {
            height: 40px;
            width: 40px;
            font-size: 18px;
            line-height: 2.2;
          }
        `;
      default:
        return css`
          height: 32px;
          width: 32px;
          font-size: 18px;
          line-height: 1.8;
          font-weight: 600;
        `;
    }
  }}

  & > #photo, svg {
    margin: 0;
    width:inherit;
    height:inherit;
    border-radius: inherit;
  }

  & > p {
    background-color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`;

export const AvatarSkeleton = styled.div`
  width:100% ;
  display: flex;
  border-radius: 50%;  
`;