import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & div {
    margin-right: 12px;
  }
`;

export const InfoAuthor = styled.a`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.gray70};
  margin: 2px 0px;
  width: max-content;

  :hover {
    color: ${({theme}) => theme.colors.primaryHover};
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export const InfoDate = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: ${({theme}) => theme.colors.gray60};
  margin: 0;
  width: max-content;
`;

export const AuthorName = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.gray70};
`;
