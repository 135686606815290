export const getFeatures = async () => {
  const domain = 'https://growthbook-api-iwbvagzb5a-uc.a.run.app/api/features';
  const path = `/${process.env.REACT_APP_GROWTH_BOOK_API_KEY}?project=prj_yl64z3qo6`;

  const response = await fetch(domain + path);

  if (response?.error) {
    throw new Error(response?.error.message);
  }

  return await response.json();
};
