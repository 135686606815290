import React from 'react';
import * as S from './styles';
import PropTypes from 'prop-types';
import './custom.css';

const ToastifyBase = ({ icon, backGroundColor, title, message }) => {
  return (
    <S.Wrapper backGroundColor={backGroundColor} >
      <S.Icon aria-label="icon container">
        {icon}
      </S.Icon>
      <S.Title>{title}</S.Title>
      <S.Message>{message}</S.Message>
    </S.Wrapper>
  );
};

ToastifyBase.propTypes = {
  icon: PropTypes.element.isRequired,
  backGroundColor: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

ToastifyBase.defaultProps = {
    title: 'Success!',
};

export default ToastifyBase;
