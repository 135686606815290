import styled from 'styled-components';
import TextArea from '../../../TextArea';

export const ReplyText = styled(TextArea)`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  resize: none;
  color: #000;
  margin-top: 8px;
  padding-left: 48px;

  @media screen and (max-width: 900px) {
    padding-left: 0;
  }
`;
