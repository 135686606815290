import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AvatarSkeleton = ({ size }) => {
  return (
    <S.AvatarSkeleton size={size} tabIndex={0}>
      <Skeleton circle={true} height={35} width={35} />
    </S.AvatarSkeleton>
  );
};

AvatarSkeleton.propTypes = {
  size: PropTypes.string
};

AvatarSkeleton.defaultProps = {
  size: 'm'
};

export default AvatarSkeleton;
