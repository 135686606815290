import React from 'react';
import PropTypes from 'prop-types';
import EditForm from '../../../../../components/editForm/EditForm';
import TextArea from '../../../TextArea';
import * as S from './styles';
import RepliesContainer from '../../../../containers/RepliesContainer';
import ItemHeader from '../../ItemHeader';

const Item = ({
  comment,
  handleRemove,
  handleEdit,
  showEditForm,
  handleSubmitEditedComment,
  handleCancelEdit,
  loggedUser
}) => {

  return (
    <S.CommentStyled>
      <ItemHeader
        item={comment}
        removeItem={handleRemove}
        editItem={handleEdit}
        allowEdit={loggedUser.id === comment.author.id}
      />
      <S.CommentGroupOffset showLine={comment.replies.length > 0}>
        {showEditForm(comment.id) ? (
          <EditForm
            handleSubmit={handleSubmitEditedComment}
            editedText={comment.text}
            handleCancelEdit={handleCancelEdit}
          />
        ) : (
          <S.Wrapper>
            <TextArea data-testid="commentText" value={comment.text} />
          </S.Wrapper>
        )}
        <RepliesContainer comment={comment} />
      </S.CommentGroupOffset>
    </S.CommentStyled>
  );
};

Item.propTypes = {
  comment: PropTypes.object,
  handleRemove: PropTypes.func,
  handleEdit: PropTypes.func,
  showEditForm: PropTypes.func,
  handleSubmitEditedComment: PropTypes.func,
  handleCancelEdit: PropTypes.func,
  loggedUser: PropTypes.object
};

export default Item;
