import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { IconEdit, IconDelete } from '../../Icons';
import Author from '../../Author';
import LikesCount from '../../LikesCount';
import CommentsCount from '../../CommentsCount';
import Dialog from '../../Dialog';
import moreIcon from '../../../../images/more-icon.svg';

const PostSummaryWrapper = ({ post, variant, openDeleteModal, isAuthor }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const trigger = (
    <S.ShowMoreButton onClick={() => setShowMobileMenu(!showMobileMenu)}>
      <img src={moreIcon} alt="more actions" />
    </S.ShowMoreButton>
  );

  return (
    <S.Wrapper>
      {variant === 'home' ? (
        <div>
          <Author
            authorName={post.author.name}
            authorEmail={post.author.email}
            username={post.author.username}
            publishedAt={post.published_date}
            showAvatar={true}
            variant={'post'}
          />
        </div>
      ) : null}
      <S.PostInteractions>
        <LikesCount likesList={post && post.likes} />
        {variant === 'profile' ? (
          <CommentsCount commentsAmount={post && post.comments.length} />
        ) : null}
      </S.PostInteractions>

      {variant === 'profile' && isAuthor ? (
        <S.PostActionsButtons>
          <S.Desktop>
            <S.EditButton
              aria-label="Edit"
              to={{
                pathname: `/post/edit/${post.blog.slug}/${post.slug}`,
                state: { post, from: `/${post?.author?.username}` }
              }}
              tabIndex={0}
            >
              <IconEdit size="m" color="#616364" disabledHover={true} />
            </S.EditButton>
            <S.DeleteButton
              aria-label="Delete"
              onClick={() => openDeleteModal(post.id)}
            >
              <IconDelete size="m" color="#616364" disabledHover={true} />
            </S.DeleteButton>
          </S.Desktop>
          <S.Mobile>
            <Dialog
              trigger={trigger}
              boxPosition="right"
              boxDistanceX={11}
              boxDistanceY={-26}
              connectorPosition="bottom"
              connectorDistance={0}
              name="deleteSummary"
            >
              <S.MobileMenu>
                <li>
                  <S.MobileMenuItem onClick={() => openDeleteModal(post.id)}>
                    <IconDelete size="m" color="#616364" disabledHover={true} />
                    <span>Delete</span>
                  </S.MobileMenuItem>
                </li>
              </S.MobileMenu>
            </Dialog>
          </S.Mobile>
        </S.PostActionsButtons>
      ) : null}
    </S.Wrapper>
  );
};

PostSummaryWrapper.propTypes = {
  post: PropTypes.object,
  variant: PropTypes.string,
  openDeleteModal: PropTypes.func,
  isAuthor: PropTypes.bool
};

PostSummaryWrapper.defaultProps = {
  isAuthor: false
};

export default PostSummaryWrapper;
