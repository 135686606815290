import styled from 'styled-components';

export const LikesCountWrapper = styled.div`
  display: flex;
  align-items: center;

  & > svg{
    margin-right: 0.8rem;
 }
`;


export const LikesCountWrapperElementSkeleton = styled.div`
  margin-left: 0.8rem;  
`;
